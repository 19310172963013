import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Accordion as MuiAccordion,
    AccordionDetails,
    Divider,
    AccordionSummary,
    Button,
    Typography,
    Tooltip,
    Theme,
    useTheme,
    createStyles,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

import moment from "moment";
import CountryFlagBox from "../boxes/CountryFlagBox";
import StatusChip from "../StatusChip";
import { GetStarredOrganizations_currentUser_market_parties } from "../../__generated__/GetStarredOrganizations";
import FaviconBox from "../FaviconBox";
import {
    GetAwardInfoForCurrentTender_tender_bids,
    GetAwardInfoForCurrentTender_tender_looptijd_start,
    GetAwardInfoForCurrentTender_tender_lots,
    GetAwardInfoForCurrentTender_tender_next_looptijd_einde,
} from "../../__generated__/GetAwardInfoForCurrentTender";

// TODO
// if bids < 3 -> expanded is true for all items.
// if contractors > 5, show 5 with load more button.
// state for number of items to show

import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";

interface Props {
    bids: GetAwardInfoForCurrentTender_tender_bids[];
    lots: GetAwardInfoForCurrentTender_tender_lots[];
    awardDate: string | null;
    endDate: (GetAwardInfoForCurrentTender_tender_next_looptijd_einde | null)[] | null;
    startDate: (GetAwardInfoForCurrentTender_tender_looptijd_start | null)[] | null;
    starredOrgs: GetStarredOrganizations_currentUser_market_parties[];
}

const Accordion = withStyles(MuiAccordion, (theme: Theme) =>
    createStyles({
        root: {
            border: "none",
            boxShadow: "none",
            "&:before": {
                display: "none",
            },
            "&$expanded": {
                margin: "auto",
            },
        },
        expanded: {
            margin: "0 !important",
        },
    })
);
const LotsEnriched: React.FC<Props> = ({ bids, lots, awardDate, endDate, starredOrgs, startDate }) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { t } = useTranslation();
    const theme = useTheme();
    const pink = "#E03660";
    const starColor = "#9e9e9e";

    const { trackEvent } = useMatomo();

    // Track event
    const trackMP = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // expand accordion based on lot ID
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const ItemsPerClick = bids.length;
    const [noOfItems, setnoOfItems] = useState(5);

    // state to show items based on state of items to show
    // const itemsToShow = bids.slice(0, noOfItems);

    /**
     * Handler to show more items onclick,
     * get value of noOfItems and add value of ItemsPerClick.
     */
    const handleShowMoreItems = () => {
        setnoOfItems(ItemsPerClick);
    };

    /**
     * Handler to show 5 items,
     */
    const handleShowLess = () => {
        setnoOfItems(5);
    };

    /**
     * key = property of array that has to be unique
     */
    const key = "combination";

    /**
     * map array with last items that have duplicate values,
     * opposite of JS find() function
     */
    const arrayUniqueByKey = [...new Map(bids.map((item) => [item[key], item])).values()];

    /**
     * get first item that is a duplicate
     */
    const uniqByKeepFirst = (a: any, key: any) => {
        const seen = new Set();
        return a.filter((item: any) => {
            const k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    };

    const arrayCopyLots = [...lots];

    function currencyFormatDE(num: number) {
        return num
            .toFixed(2) // always two decimal digits
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    }

    return (
        <React.Fragment>
            {/* map over lots, for each lot an accordion item */}
            {arrayCopyLots
                .sort((a: any, b: any) => parseInt(a.number, 10) - parseInt(b.number, 10))
                .map((lot) => {
                    // check if bid has lot id, if not -> dont expand and dont show arrow icon
                    const item = bids.find((u: any) => u.lot?.id === lot.id);
                    const getWonBidValue = bids.filter((bid) => bid.lot?.id === lot.id).find((b) => b.winner === true);
                    const getpreliminaryBidValue = bids.filter((bid) => bid.lot?.id === lot.id).find((b) => b.preliminaryAwarded === true);

                    return (
                        <React.Fragment key={lot.id}>
                            {/* TODO: is this the way we want it ? 
                                - Accordion expands if lot has bids 
                        */}
                            <Accordion expanded={item !== undefined && expanded === item?.lot?.id} onChange={handleChange(lot.id)}>
                                {/* oneliner with some information that user sees on first sight */}
                                <AccordionSummary
                                    sx={{
                                        "&:hover": {
                                            background: item !== undefined ? "#f4f4f4" : undefined,
                                            cursor: item !== undefined ? "pointer" : "default !important",
                                        },
                                    }}
                                    expandIcon={item !== undefined ? <ExpandMoreIcon /> : <StatusChip colored={false} type="noWinner" />}
                                >
                                    <Typography sx={{ flexBasis: "80%", flexShrink: 0 }}>
                                        {t("tenderPage.Lot")} {lot.number} - {lot.title}
                                    </Typography>
                                </AccordionSummary>
                                {/* Detailcomponent with more information about a lot */}
                                {item && (
                                    <AccordionDetails
                                        sx={{
                                            "& .MuiAccordionDetails-root": {
                                                paddingTop: "0px",
                                                paddingBottom: "8px",
                                                justifyContent: "center",
                                            },
                                        }}
                                    >
                                        <List
                                            sx={{
                                                "& .MuiList-root": {
                                                    width: "100%",
                                                    padding: "0px",
                                                },
                                            }}
                                        >
                                            <ListItem style={{ paddingLeft: 0 }}>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <table>
                                                                <tbody>
                                                                    {/* Award date */}
                                                                    {lot.award_date !== null ? (
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingRight: "20px",
                                                                                    whiteSpace: "nowrap",
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <Typography>{t("tenderPage.AwardDate")}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography>{moment(lot.award_date).format("LL")}</Typography>
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        awardDate !== null && (
                                                                            <tr>
                                                                                <td
                                                                                    style={{
                                                                                        paddingRight: "20px",
                                                                                        whiteSpace: "nowrap",
                                                                                        verticalAlign: "top",
                                                                                    }}
                                                                                >
                                                                                    <Typography>{t("tenderPage.AwardDate")}</Typography>
                                                                                </td>
                                                                                <td>
                                                                                    <Typography>{moment(awardDate).format("LL")}</Typography>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}

                                                                    {/* won bid value */}
                                                                    {getWonBidValue && getWonBidValue.bid && (
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingRight: "20px",
                                                                                    whiteSpace: "nowrap",
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <Typography>{t("WonBidValue")}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography>€ {currencyFormatDE(getWonBidValue?.bid)}</Typography>
                                                                            </td>
                                                                        </tr>
                                                                    )}

                                                                    {/* preliminary awarded bid value */}
                                                                    {getpreliminaryBidValue && getpreliminaryBidValue.bid && (
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingRight: "20px",
                                                                                    whiteSpace: "nowrap",
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <Typography>{t("WonBidValue")}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography>
                                                                                    € {currencyFormatDE(getpreliminaryBidValue?.bid)}
                                                                                </Typography>
                                                                            </td>
                                                                        </tr>
                                                                    )}

                                                                    {lot.looptijd_start !== null && (
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingRight: "20px",
                                                                                    whiteSpace: "nowrap",
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <Typography>{t("tenderPage.StartDuration")}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography>{moment(lot.looptijd_start).format("LL")}</Typography>
                                                                            </td>
                                                                        </tr>
                                                                    )}

                                                                    {/* First enddate */}
                                                                    {lot.next_looptijd_einde !== null ? (
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingRight: "20px",
                                                                                    whiteSpace: "nowrap",
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <Typography>{t("tenderPage.FirstEnddate")}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography>
                                                                                    {moment(lot.next_looptijd_einde).format("LL")}
                                                                                </Typography>
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        endDate !== null &&
                                                                        endDate.length > 0 && (
                                                                            <tr>
                                                                                <td
                                                                                    style={{
                                                                                        paddingRight: "20px",
                                                                                        whiteSpace: "nowrap",
                                                                                        verticalAlign: "top",
                                                                                    }}
                                                                                >
                                                                                    <Typography>{t("tenderPage.FirstEnddate")}</Typography>
                                                                                </td>
                                                                                <td>
                                                                                    {endDate.map((end, i) => {
                                                                                        return (
                                                                                            <Typography key={i}>
                                                                                                {moment(end?.value).format("LL")} (perceel
                                                                                                {end?.lots.map((lot) => lot).join(", ")})
                                                                                            </Typography>
                                                                                        );
                                                                                    })}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}

                                                                    {/* bids in lot */}
                                                                    {lot.participants_count && (
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingRight: "20px",
                                                                                    whiteSpace: "nowrap",
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <Typography>{t("tenderPage.NumberOfTenderers")}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography> {lot.participants_count} </Typography>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider style={{ marginTop: 8 }} />

                                            {/* map over bids in a single lot item */}
                                            {bids
                                                .filter((bid) => bid.lot?.id === lot.id)
                                                // .sort((a: any, b: any) => a.combination - b.combination)
                                                .sort(
                                                    (a: any, b: any) =>
                                                        b.winner - a.winner ||
                                                        b.combination - a.combination ||
                                                        a.totalValue?.value - b.totalValue?.value ||
                                                        a.marketParty.name - b.marketParty.name
                                                )
                                                .slice(0, noOfItems)
                                                .map((bid, i) => {
                                                    const filteredNames = bid.marketParty.names.filter((n) => n.type === "trade_name");

                                                    return (
                                                        <React.Fragment key={bid.id}>
                                                            {/* <ListItem style={{ paddingLeft: 0 }}> */}
                                                            {/* get first item of combination and add 8px padding top */}
                                                            <ListItem
                                                                sx={{
                                                                    padding: bid.combination === null ? "8px 16px" : "0px 16px",
                                                                    paddingTop:
                                                                        bid.combination !== null &&
                                                                        uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                                                            (t: any) => t.id === bid.id && t.combination !== null
                                                                        ) &&
                                                                        "8px",

                                                                    // last item of combination -> paddingBottom 8
                                                                    paddingBottom:
                                                                        arrayUniqueByKey.find(
                                                                            (t: any) => t.id === bid.id && t.combination !== null
                                                                        ) && "8px",
                                                                }}
                                                            >
                                                                {/* favicon of tenderer */}
                                                                <div style={{ marginRight: 8 }}>
                                                                    <FaviconBox
                                                                        marginTop={0}
                                                                        favicon={bid.marketParty.favicon_resized ?? bid.marketParty.favicon}
                                                                        name={bid.marketParty.name}
                                                                        color={pink}
                                                                    />
                                                                </div>
                                                                {/* Link to tenderer */}
                                                                <ListItemText
                                                                    primary={
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                alignItems: "center",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                noWrap
                                                                                sx={{
                                                                                    display: "inline",
                                                                                    maxWidth: (theme) =>
                                                                                        theme.breakpoints.up("xl")
                                                                                            ? "88%"
                                                                                            : theme.breakpoints.up("xxl")
                                                                                            ? "94%"
                                                                                            : "80%",
                                                                                }}
                                                                            >
                                                                                {filteredNames.length > 0 ? (
                                                                                    <Tooltip
                                                                                        title={filteredNames
                                                                                            .sort((a, b) => a.value.localeCompare(b.value))
                                                                                            .map((item, i) => {
                                                                                                return (
                                                                                                    <React.Fragment key={i}>
                                                                                                        {i !== filteredNames.length - 1 ? (
                                                                                                            <Typography variant="caption">
                                                                                                                {item.value} |{" "}
                                                                                                            </Typography>
                                                                                                        ) : (
                                                                                                            <Typography variant="caption">
                                                                                                                {item.value}
                                                                                                            </Typography>
                                                                                                        )}
                                                                                                    </React.Fragment>
                                                                                                );
                                                                                            })}
                                                                                    >
                                                                                        <Link
                                                                                            to={`/organizations/${bid.marketParty.id}`}
                                                                                            onClick={() => {
                                                                                                trackMP({
                                                                                                    category: "Tender",
                                                                                                    action: "Go-from-award-widget-to-organization-detail",
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            {bid.marketParty.name}{" "}
                                                                                        </Link>
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <Link
                                                                                        to={`/organizations/${bid.marketParty.id}`}
                                                                                        onClick={() => {
                                                                                            trackMP({
                                                                                                category: "Tender",
                                                                                                action: "Go-from-award-widget-to-organization-detail",
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {bid.marketParty.name}{" "}
                                                                                    </Link>
                                                                                )}
                                                                            </Typography>
                                                                            <CountryFlagBox
                                                                                code={
                                                                                    bid.marketParty.country?.alpha2
                                                                                        ? bid.marketParty.country.alpha2
                                                                                        : null
                                                                                }
                                                                            />
                                                                            {/* TODO: conditon, render only if starred */}
                                                                            {starredOrgs.find((s) => s.id === bid.marketParty.id) && (
                                                                                <StarIcon
                                                                                    style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }}
                                                                                />
                                                                            )}
                                                                            {arrayUniqueByKey.find(
                                                                                (t: any) => t.id === bid.id && t.combination !== null
                                                                            )
                                                                                ? null
                                                                                : bid.combination !== null && (
                                                                                      <AddIcon
                                                                                          style={{ height: "0.7em", width: "0.7em", marginLeft: 16 }}
                                                                                      />
                                                                                  )}
                                                                        </div>
                                                                    }
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            {/* show  */}
                                                                            {arrayUniqueByKey.find(
                                                                                (t: any) => t.id === bid.id && t.combination !== null
                                                                            ) && bid.bid ? (
                                                                                <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                                                    € {currencyFormatDE(bid.bid)}
                                                                                </Typography>
                                                                            ) : (
                                                                                bid.bid &&
                                                                                bid.combination === null && (
                                                                                    <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                                                        € {currencyFormatDE(bid.bid)}
                                                                                    </Typography>
                                                                                )
                                                                            )}

                                                                            {/* show green or red percentage when there are over 1 winners, percentage based on diff between lowest offer and offer of bid */}
                                                                            {bid.percentage && (
                                                                                <Typography
                                                                                    style={{
                                                                                        display: "inline-block",
                                                                                        color: theme.palette.error.main,
                                                                                    }}
                                                                                >
                                                                                    +{bid.percentage}%
                                                                                </Typography>
                                                                            )}
                                                                        </React.Fragment>
                                                                    }
                                                                />

                                                                {/* Chip that shows who won the bid */}
                                                                <ListItemSecondaryAction style={{ height: "100%", paddingTop: 12 }}>
                                                                    {/* if winner is true and combination is null (no combo) show winner chip */}
                                                                    {bid.winner && bid.combination === null ? (
                                                                        <div style={{ marginLeft: 4 }}>
                                                                            <StatusChip colored={false} type="winner" />
                                                                        </div>
                                                                    ) : // If bid is not winner and no combination => noWinner
                                                                    bid.winner !== true && bid.combination === null ? (
                                                                        <div style={{ marginLeft: 4 }}>
                                                                            {/* <StatusChip colored={false} type="noWinner" /> */}
                                                                        </div>
                                                                    ) : // if preliminary is true and combination is null (no combo) show preliminary awarded chip
                                                                    bid.preliminaryAwarded && bid.combination === null ? (
                                                                        <div style={{ marginLeft: 4 }}>
                                                                            <StatusChip colored={false} type="PRELIMINARY_AWARDED" />
                                                                        </div>
                                                                    ) : // map over unique array if id is same then show chip -> winner
                                                                    bid.winner &&
                                                                      uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                                                          (t: any) => t.id === bid.id && t.combination !== null
                                                                      ) ? (
                                                                        <div style={{ marginLeft: 4 }}>
                                                                            <StatusChip colored={false} type="winner" />
                                                                        </div>
                                                                    ) : // map over unique array if id is same then show chip -> preliminary awarded
                                                                    bid.preliminaryAwarded &&
                                                                      uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                                                          (t: any) => t.id === bid.id && t.combination !== null
                                                                      ) ? (
                                                                        <div style={{ marginLeft: 4 }}>
                                                                            <StatusChip colored={false} type="PRELIMINARY_AWARDED" />
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </ListItemSecondaryAction>
                                                            </ListItem>

                                                            {/* Check if item is last one AND item is not a combination, last one -> show divider */}
                                                            {bids.filter((bid) => bid.lot?.id === lot.id).length - 1 !== i &&
                                                                bid.combination === null && <Divider />}

                                                            {/* Set divider to last index of a combination */}
                                                            {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && (
                                                                <Divider />
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </List>
                                    </AccordionDetails>
                                )}

                                {bids.filter((bid) => bid.lot?.id === lot.id).length > 5 && (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {noOfItems === 5 ? (
                                            <Button
                                                size="small"
                                                variant="text"
                                                color="primary"
                                                style={{ marginBottom: 8 }}
                                                onClick={handleShowMoreItems}
                                            >
                                                {t("tenderPage.showAll")}
                                            </Button>
                                        ) : (
                                            <Button size="small" variant="text" color="primary" style={{ marginBottom: 8 }} onClick={handleShowLess}>
                                                {t("tenderPage.ShowLess")}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </Accordion>
                            <Divider />
                        </React.Fragment>
                    );
                })}
        </React.Fragment>
    );
};

export default LotsEnriched;
