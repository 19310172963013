import React from "react";

interface Props {
    a?: number;
}

const OverlappingDotLeft: React.FC<Props> = (props) => {
    return (
        <div
            style={{
                position: "absolute",
                top: "500px",
                left: "600px",
                height: "450px",
                width: "450px",
            }}
        >
            <svg id="10015.io" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#ffffff14"
                    d="M404.5,283.5Q391,327,367.5,374Q344,421,292,435Q240,449,191,429Q142,409,111.5,370.5Q81,332,54,286Q27,240,49.5,191.5Q72,143,101,96Q130,49,185,30Q240,11,290,39Q340,67,373.5,105.5Q407,144,412.5,192Q418,240,404.5,283.5Z"
                />
            </svg>
        </div>
    );
};

export default OverlappingDotLeft;
