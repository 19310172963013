import React from "react";
import { useTranslation } from "react-i18next";
// import moment from "moment";
import {
    Button,
    //  Menu, MenuItem
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

// const languages = [
//     { id: "1", label: "Nederlands", code: "nl", language: "nl" },
//     { id: "2", label: "English", code: "en", language: "en-US" },
//     { id: "3", label: "Deutsch", code: "de", language: "de" },
//     { id: "4", label: "Français", code: "fr", language: "fr" },
//     { id: "5", label: "Español", code: "es", language: "es" },
// ];
// const language =
//     localStorage.getItem("i18nextLng") === "en-US"
//         ? "en"
//         : localStorage.getItem("i18nextLng") === "nl-NL"
//         ? "nl"
//         : localStorage.getItem("i18nextLng");

const LanguageSelector: React.FC = (props) => {
    const {
        t,
        // i18n
    } = useTranslation();
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    localStorage.setItem("i18nextLng", "nl");

    return (
        <div className="LanguageSelector" style={{ fontSize: 12, position: "absolute", top: -40, left: -1 }}>
            <Button
                disableRipple
                size="small"
                aria-controls="simple-menu"
                aria-haspopup="true"
                //  onClick={handleClick}
                startIcon={<LanguageIcon />}
            >
                {t(`language.nl`)}
            </Button>
            {/* <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                defaultValue={"nl"}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {languages.map((l) => (
                    <MenuItem
                        value={l.code}
                        key={l.id}
                        onClick={() => {
                            i18n.changeLanguage(l.code);
                            moment.locale(l.language);
                            setAnchorEl(null);
                        }}
                    >
                        {l.label}
                    </MenuItem>
                ))}
            </Menu> */}
        </div>
    );
};

export default LanguageSelector;
