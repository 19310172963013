import React, { useState } from "react";
import { Box, Typography, Card, IconButton, Stack, Button, useTheme, useMediaQuery, Link, Skeleton, CardActionArea } from "@mui/material";
import { GetSubscriptionUsers } from "../../__generated__/GetSubscriptionUsers";
import { gql, useMutation, useQuery } from "@apollo/client";
import { QUERY_CURRENT_USER_TEST, QUERY_SUBSCRIPTION_USERS } from "../../graphql/queryDefUserAndTeams";
import { User, UserInTeam } from "../profile/UserAndTeams/UsersAndTeams";
import { useCurrentUserOrganization } from "../../components/contextProviders/CurrentUserOrganization";
import { DataOfCurrentUserToShow } from "../../__generated__/DataOfCurrentUserToShow";
import { Add } from "@mui/icons-material";
import FormModal from "./addUsers/FormModal";
import AddedUsers from "./addUsers/AddedUsers";
import { TendenZ_registrations } from "../../__generated__/TendenZ_registrations";
import { TendenZ_register, TendenZ_registerVariables } from "../../__generated__/TendenZ_register";
import { Disable } from "react-disable";
import { useFaq } from "../../components/contextProviders/FaqOpenerContext";
import { LoadingButton } from "@mui/lab";

interface Props {
    onMutationCompleted: () => void;
}

const FormSection: React.FC<Props> = ({ onMutationCompleted }) => {
    const { setOpenChat, setOpenFaq } = useFaq();
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    const [orgName, setOrgName] = useState<string>("");
    const { org_id } = useCurrentUserOrganization();
    const [currentUser, setCurrentUser] = useState<User>();
    const [userList, setUserList] = useState<UserInTeam[]>([]);
    const [open, setOpen] = useState(false);
    const [seats, setSeats] = useState<number>(0);
    const [loadAllQueries, setLoadAllQueries] = useState<{ subs: boolean; regs: boolean; user: boolean }>({ subs: false, regs: false, user: false });
    const [loading, setLoading] = useState<boolean>(false);

    //  30 of meer seats	5
    //  16 - 29 seats		4
    //  7 - 15 seats		3
    //  6 of minder seats	2 deelnemers toegestaan
    const amountOfSeats = seats <= 6 ? 2 : seats >= 7 && seats <= 15 ? 3 : seats >= 16 && seats <= 29 ? 4 : 5;

    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    // ADD REGISTRATION
    const [addRegistration, { loading: loadAdding, error: errorAdd }] = useMutation<TendenZ_register, TendenZ_registerVariables>(TENDENZ_REGISTER);

    /**
     * QUERY USERS OF ORGANIZATION
     */
    useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.organization && data.organization.users && data.organization.users) {
                setUserList(data.organization.users);
                setOrgName(data.organization.name ?? "");
                setSeats(data.organization.subscriptions[0].seats ?? 0);
                setLoadAllQueries((prevState) => ({
                    ...prevState,
                    subs: true,
                }));
            }
        },
    });

    // FETCH REGISTRATIONS
    const { data, refetch } = useQuery<TendenZ_registrations>(TENDENZ_REGISTRATIONS, {
        onCompleted: () => {
            setLoadAllQueries((prevState) => ({
                ...prevState,
                regs: true,
            }));
        },
    });

    // LOAD CURRENT USER
    useQuery<DataOfCurrentUserToShow>(QUERY_CURRENT_USER_TEST, {
        onCompleted: (data) => {
            if (data && data.currentUser) {
                setCurrentUser(data.currentUser);
                setLoadAllQueries((prevState) => ({
                    ...prevState,
                    user: true,
                }));
            }
        },
    });

    const toggleUserSelection = (userId: string) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(selectedUsers.filter((id) => id !== userId));
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        AddMulti();
    };

    /**
     * Add multiple users
     */
    const AddMulti = () => {
        /**
         * if arr is empty, then all CA's are already starred.
         * if user clicks on button, then CA's star will be removed.
         * if arr is > 0 then CA's will be starred.
         */
        if (selectedUsers.length > 0) {
            setLoading(true);
            selectedUsers.forEach(async (id: string) => {
                // Function to like organization
                if (loadAdding) {
                }
                if (errorAdd) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await addRegistration({
                        variables: {
                            user_id: parseInt(id),
                            edition: "1",
                        },
                    });

                    setSelectedUsers([]);
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    handleClose();
                    setSelectedUsers([]);
                    onMutationCompleted();
                    refetch();
                    setLoading(false);
                }
            });
        }
        // else {
        //     onClose?.();
        // }
    };

    // Function to register single user on click
    const addSingle = async (id: number) => {
        try {
            await addRegistration({
                variables: {
                    user_id: id,
                    edition: "1",
                },
                onCompleted: () => {
                    refetch();
                    onMutationCompleted();
                },
            });
        } catch (e) {
            // nothing to do
        }
    };
    // Checks if all loadingstate are set to true
    const allTrue = Object.values(loadAllQueries).every((value) => value === true);

    // if NOT => Show loader
    if (!allTrue) {
        return (
            <div
                style={{
                    height: "40vh",
                    margin: noMobile ? "128px 0px" : 0,
                    flex: 1,
                    width: "100%",
                    padding: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Skeleton component="text" sx={{ fontSize: "3rem" }} width={"40vw"} />
                <Skeleton component="text" sx={{ fontSize: "2rem" }} width={"35vw"} />
                <Skeleton component="text" sx={{ fontSize: "1rem" }} width={"20vw"} />

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", marginTop: "32px" }}>
                    <Skeleton variant="rounded" width={175} height={175} />
                    <div style={{ width: "32px" }} />
                    <Skeleton variant="rounded" width={175} height={175} />
                </div>
            </div>
        );
    }
    // check if current user is registered
    const userIsRegistered = Boolean(data?.TendenzRegistrations?.find((item) => item?.user.id === currentUser?.id));

    // no free spot
    const registrationsIsFull = amountOfSeats === data?.TendenzRegistrations?.length;

    return (
        <Disable disabled={loadAdding}>
            <Box py={4} px={4} sx={{ minHeight: "50vh", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Typography
                    id="register"
                    variant="h1"
                    fontWeight={500}
                    gutterBottom
                    align="center"
                    sx={{ marginX: 5, marginBottom: 2, fontSize: "3rem !important" }}
                >
                    Deelnemers {orgName}
                </Typography>

                <Typography variant="h3" align="center" gutterBottom>
                    Het is mogelijk om met <span style={{ fontWeight: 600 }}>{amountOfSeats}</span> gebruikers deel te nemen aan TendenZ
                </Typography>

                <Typography variant="h5" align="center">
                    Heb je vragen?{" "}
                    <Link
                        sx={{
                            cursor: "pointer",
                            fontWeight: 500,
                            color: "#70a4b7",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "none",
                            },
                        }}
                        onClick={() => {
                            setOpenFaq(true);
                            setOpenChat(true);
                        }}
                    >
                        Stuur ons een bericht
                    </Link>
                </Typography>

                <div style={{ margin: noMobile ? "128px 0px" : "64px 0px", flex: 1, width: noMobile ? "60vw" : "100vw", padding: 8 }}>
                    {data?.TendenzRegistrations?.length !== 0 ? (
                        <>
                            <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" justifyContent={"center"}>
                                {data?.TendenzRegistrations?.map((user) => {
                                    return (
                                        <AddedUsers
                                            key={user?.id}
                                            givenName={user?.user.employee.givenname ?? ""}
                                            familyname={user?.user.employee.familyname ?? ""}
                                            middlename={user?.user.employee.middlename ?? ""}
                                            id={parseInt(user?.user.id as string)}
                                            iAmRegistered={data?.TendenzRegistrations?.find((item) => item?.user.id === currentUser?.id)?.user.id}
                                            showDelete={currentUser?.subscription_admin}
                                        />
                                    );
                                })}
                            </Stack>

                            {currentUser?.subscription_admin ? (
                                <div style={{ marginTop: "64px", display: "flex", justifyContent: "center" }}>
                                    <Button disabled={loadAdding} variant="contained" onClick={handleClickOpen}>
                                        Gebruikers aanmelden
                                    </Button>
                                </div>
                            ) : (
                                <div style={{ marginTop: "64px", display: "flex", justifyContent: "center" }}>
                                    {!userIsRegistered && !registrationsIsFull && (
                                        <LoadingButton
                                            disabled={loadAdding}
                                            variant="contained"
                                            loadingIndicator="Aanmelden…"
                                            onClick={() => addSingle(parseInt(currentUser?.id as string))}
                                        >
                                            Aanmelden
                                        </LoadingButton>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {currentUser?.subscription_admin ? (
                                <Card elevation={4} sx={{ borderRadius: "16px" }}>
                                    <CardActionArea
                                        sx={{
                                            width: 275,
                                            height: 275,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",

                                            flexDirection: "column",
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        <IconButton size="large">
                                            <Add fontSize="large" />
                                        </IconButton>
                                        <Typography variant="h5" fontWeight={500}>
                                            Gebruikers aanmelden
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                            ) : !registrationsIsFull ? (
                                <Card
                                    elevation={4}
                                    sx={{
                                        borderRadius: "16px",
                                    }}
                                >
                                    <CardActionArea
                                        sx={{
                                            width: 275,
                                            height: 275,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        }}
                                        onClick={() => addSingle(parseInt(currentUser?.id as string))}
                                    >
                                        <IconButton size="large">
                                            <Add fontSize="large" />
                                        </IconButton>
                                        <Typography variant="h5" fontWeight={500}>
                                            Aanmelden
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </div>

                <FormModal
                    loading={loading}
                    open={open}
                    onClose={handleClose}
                    onSave={handleSave}
                    userList={userList}
                    totalSeats={amountOfSeats}
                    toggleUserSelection={toggleUserSelection}
                    selectedUsers={selectedUsers}
                    registeredUsers={data?.TendenzRegistrations}
                />
            </Box>
        </Disable>
    );
};

export default FormSection;

// GET ORGANIZATION REGISTRATIONS TENDENZ
export const TENDENZ_REGISTRATIONS = gql`
    query TendenZ_registrations {
        TendenzRegistrations {
            id
            user {
                id
                employee {
                    givenname
                    middlename
                    familyname
                }
            }
        }
    }
`;

// ADD REGISTRATION
export const TENDENZ_REGISTER = gql`
    mutation TendenZ_register($user_id: Int!, $edition: String!) {
        TendenZRegister(user_id: $user_id, edition: $edition)
    }
`;
