import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CountDownItem from "./CountDownItem";

interface CountdownProps {
    targetDate: Date;
    teaser?: string;
}

const CountDown: React.FC<CountdownProps> = ({ targetDate, teaser }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    /**
     * RETURN IF DEVICE IS MOBILE
     */
    if (!noMobile) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    bottom: 175,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0",
                    flexDirection: "row",
                    width: "100%",
                }}
            >
                <div style={{ width: "95%" }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                            <Typography
                                sx={{
                                    fontSize: "2rem !important", // Adjust font size as needed
                                    fontWeight: 700, // Adjust font weight as needed
                                    textAlign: "center", // Center align the text
                                }}
                            >
                                8 oktober 2024
                            </Typography>
                        </Grid>
                        <CountDownItem value={timeLeft.days} label="Dagen" padding="32px 6px" />
                        <CountDownItem value={timeLeft.hours} label="Uren" padding="32px 6px" />
                        <CountDownItem value={timeLeft.minutes} label="Minuten" padding="32px 6px" />
                        <CountDownItem value={timeLeft.seconds} label="Seconden" padding="32px 6px" />
                    </Grid>
                </div>
            </Box>
        );
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "256px", flexDirection: "row" }}>
            <div style={{ width: "50%" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                        {teaser && (
                            <Typography
                                sx={{
                                    fontSize: "2rem", // Adjust font size as needed
                                    fontWeight: 500, // Adjust font weight as needed
                                    textAlign: "center", // Center align the text
                                }}
                            >
                                {teaser}
                            </Typography>
                        )}
                        <Typography
                            sx={{
                                fontSize: "3rem", // Adjust font size as needed
                                fontWeight: 700, // Adjust font weight as needed
                                textAlign: "center", // Center align the text
                            }}
                        >
                            8 oktober 2024
                        </Typography>
                        <Typography variant="h2" fontWeight={500} sx={{ zIndex: 999, position: "relative" }}>
                            13:30 uur
                        </Typography>
                    </Grid>
                    <CountDownItem value={timeLeft.days} label="Dagen" />
                    <CountDownItem value={timeLeft.hours} label="Uren" />
                    <CountDownItem value={timeLeft.minutes} label="Minuten" />
                    <CountDownItem value={timeLeft.seconds} label="Seconden" />
                </Grid>
            </div>
        </Box>
    );
};

export default CountDown;
