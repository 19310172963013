import React from "react";
import { Typography } from "@mui/material";

interface Props {
    title: string;
    value: number | null;
    currency?: string | null;
}

const HeadingBlock: React.FC<Props> = ({ title, value, currency }) => {
    function currencyFormatDE(num: number) {
        return num
            .toFixed(2) // always two decimal digits
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    }

    // change string to currency sign
    const sign = currency === "EUR" ? "€" : currency;

    return (
        <React.Fragment>
            <div style={{ padding: "1px 16px" }}>
                <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h4">{title}</Typography>
                        {/* value div */}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
                            <Typography style={{ marginLeft: 8 }}>
                                {sign} {value ? currencyFormatDE(value) : ""}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default HeadingBlock;
