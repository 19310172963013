import React from "react";
import { Typography, Box, Link, Breadcrumbs, Skeleton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// GQL
import { useQuery } from "@apollo/client";
import { SingleArticle, SingleArticleVariables } from "../../__generated__/SingleArticle";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import { useFaq } from "../contextProviders/FaqOpenerContext";
import { QUERY_GETSINGLEARTICLE } from "../../graphql/queryDefFaq";
import VcardColumn from "../VcardColumn";

interface Props {
    article_id: string;
}

const KnowledgeBaseDetailPage: React.FC<Props> = ({ article_id }) => {
    const { setView } = useFaq();
    const { data, error, loading } = useQuery<SingleArticle, SingleArticleVariables>(QUERY_GETSINGLEARTICLE, { variables: { id: article_id } });
    const { t } = useTranslation();

    // Loader
    if (loading) {
        return (
            <React.Fragment>
                {/* Breadcrumb link chain */}
                <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 16, marginTop: 12 }}>
                    {/* Link with breadcrumbs to show user the category of the clicked item */}
                    <Skeleton variant="circular" width={20} height={20} />
                    <Skeleton animation="wave" height={12} width="50px" />
                    <Skeleton animation="wave" height={12} width="50px" />
                </Breadcrumbs>

                {/* box with information */}
                <Box p={2}>
                    <Skeleton animation="wave" height={24} width="200px" style={{ marginBottom: 16 }} />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="90%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="90%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                </Box>
            </React.Fragment>
        );
    }
    if (error || !data || !data.article) return <p>error loading articles</p>;
    const article = data.article;

    /**
     * dompurify imported for sanitizing html
     */
    const frag = dompurify.sanitize(article.content, { FORCE_BODY: true });

    /**
     * Check if content has external urls to navigate to.
     * If its true => add target blank to url tag(s)
     */
    const CheckUrlsInContent = frag.includes(`<a href="https://tool.tender.guide/`) ? frag : frag.replaceAll(`<a`, `<a target="_blank"`);

    /**
     * Get sanitized content and replace the html tags (<icon_name>) with material icons
     */
    const showIcons = CheckUrlsInContent.replace(/&lt;/g, `<span class="material-icons">`).replace(/&gt;/g, `</span>`);

    // Create a temporary element to parse the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = showIcons;

    // Find the img tag within the parsed HTML
    const imgElement = tempElement.querySelector("img");
    // Find all <figcaption> elements
    const figcaptions = tempElement.querySelectorAll("figcaption");

    // If img tag is found, modify its width and height attributes
    if (imgElement) {
        imgElement.setAttribute("width", "100%");
        imgElement.setAttribute("height", "auto");
    }
    // Remove each <figcaption> element
    figcaptions.forEach((figcaption) => {
        figcaption.remove();
    });

    // Get the modified HTML string
    const modifiedHtmlString = tempElement.innerHTML;

    // Change <figure> to <div>
    const removeFigure = modifiedHtmlString.replace(/<figure/g, "<div").replace(/<\/figure>/g, "</div>");

    //     <video muted width="100%" height="auto" controls autoPlay>
    //     <source src="https://storage.googleapis.com/faq_assets/testing.mp4" type="video/mp4" />
    //     Your browser does not support the video tag.
    // </video>

    return (
        <React.Fragment>
            {/* Breadcrumb link chain */}
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 16, marginTop: 12 }}>
                {/* Link with breadcrumbs to show user the category of the clicked item */}
                <Link
                    underline="none"
                    color="inherit"
                    component="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        setView("start");
                    }}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                            textDecoration: "underline",
                        },
                    }}
                >
                    <SearchIcon sx={{ width: "20px", height: "20px" }} />
                </Link>

                <Link
                    underline="none"
                    color="inherit"
                    component="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        setView(article.category === "column" ? "column" : article.module !== null ? article.module : "start");
                    }}
                >
                    {article.category === "column" ? "column" : t(`faq.${article.module}`)}
                </Link>

                {/* <Link color="inherit" component="button" onClick={() => setView(article.category !== null ? article.category : "start")}>
                    {t(`faq.${article.category}`)}
                </Link> */}
            </Breadcrumbs>

            {/* box with information */}
            <Box p={2}>
                <Typography sx={{ color: "#707070", marginBottom: "16px" }} variant="h5">
                    {article.title}
                </Typography>

                <Typography
                    // style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{
                        __html: removeFigure,
                    }}
                />

                {article.category === "column" && article.author && (
                    <div style={{ marginTop: "32px" }}>
                        <VcardColumn
                            // logo
                            logo={article.author.organization.logo || ""}
                            // picture in avatar
                            img={article.author.picture || ""}
                            role={article.author.function || ""}
                            showmenu={true}
                            showLabel={false}
                            full_name={article.author.name || ""}
                            first_name={article.author.givenname || ""}
                            last_name={article.author.familyname || ""}
                            cardVariant={"elevation"}
                            cardElevation={1}
                            nameStyle="body1"
                            mail={article.author.email || ""}
                            social={article.author.linkedin || ""}
                            organization={article.author.organization.name || ""}
                            phone={article.author.phonenumber || ""}
                            // "0570-758011"
                        />
                    </div>
                )}

                {/*
                 * TEST div with iframe to create poc with video
                 */}
                {/* <div
                    style={{
                        position: "relative",
                        display: "block",
                        margin: "0 auto",
                        width: "100%",
                        maxWidth: "920px",
                        paddingBottom: "56.25%",
                        marginTop: "12px",
                    }}
                >
                    <iframe
                        src="https://player.vimeo.com/video/820795478?h=6f04896d12&title=0&byline=0&portrait=0"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: "0%",
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                        width="560"
                        height="315"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        // allowFullScreen
                        title="vimeo"
                    />
                </div> */}
            </Box>
        </React.Fragment>
    );
};

export default KnowledgeBaseDetailPage;
