import React, { useState } from "react";
import { Collapse, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import SearchLineMenu from "../SearchLineMenu";
import SearchLineStats from "../SearchLineStats";
import { GetUserSearchRules_currentUser_opportunitySearches } from "../../../../__generated__/GetUserSearchRules";
import { presetColors } from "../presetColors";
import OppSearchLineSettings from "./OppSearchLineSettings";
import { useTranslation } from "react-i18next";
import { Disable } from "react-disable";
import CopySearchLine from "../CopySearchline";
import OppMobileViewSearch from "./OppMobileViewSearch";

interface Props {
    searchline: GetUserSearchRules_currentUser_opportunitySearches;
    openEdit: () => void;
    type: string;
}

export interface Color {
    id: string;
    colors: string[];
}

const OppSearchLineItem: React.FC<Props> = ({ searchline, openEdit, type }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    // const [openPicker, setOpenPicker] = useState(false);
    const [openStats, setOpenStats] = useState(false);
    const [searchterm, setSearchterm] = useState<string[]>([""]);
    const [openShare, setOpenShare] = useState<boolean>(false);
    const [color, setColor] = useState(
        // searchline?.color ||
        "#225E4D"
    );
    const [removeRuleLoader, setRemoveRuleLoader] = useState<boolean>(false);
    const green = "#225E4D";
    const lightgreen = "#508c79";
    const bg = "#ffffff";
    const blue = "#173357";

    const handleOpenShare = () => {
        // Open menu with colleagues and teams
        setOpenShare(true);
        // close menu with options to share, remove, edit
        setOpenMenu(false);
    };

    const handleCloseShare = () => {
        // Open share menu
        setOpenShare(false);
        // Close menu
        handleCloseSub();
    };

    /**
     * Close colorPicker
     */
    // const handleClosePicker = () => {
    //     setOpenPicker(false);
    // };

    /**
     * Close colorPicker
     */
    const handleOpenEdit = () => {
        // Open searcrule modal with data prefilled
        openEdit();
        // Close menu
        handleCloseSub();
    };

    /**
     * open colorPicker
     */
    // const handleOpenPicker = () => {
    //     setOpenPicker(true);
    //     handleCloseSub();
    // };

    /**
     * open statistics
     */
    const handleOpenStats = () => {
        setOpenStats(true);
        setOpen(false);
        handleCloseSub();
    };

    /**
     * close statistics
     */
    const handleCloseStats = () => {
        setOpenStats(false);
        setOpen(false);
        handleCloseSub();
    };

    /**
     * @param event selected searchterm
     * add selected searchterm to selection
     */
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearchterm(event.target.value as string[]);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    const handleOpenDist = () => {
        setOpen(!open);
        setOpenStats(false);
    };

    const openDistribution = () => {
        setOpenMenu(false);
        setAnchorEl(null);
        handleOpenDist();
    };

    /**
     * Temportary loading animation
     */
    // const [progress, setProgress] = useState(0);
    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    //     }, 800);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);
    // const distribution = JSON.parse(searchline.distribution);

    //
    return (
        <Disable disabled={removeRuleLoader}>
            <React.Fragment>
                {/*
                 * Settings for searchrule
                 * 1 sentence with al settings for individual searchrule.
                 * TODO: inline edit
                 */}
                {isMobile ? (
                    <OppMobileViewSearch searchline={searchline} type={"opportunity"} />
                ) : (
                    <OppSearchLineSettings
                        type={type}
                        color={color}
                        searchline={searchline}
                        open={open}
                        searchterm={searchterm}
                        handleChange={handleChange}
                        progress={0}
                        handleOpenStats={handleOpenDist}
                        handleClickSub={handleClickSub}
                    />
                )}
                {/*
                 * Box contains dsitribution settings for individual searchrule
                 * TODO: inline edit
                 */}
                <Collapse in={open || openStats} timeout="auto">
                    {
                        openStats && !open ? <SearchLineStats /> : null
                        // <OppSearchLineDistribution dist={distribution} type={type} />
                    }
                    {/*
                     *  Temporary sentence until OppsearchLineDistribution is available
                     *  //TODO: add OppsearchLineDistribution
                     */}
                    <ListItem
                        sx={{
                            borderLeft: type === "opportunity" ? `5px solid ${green}` : type === "tender" ? `5px solid ${blue}` : undefined,
                            backgroundColor: bg,
                            marginBottom: "5px",
                        }}
                    >
                        <div>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: 37 }}>
                                <Typography variant="body1" sx={{ ml: "4px" }}>
                                    <span style={{ color: lightgreen, fontWeight: 500, marginRight: 4 }}>{t("AT_PUBLICATION_OPP" as string)}</span>
                                    alle resultaten tonen in werklijst
                                </Typography>
                            </div>
                        </div>
                    </ListItem>
                </Collapse>

                {/*
                 * Submenu searchrule
                 *  choose color
                 *  share searchrule
                 *  delete searchrule
                 *  edit searchrule
                 * */}
                <SearchLineMenu
                    isOwner
                    chosenColors={[]}
                    type={"opportunity"}
                    setRemoveRuleLoader={setRemoveRuleLoader}
                    openEdit={handleOpenEdit}
                    searchRuleId={searchline.id}
                    handleCloseSub={handleCloseSub}
                    anchorEl={anchorEl}
                    // handleOpenPicker={handleOpenPicker}
                    color={color}
                    setColor={setColor}
                    presetColors={presetColors}
                    // handleClosePicker={handleClosePicker}
                    // openPicker={openPicker}
                    handleOpenStats={handleOpenStats}
                    openStats={openStats}
                    handleCloseStats={handleCloseStats}
                    shareOpened={openShare}
                    openMenu={openMenu}
                    openShare={handleOpenShare}
                    openDistribution={openDistribution}
                    openCopy={handleOpenShare}
                />
                <CopySearchLine
                    type={"opportunity"}
                    searchID={searchline.id}
                    shareOpened={openShare}
                    anchorEl={anchorEl}
                    handleCloseSub={handleCloseShare}
                    teamsComponent={[]}
                    colleaguesComponents={[]}
                />
            </React.Fragment>
        </Disable>
    );
};

export default OppSearchLineItem;
