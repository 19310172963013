import React, { useState, useRef, useCallback } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import PlayListAddIcon from "@mui/icons-material/PlaylistAdd";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
// import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

interface Props {
    query: string;
    onChangeQuery(query: string): void;
    onSearch(): void;
    visible: "show" | "disabled" | "noShow";
    openModal(): void;
}
// TODO: Target changes to summary on query update (if another target is chosen)
const OpportunitySearchBar: React.FC<Props> = ({ query, onChangeQuery, onSearch, visible, openModal }) => {
    const inputRef = useRef();
    const { pathname } = useLocation();
    // const { t } = useTranslation();
    const theme = useTheme();
    const grey = "#707070";
    // state to show user input in searchfield
    // debounce function to prevent request if characters is < 3 except 0
    const debounced = useRef(debounce((value) => (value.length === 0 ? onChangeQuery(value) : value.length >= 3 && onChangeQuery(value)), 1100));
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    /**
     * get defaultQuery based on location(url)
     */
    const defaultQuery =
        pathname === "/opportunities"
            ? sessionStorage.getItem("aoq")
            : pathname === "/opportunities/reviews"
            ? sessionStorage.getItem("roq")
            : pathname === "/opportunities/worklist"
            ? sessionStorage.getItem("woq")
            : "";

    // check if defAultQuery is not null and parse json
    const defaultQueryJson = defaultQuery ? JSON.parse(defaultQuery) : "";

    /**
     * Searchfield state with default value from sessionStorage (defaultQuery)
     * remove "" in query
     */
    const [userValue, setUserValue] = useState(defaultQueryJson || "");

    const updateUserValue = useCallback(({ target: { value } }) => {
        setUserValue(value);
        debounced.current(value);
    }, []);

    return (
        <div
            style={{
                width: onlyMobile ? "95vw" : "480px",
                maxWidth: "100% !important",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/*
                 * Searchbox to type query
                 * onSearch -> start search with typed query
                 */}
                <Paper
                    square
                    component="form"
                    sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: "438px" }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSearch();
                    }}
                >
                    <SearchIcon style={{ margin: "10px 0px 10px 6px", color: grey }} />
                    <InputBase
                        ref={inputRef}
                        sx={{ marginLeft: theme.spacing(1), flex: 1 }}
                        placeholder="Zoeken"
                        autoFocus={onlyMobile}
                        value={userValue}
                        onChange={updateUserValue}
                    />
                </Paper>
                {/* show add searchrule icon
                 * - query
                 * - active filters
                 * - modal shows up when active filter is not a searchrulefilter
                 */}
                {visible === "noShow" ? (
                    <div />
                ) : visible === "disabled" ? (
                    <Tooltip title="Toevoegen als zoekregel niet mogelijk" placement="right">
                        <div>
                            <IconButton disabled size="small" style={{ margin: "0 6px" }}>
                                <PlayListAddIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip title="Toevoegen als zoekregel" placement="right">
                        <IconButton onClick={openModal} size="small" style={{ margin: "0 6px" }}>
                            <PlayListAddIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {/*
             * Div with 4 px whitespace
             */}
            <div style={{ height: 4 }} />
        </div>
    );
};

export default OpportunitySearchBar;
