import React, { useEffect } from "react";
import { Paper, List, ListItemText, Checkbox, ListItemIcon, ListSubheader, Typography, Box, ListItemButton, FormControlLabel } from "@mui/material";
import SectorIconLoader from "../../../components/boxes/SectorIconLoader";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";
import { TenderFilterInput } from "../../../__generated__/globalTypes";
import update from "immutability-helper";

//TODO translation

interface Props {
    a?: number;
}

const SectorSearch: React.FC<Props> = (props) => {
    const { chosenWords, checkedSub, setCheckedSub, sector, filters, setFilters, setSector, sectorList } = useTenderSearchContext(); // Use the context hook to access context value and function

    useEffect(() => {
        if (sector.length === 0) {
            setFilters(update(filters, { $unset: ["sectors" as keyof TenderFilterInput] }));
        }
        if (checkedSub.length === 0) {
            setFilters(update(filters, { $unset: ["subSectors" as keyof TenderFilterInput] }));
        }
    }, [checkedSub.length, filters, sector.length, setFilters]);

    // Check if array includes all items of other items
    const checker = (arr: any, target: any) => target.every((v: any) => arr.includes(v));

    // Function to handle click on parent
    const handleClickParent = (parentId: string) => {
        setCheckedSub((prevClickedChildIds) => {
            const newClickedChildIds = [...prevClickedChildIds];
            // Remove child IDs associated with this parent from clickedChildIds
            const parent = sectorList.find((parent) => parent?.id === parentId);
            parent?.children?.forEach((child) => {
                const index = newClickedChildIds.indexOf(parseInt(child.id));
                if (index !== -1) {
                    newClickedChildIds.splice(index, 1);
                }
            });
            return newClickedChildIds;
        });

        setSector((prevClickedParentIds) => {
            const newClickedParentIds = [...prevClickedParentIds];
            const index = newClickedParentIds.indexOf(parseInt(parentId));
            if (index !== -1) {
                newClickedParentIds.splice(index, 1);
            } else {
                newClickedParentIds.push(parseInt(parentId));
            }
            return newClickedParentIds;
        });
    };

    // Function to handle click on child
    const handleClickChild = (childId: string) => {
        // Find the parent of the clicked child
        const childParent = sectorList.find((parent) => parent?.children?.some((child) => child.id === childId));
        if (!childParent) return;

        setCheckedSub((prevClickedChildIds) => {
            // Toggle the clicked child's ID in the array of checked child IDs
            const newClickedChildIds = prevClickedChildIds.includes(parseInt(childId))
                ? prevClickedChildIds.filter((id) => id !== parseInt(childId))
                : [...prevClickedChildIds, parseInt(childId)];

            // Get all child IDs of the parent
            const allChildIds = childParent?.children?.map((child) => parseInt(child.id));
            // Filter out clicked child IDs that are present in all child IDs
            const clickedChildIds = newClickedChildIds.filter((id) => allChildIds?.includes(id));

            // Check if all children are clicked
            if (clickedChildIds.length === allChildIds?.length) {
                // If all children are clicked, add the parent to the list of clicked parent IDs
                setSector((prevClickedParentIds) => {
                    if (!prevClickedParentIds.includes(parseInt(childParent.id))) {
                        return [...prevClickedParentIds, parseInt(childParent.id)];
                    }
                    return prevClickedParentIds;
                });
                return newClickedChildIds;
            } else {
                // If not all children are clicked, remove the parent from the list of clicked parent IDs
                setSector((prevClickedParentIds) => prevClickedParentIds.filter((id) => id !== parseInt(childParent.id)));
                return newClickedChildIds;
            }
        });
    };

    return (
        <>
            <Paper
                square
                elevation={0}
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        //TABLET version
                    },
                    [theme.breakpoints.up("lg")]: {
                        //PC version
                        width: "40vw",
                        overflow: "auto",
                        display: "flex",
                    },
                    [theme.breakpoints.up("xxl")]: {
                        //5k version
                        width: "25vw",
                        overflow: "auto",
                        display: "flex",
                    },
                })}
                style={{ flexGrow: chosenWords.length === 0 ? undefined : "0" }}
            >
                <Box
                    sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                            minWidth: "0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                        },
                        [theme.breakpoints.up("md")]: {
                            ///
                        },
                        [theme.breakpoints.up("lg")]: {
                            width: "40vw",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        [theme.breakpoints.up("xxl")]: {
                            width: "25vw",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                    })}
                >
                    {/* Textual helper for user */}
                    <List
                        dense
                        sx={{
                            paddingTop: 0,
                            width: "100%",
                            height: "46vh",
                            overflow: "auto",
                            backgroundColor: (theme) => theme.palette.background.paper,
                        }}
                    >
                        {sectorList.map((value, index) => {
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            const btnLabel = checker(
                                checkedSub,
                                value?.children?.map((i) => parseInt(i.id))
                            );

                            const count = checkedSub.filter((i) => value?.children?.map((i) => parseInt(i.id)).includes(i)).length;

                            return (
                                <React.Fragment key={value?.id}>
                                    <ListSubheader sx={{ display: "flex" }}>
                                        <span>{value?.name}</span>
                                        <FormControlLabel
                                            sx={{ marginLeft: "16px" }}
                                            label=""
                                            control={
                                                <Checkbox
                                                    checked={sector.find((i) => i === parseInt(value?.id as string)) || btnLabel}
                                                    indeterminate={count > 0 && count !== value?.children?.length}
                                                    onChange={() => {
                                                        handleClickParent(value?.id as string);
                                                    }}
                                                />
                                            }
                                        />
                                    </ListSubheader>
                                    {value?.children?.map((c) => {
                                        return (
                                            <ListItemButton key={c.id} onClick={() => handleClickChild(c.id)}>
                                                <ListItemText
                                                    inset
                                                    id={labelId}
                                                    primary={
                                                        <Typography style={{ display: "flex", alignItems: "center" }}>
                                                            <div style={{ marginRight: 12, display: "flex" }}>
                                                                <SectorIconLoader sector_id={c.id} />{" "}
                                                            </div>{" "}
                                                            {c.name}
                                                        </Typography>
                                                    }
                                                />
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            checkedSub.includes(parseInt(c.id)) ||
                                                            Boolean(sector.find((i) => i === parseInt(value?.id as string)))
                                                        }
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                    />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Box>
            </Paper>
        </>
    );
};

export default SectorSearch;
