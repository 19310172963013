import React, { useEffect, useState } from "react";
import SearchWizardContainer from "../Dialog/SearchWizardContainer";
import { useTranslation } from "react-i18next";
import ProfileLayout from "../ProfileLayout";
import setTitle from "../../../utils/setTitle";
import SearchLine from "./SearchLine";
import WizardMenu from "./WizardMenu";
import SearchRuleMenu from "./SearchRuleMenu";
import { useMediaQuery, useTheme } from "@mui/material";
import queryString from "query-string";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

const SearchLineLayout: React.FC = () => {
    const theme = useTheme();
    const [openPopup, setOpenPopup] = useState<string | false>(false);
    const [chosenRule, setChosenRule] = useState<any | null>(null);
    const [chosenRules, setChosenRules] = useState("my");
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const query = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();

    // Track event

    /**
     * Set tab title
     * Check if type is set in url, otherwise set to "all"
     */
    useEffect(() => {
        setTitle(t("SearchRules"));
        if (typeof query.type === "string") {
            if (query.type === "opportunities") {
                trackEvent({ category: "Personal", action: "Click-show-opportunities-searchrule" } as MatomoEvent);
                return setChosenRules("opportunities");
            }
            if (query.type === "tenders") {
                trackEvent({ category: "Personal", action: "Click-show-tender-searchrule" } as MatomoEvent);
                return setChosenRules("tenders");
            }
        } else {
            trackEvent({ category: "Personal", action: "Click-my-tender-searchrule" } as MatomoEvent);
            return setChosenRules("my");
        }
    }, [t, query.type, trackEvent]);

    /**
     * Open modal to create new searchrule
     */
    const handleOpenTenderWizard = () => {
        setOpenPopup("tender");
        trackEvent({ category: "Personal", action: "Click-add-tender-searchrule" } as MatomoEvent);
    };

    const handleEditSearchRule = (type: string, item: any) => {
        setOpenPopup(type);
        setChosenRule(item);
    };

    /**
     * Open modal to create new searchrule
     */
    const handleOpenOpportunityWizard = () => {
        setOpenPopup("opportunity");
        trackEvent({ category: "Personal", action: "Click-add-opportunity-searchrule" } as MatomoEvent);
    };

    /**
     * Open menu to filter searchrules on type
     * @param type => "opportunies" || "tenders" || "all"
     */
    const handleClickMenu = (type: string) => {
        setChosenRules(type);
    };

    return (
        <ProfileLayout
            title={
                <div>
                    <SearchRuleMenu
                        openMenu={handleClickMenu}
                        menuText={
                            chosenRules === "tenders"
                                ? "TenderSearchRules"
                                : chosenRules === "opportunities"
                                ? "OpportunitySearchRules"
                                : chosenRules === "All"
                                ? "AllSearchRules"
                                : "mySearchRules"
                        }
                    />
                </div>
            }
            componentToPassDown={
                noMobile && <WizardMenu openTenderWizard={handleOpenTenderWizard} openOpportunityWizard={handleOpenOpportunityWizard} />
            }
        >
            <SearchLine openModal={handleEditSearchRule} chosenRules={chosenRules} openFirstTime={setOpenPopup} />
            {/* Modal where user can create a searchline step-by-step. */}
            {noMobile && (
                <SearchWizardContainer openPopup={openPopup} setOpenPopup={setOpenPopup} editRule={chosenRule} setEditRule={setChosenRule} />
            )}
        </ProfileLayout>
    );
};

export default SearchLineLayout;
