import React from "react";
import { List, Paper, Typography } from "@mui/material";
import SearchTermWithOperator from "./distributionStep/SearchTermWithOperator";
import AddIcon from "@mui/icons-material/AddBox";
import { Disable } from "react-disable";
interface Props {
    words: string[];
    expanded: string | false;
    onDelete(value: string): void;
    onToggle(): void;
    toggle: boolean;
    checked: boolean;
    type: string;
}

const BasicSearch: React.FC<Props> = ({ words, onDelete, expanded, onToggle, toggle, checked, type }) => {
    return (
        <Paper
            square
            elevation={0}
            sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    //TABLET version
                },
                [theme.breakpoints.up("lg")]: {
                    //PC version
                    width: "40vw",
                    overflow: "auto",
                    display: "flex",
                },
                // 5k styling
                [theme.breakpoints.up("xxl")]: {
                    width: "25vw",
                    overflow: "auto",
                    display: "flex",
                },
            })}
            style={{
                marginBottom: type === "tender" ? 8 : 0,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                flexGrow: expanded === "panel1" ? 0 : 1,
                height: expanded === "panel1" ? 50 : "auto",
            }}
        >
            <div style={{ width: "100%", height: "100%" }}>
                {words.length === 0 && expanded === false ? (
                    // basic/standard view
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <AddIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                                Nog geen zoekwoorden gekozen
                            </Typography>
                        </div>
                    </div>
                ) : // expanded accordion but nothing checked
                expanded === "panel1" ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant="h5" style={{ color: "#d4d4d4", display: "flex", alignItems: "center", fontSize: "1rem" }}>
                                <AddIcon style={{ color: "#f2f2f2", height: "2rem", width: "2rem" }} /> Nog geen zoekwoorden gekozen
                            </Typography>
                        </div>
                    </div>
                ) : // Expanded and checked something -> disabled version
                expanded === "panel1" && checked ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Disable disabled>
                                <Typography variant="h5" style={{ color: "#d4d4d4", display: "flex", alignItems: "center", fontSize: "1rem" }}>
                                    <AddIcon style={{ color: "#f2f2f2", height: "2rem", width: "2rem" }} /> Nog geen zoekwoorden gekozen
                                </Typography>
                            </Disable>
                        </div>
                    </div>
                ) : (
                    <List dense>
                        {words.map((value, i) => {
                            /**
                             * Create a list with selected searchterms
                             * Avatar with OR shows up when user selects multiple words to show user that the basic search uses OR function
                             */
                            return <SearchTermWithOperator key={`${value}${i}`} index={i} value={value} onDelete={onDelete} />;
                        })}
                    </List>
                )}
            </div>
        </Paper>
    );
};

export default BasicSearch;
