import React from "react";
import { ListItem, Select, MenuItem, IconButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

interface Props {
    index: number;
    value: string;
    onDelete(value: string): void;
}

const SearchTermWithOperator: React.FC<Props> = ({ index, value, onDelete }) => {
    const { t } = useTranslation();

    return (
        <ListItem key={value} sx={{ width: "100%" }} data-tut={index === 0 && "reactour__showChosenWord"}>
            {index > 0 && (
                /**
                 * Avatar with OR so user knows that all his selectedwords work with the OR function.
                 */
                <React.Fragment>
                    <Select
                        variant="standard"
                        IconComponent={() => <div />}
                        disabled={true}
                        disableUnderline
                        defaultValue="1"
                        sx={{
                            backgroundColor: "#cccccc",
                            paddingLeft: "8px",
                            marginRight: "8px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            "& .MuiSelect-root": { paddingRight: "8px !important" },
                            "& .MuiSelect-select": { paddingRight: "8px !important" },
                            "& .MuiMenu-paper": {
                                marginLeft: "-8px",
                            },
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                        }}
                    >
                        <MenuItem value={"1"}>{t("advancedSearch.OR")}</MenuItem>
                        <MenuItem value={"2"}>{t("advancedSearch.TOGETHER")}</MenuItem>
                        <MenuItem value={"3"}>{t("advancedSearch.RANGE3")}</MenuItem>
                        <MenuItem value={"4"}>{t("advancedSearch.RANGE5")}</MenuItem>
                        <MenuItem value={"5"}>{t("advancedSearch.RANGE8")}</MenuItem>
                    </Select>
                </React.Fragment>
            )}
            {/* Chosen word with an option to remove the searchterm. */}
            <ListItemText id={value} primary={<Typography>{value}</Typography>} />
            <ListItemSecondaryAction>
                <IconButton size="small" onClick={() => onDelete(value)} data-tut="reactour__showChosenWordDelete">
                    <ClearIcon style={{ opacity: "0.7" }} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default SearchTermWithOperator;
