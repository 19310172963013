import React, { useState } from "react";
import { List, ListItem, ListItemSecondaryAction, ListItemText, Typography, Button, Divider, Tooltip, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";
import CountryFlagBox from "../boxes/CountryFlagBox";
import StatusChip from "../StatusChip";
import { GetStarredOrganizations_currentUser_market_parties } from "../../__generated__/GetStarredOrganizations";
import FaviconBox from "../FaviconBox";
import { GetAwardInfoForCurrentTender_tender_bids } from "../../__generated__/GetAwardInfoForCurrentTender";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    bids: GetAwardInfoForCurrentTender_tender_bids[];
    awardDate: string | null;
    endDate: string | null;
    startDate: string | null;
    lowest_bid: number | null;
    highest_bid: number | null;
    starredOrgs: GetStarredOrganizations_currentUser_market_parties[];
    published_participants_count: any;
}

const BasicEnriched: React.FC<Props> = ({
    bids,
    awardDate,
    endDate,
    startDate,
    highest_bid,
    lowest_bid,
    starredOrgs,
    published_participants_count,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const pink = "#E03660";
    const starColor = "#9e9e9e";
    const ItemsPerClick = bids.length;
    // state for number of items to show
    const [noOfItems, setnoOfItems] = useState(5);
    // copy of bids
    const arrayForSort = [...bids].sort(
        (a: any, b: any) =>
            b.winner - a.winner || // 1. Sort by `winner` (true comes first)
            b.combination - a.combination || // 2. Sort by `combination`
            a.totalValue?.value - b.totalValue?.value || // 3. Sort by value
            a.marketParty.name - b.marketParty.name // 4. Sort by name
    );

    const { trackEvent } = useMatomo();

    // Track event
    const trackMP = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const itemsToShow = (() => {
        // Step 1: Sort the array based on the criteria
        const sortedArray = arrayForSort.sort(
            (a: any, b: any) =>
                b.winner - a.winner || // 1. Sort by `winner`
                b.combination - a.combination || // 2. Sort by `combination`
                b.totalValue?.value - a.totalValue?.value || // 3. Sort by `totalValue.value`
                a.marketParty.name.localeCompare(b.marketParty.name) // 4. Sort by `marketParty.name`
        );

        // Step 2: Slice the array to get the initial `noOfItems` items
        const initialItems = sortedArray.slice(0, noOfItems);

        // Step 3: Check the combination value of the last item in the initial slice
        const lastItemCombination = initialItems[initialItems.length - 1]?.combination;

        // Step 4: Add items with the same combination value as the last item in the initial slice
        let extendedItems = initialItems;
        if (lastItemCombination !== null && lastItemCombination !== undefined) {
            const additionalItems = sortedArray.slice(noOfItems).filter((item) => item.combination === lastItemCombination);
            extendedItems = initialItems.concat(additionalItems);
        }

        return extendedItems;
    })();

    /**
     * Handler to show more items onclick,
     * get totalValue of noOfItems and add totalValue of ItemsPerClick.
     */
    const handleShowMoreItems = () => {
        setnoOfItems(ItemsPerClick);
    };

    const handleShowLess = () => {
        setnoOfItems(5);
    };

    /**
     * key = property of array that has to be unique
     */
    const key = "combination";

    /**
     * map array with last items that have duplicate totalValues,
     * opposite of JS find() function
     */
    const arrayUniqueByKey = [...new Map(arrayForSort.map((item) => [item[key], item])).values()];

    /**
     * get first item that is a duplicate
     */
    const uniqByKeepFirst = (a: any, key: any) => {
        const seen = new Set();
        return a.filter((item: any) => {
            const k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    };

    const wonBidValue = arrayForSort.find((bid) => bid.winner === true);

    function currencyFormatDE(num: number) {
        return num
            .toFixed(2) // always two decimal digits
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    }

    return (
        <React.Fragment>
            {/* List with bids and information */}
            <List
                sx={{
                    "& .MuiList-root": {
                        width: "100%",
                    },
                }}
            >
                {/* listitem with general information of lot */}
                <ListItem style={{ paddingLeft: "8px 16px" }}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <table>
                                    <tbody>
                                        {awardDate !== null && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    <Typography>{t("tenderPage.AwardDate")}</Typography>
                                                </td>
                                                <td>
                                                    <Typography>{moment(awardDate).format("LL")}</Typography>
                                                </td>
                                            </tr>
                                        )}

                                        {wonBidValue && wonBidValue.bid && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    <Typography>{t("WonBidValue")}</Typography>
                                                </td>
                                                <td>
                                                    <Typography>€ {currencyFormatDE(wonBidValue.bid)}</Typography>
                                                </td>
                                            </tr>
                                        )}

                                        {startDate !== null && startDate !== undefined && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    <Typography>{t("tenderPage.StartDuration")}</Typography>
                                                </td>
                                                <td>
                                                    <Typography>{moment(startDate).format("LL")}</Typography>
                                                </td>
                                            </tr>
                                        )}

                                        {endDate !== null && endDate !== undefined && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    <Typography>{t("tenderPage.FirstEnddate")}</Typography>
                                                </td>
                                                <td>
                                                    <Typography>{moment(endDate).format("LL")}</Typography>
                                                </td>
                                            </tr>
                                        )}

                                        {published_participants_count !== undefined && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    <Typography>{t("tenderPage.NumberOfTenderers")}</Typography>
                                                </td>
                                                <td>
                                                    <Typography>{published_participants_count}</Typography>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        }
                    />
                </ListItem>

                {/* map all contractors with logo, winner and more info */}
                {itemsToShow
                    /**
                     * Sort array
                     * 1. winner = true
                     * 2. combination
                     * 3. name of organization
                     */
                    .sort(
                        (a: any, b: any) =>
                            b.winner - a.winner ||
                            b.combination - a.combination ||
                            a.totalValue?.value - b.totalValue?.value ||
                            a.marketParty.name - b.marketParty.name
                    )
                    // .sort((a: any, b: any) => b.combination - a.combination)
                    .map((bid, i) => {
                        const filteredNames = bid.marketParty.names.filter((n) => n.type === "trade_name");

                        return (
                            <React.Fragment key={bid.id}>
                                {/* <ListItem style={{ padding: "8px 16px" }}> */}
                                <ListItem
                                    sx={{
                                        padding: bid.combination === null ? "8px 16px" : "0px 16px",
                                    }}
                                    style={{
                                        paddingTop:
                                            bid.combination !== null &&
                                            uniqByKeepFirst(arrayForSort, (it: any) => it.combination).find(
                                                (t: any) => t.id === bid.id && t.combination !== null
                                            ) &&
                                            8,

                                        // last item of combination -> paddingBottom 8
                                        paddingBottom: arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && 8,
                                    }}
                                >
                                    {/* tenderer logo */}
                                    <div style={{ marginRight: 8 }}>
                                        <FaviconBox
                                            favicon={bid.marketParty.favicon_resized ?? bid.marketParty.favicon}
                                            name={bid.marketParty.name}
                                            color={pink}
                                            marginTop={0}
                                        />
                                    </div>

                                    {/* listitemtext with amount and percentage */}
                                    <ListItemText
                                        primary={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    noWrap
                                                    sx={{
                                                        display: theme.breakpoints.up("xl") ? "inline" : undefined,
                                                        maxWidth: theme.breakpoints.up("xl")
                                                            ? "94%"
                                                            : theme.breakpoints.up("xxl")
                                                            ? "88%"
                                                            : undefined,
                                                    }}
                                                >
                                                    {filteredNames.length > 0 ? (
                                                        <Tooltip
                                                            title={filteredNames
                                                                .sort((a, b) => a.value.localeCompare(b.value))
                                                                .map((item, i) => {
                                                                    return (
                                                                        <React.Fragment key={i}>
                                                                            {i !== filteredNames.length - 1 ? (
                                                                                <Typography variant="caption">{item.value} | </Typography>
                                                                            ) : (
                                                                                <Typography variant="caption">{item.value}</Typography>
                                                                            )}
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                        >
                                                            <Link
                                                                to={`/organizations/${bid.marketParty.id}`}
                                                                onClick={() => {
                                                                    trackMP({
                                                                        category: "Tender",
                                                                        action: "Go-from-award-widget-to-organization-detail",
                                                                    });
                                                                }}
                                                            >
                                                                {bid.marketParty.name}{" "}
                                                            </Link>
                                                        </Tooltip>
                                                    ) : (
                                                        <Link
                                                            to={`/organizations/${bid.marketParty.id}`}
                                                            onClick={() => {
                                                                trackMP({
                                                                    category: "Tender",
                                                                    action: "Go-from-award-widget-to-organization-detail",
                                                                });
                                                            }}
                                                        >
                                                            {bid.marketParty.name}{" "}
                                                        </Link>
                                                    )}
                                                </Typography>
                                                <CountryFlagBox code={bid.marketParty.country?.alpha2 ? bid.marketParty.country.alpha2 : null} />
                                                {/* Render only if starred */}
                                                {starredOrgs.find((s) => s.id === bid.marketParty.id) && (
                                                    <StarIcon style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }} />
                                                )}
                                                {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null)
                                                    ? null
                                                    : bid.combination !== null && (
                                                          <AddIcon style={{ height: "0.7em", width: "0.7em", marginLeft: 16 }} />
                                                      )}
                                            </div>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && bid.bid ? (
                                                    <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                        € {currencyFormatDE(bid.bid)}
                                                    </Typography>
                                                ) : (
                                                    bid.bid &&
                                                    bid.combination === null && (
                                                        <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                            € {currencyFormatDE(bid.bid)}
                                                        </Typography>
                                                    )
                                                )}
                                                {/* Percentage */}
                                                {bid.percentage && (
                                                    <Typography
                                                        style={{ display: "inline-block" }}
                                                        sx={{ color: (theme) => theme.palette.error.main }}
                                                    >
                                                        +{bid.percentage.replace(".", "|").replace(",", ".").replace("|", ",")}%
                                                    </Typography>
                                                )}
                                            </React.Fragment>
                                        }
                                    />

                                    {/* Chip that shows who won the bid */}
                                    <ListItemSecondaryAction style={{ height: "100%", paddingTop: 12 }}>
                                        {/* if winner is true and combination is null (no combo) show winner chip */}
                                        {bid.winner && bid.combination === null ? (
                                            <div style={{ marginLeft: 4 }}>
                                                <StatusChip colored={false} type="winner" />
                                            </div>
                                        ) : // if preliminary is true and combination is null (no combo) show preliminary awarded chip
                                        bid.preliminaryAwarded && bid.combination === null ? (
                                            <div style={{ marginLeft: 4 }}>
                                                <StatusChip colored={false} type="PRELIMINARY_AWARDED" />
                                            </div>
                                        ) : // map over unique array if id is same then show chip -> winner
                                        bid.winner &&
                                          uniqByKeepFirst(arrayForSort, (it: any) => it.combination).find(
                                              (t: any) => t.id === bid.id && t.combination !== null
                                          ) ? (
                                            <div style={{ marginLeft: 4 }}>
                                                <StatusChip colored={false} type="winner" />
                                            </div>
                                        ) : // map over unique array if id is same then show chip -> preliminary awarded
                                        bid.preliminaryAwarded &&
                                          uniqByKeepFirst(arrayForSort, (it: any) => it.combination).find(
                                              (t: any) => t.id === bid.id && t.combination !== null
                                          ) ? (
                                            <div style={{ marginLeft: 4 }}>
                                                <StatusChip colored={false} type="PRELIMINARY_AWARDED" />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>

                                {/* Check if item is last one AND item is not a combination, last one -> show divider */}
                                {i !== itemsToShow.length - 1 && bid.combination === null && <Divider />}

                                {/* Set divider to last index of a combination */}
                                {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && <Divider />}
                            </React.Fragment>
                        );
                    })}
            </List>

            {/*
             * Button to load more contractors
             */}
            {arrayForSort.length > 5 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {itemsToShow.length !== arrayForSort.length && (
                        <Button size="small" variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={handleShowMoreItems}>
                            {t("tenderPage.showAll")}
                        </Button>
                    )}

                    {itemsToShow.length === arrayForSort.length && (
                        <Button size="small" variant="text" color="primary" style={{ marginBottom: 8 }} onClick={handleShowLess}>
                            {t("tenderPage.ShowLess")}
                        </Button>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};
export default BasicEnriched;
