import { gql } from "@apollo/client";

/**
 * File to create queries/mutations for notifications only
 */

/**
 * Query tender notification data
 */
export const TENDER_DATA_NOTIFICATION = gql`
    query GetTenderNotificationData($tender_id: ID!) {
        tender(id: $tender_id) {
            id
            namegiventocontract
            searchresults {
                search {
                    id
                    name
                    color
                }
            }
        }
    }
`;

/**
 * Query tender deadline reminder notification data
 */
export const TENDER_DEADLINE_REMINDER_NOTIFICATION = gql`
    query GetTenderDeadlineNotificationData($tender_id: ID!) {
        tender(id: $tender_id) {
            id
            namegiventocontract
            deadline_inschrijving
        }
    }
`;

/**
 * Query tender next enddate notification data
 */
export const TENDER_NEXT_END_DATE_REMINDER_NOTIFICATION = gql`
    query GetTenderNextEndDateNotificationData($tender_id: ID!) {
        tender(id: $tender_id) {
            id
            namegiventocontract
        }
    }
`;

/**
 * Query tender notification data
 */
export const TENDER_UPDATE_DATA_NOTIFICATION = gql`
    query GetTenderUpdateNotificationData($tender_id: ID!) {
        tender(id: $tender_id) {
            id
            namegiventocontract
            published_opdrachtgever
            clients {
                id
                name
                favicon
                pivot {
                    category
                }
            }
        }
    }
`;

/**
 * Query organization notification data
 */
export const ORGANIZATION_DATA_NOTIFICATION = gql`
    query GetOrganizationNotificationData($org_id: ID!) {
        organization(id: $org_id) {
            id
            name
            favicon
            favicon_resized
        }
    }
`;

/**
 * Query organization notification data
 */
export const CA_DATA_NOTIFICATION = gql`
    query GetCANotificationData($ca_id: ID!) {
        contracting_authority(id: $ca_id) {
            id
            name
            favicon
            favicon_resized
        }
    }
`;

//?  Query for highlighting in title, summary and description
export const GET_SEARCHES_OF_CURRENT_USER = gql`
    query GetSearchesOfCurrentUserNotification {
        currentUser {
            id
            searches {
                id
                name
                color
            }
        }
    }
`;

//?  Query for highlighting in title, summary and description
export const GET_OPPORTUNITY_SEARCHES_OF_CURRENT_USER = gql`
    query GetOpportunitySearchesOfCurrentUserNotification {
        currentUser {
            id
            opportunitySearches {
                id
                name
            }
        }
    }
`;
