import React, { useState } from "react";
import {
    Paper,
    Button,
    TextField,
    List,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
    IconButton,
    Tooltip,
    ListItemButton,
} from "@mui/material";

import { useTranslation } from "react-i18next";
//All icons used in this component
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ListIcon from "@mui/icons-material/List";
import ClearIcon from "@mui/icons-material/Clear";
import { GetWordArray_currentUser_keywords } from "../../../__generated__/GetWordArray";
interface Props {
    type: "tender" | "opportunity";
    onChange(event: any): void;
    onAdd(word: string): void;
    onDeleteWord(word: string, id: string): void;
    onToggle(): void;
    onSelect(value: string): void;
    errors: string;
    toggle: boolean;
    searchTerm: string;
    results: GetWordArray_currentUser_keywords[];
    subSector: boolean;
    selected: GetWordArray_currentUser_keywords[];
    selectedWords: string[];
    loading: boolean;
}

const SearchTermList: React.FC<Props> = ({
    onAdd,
    onChange,
    onDeleteWord,
    onSelect,
    onToggle,
    loading,
    errors,
    toggle,
    searchTerm,
    results,
    subSector,
    selected,
    selectedWords,
    type,
}) => {
    const { t } = useTranslation();
    const [hoverState, setHoverState] = useState<number | boolean>(false);
    const copy = [...results];

    return (
        <div>
            {/*
             * header for searchterms un bucket
             */}
            <h3>{t("wordModal.your-searchterms")}</h3>

            {/*
             * Bucket with searchterms, now chosenWords is prefilled so its possible to use all the different searchmethodes
             */}
            <Paper
                square
                elevation={0}
                sx={(theme) => ({
                    minWidth: "40vw",
                    minHeight: "57vh",
                    maxHeight: "57vh",
                    display: "flex",
                    flexDirection: "column",

                    [theme.breakpoints.up("xxl")]: {
                        // PC version
                        minWidth: "25vw",
                        minHeight: "57vh",
                        maxHeight: "57vh",
                        overflow: "auto",
                        display: "flex",
                    },
                })}
            >
                {/*
                 * Div for searchbar
                 */}
                <div style={{ display: "flex", padding: "10px" }} data-tut="reactour__addWord">
                    {/*
                     * searchbar to search in list or add a new word when word is new
                     */}
                    <TextField
                        data-tut="reactour__addWordField"
                        // if > 0 woord toevoegen/toevoegen
                        placeholder={selected.length > 0 ? "Woord zoeken/toevoegen..." : "Woord toevoegen..."}
                        size="small"
                        variant="outlined"
                        style={{ flexGrow: 1 }}
                        value={searchTerm}
                        onChange={onChange}
                        error={Boolean(errors)}
                        helperText={errors}
                        inputProps={{ maxLength: 50 }}
                        onKeyDown={(e) => {
                            e.key === "Enter" && onAdd(searchTerm);
                        }}
                    />

                    {/*
                     * Button to add a new searchterm when this word doesnt exist in list
                     */}
                    <Button
                        id="add_searchterm_btn"
                        data-tut="add_searchterm_btn"
                        disabled={!searchTerm || searchTerm.length < 3 || selected.some((item) => item.keyword === searchTerm) || Boolean(errors)}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "8px", height: "100%", padding: "8px" }}
                        onClick={() => {
                            onAdd(searchTerm);
                        }}
                    >
                        <AddIcon />
                    </Button>
                </div>

                {/* Fetch keywords */}
                {loading ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
                        <div
                            style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "-122px" }}
                        >
                            <ListIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                                Zoekwoorden ophalen...
                            </Typography>
                        </div>
                    </div>
                ) : // show when user has no keywords added
                selected.length === 0 ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
                        <div
                            style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "-122px" }}
                        >
                            <ListIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                                Je hebt nog geen zoekwoorden toegevoegd
                            </Typography>
                        </div>
                    </div>
                ) : (
                    //  Show all keywords of current user in reverse order. newest on top
                    <List dense sx={{ width: "100%", overflow: "auto" }} data-tut="reactour__showAddWord">
                        {copy.reverse().map((value, i) => {
                            // Set last searchterm to sessionstorage to make it usable in word tour

                            return (
                                <ListItemButton
                                    data-tut={i === 0 && "reactour__showAddWordRemovable"}
                                    style={{ backgroundColor: hoverState === i ? "rgba(0, 0, 0, 0.04)" : "transparent" }}
                                    disabled={subSector}
                                    key={i}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onSelect(value.keyword);
                                    }}
                                    onMouseEnter={() => setHoverState(i)}
                                    onMouseLeave={() => setHoverState(false)}
                                >
                                    <ListItemText
                                        id={value.keyword}
                                        primary={
                                            <React.Fragment>
                                                <Typography>
                                                    {value.keyword}
                                                    {/* Show remove searchterm if id is a numeric string and not the keyword itself
                                                     * Delete mutation needs id to remove from DB
                                                     */}
                                                    {hoverState === i && value.id !== value.keyword && (
                                                        <Tooltip title="Zoekwoord verwijderen" placement="right">
                                                            <IconButton
                                                                sx={{
                                                                    padding: "2px",
                                                                    marginLeft: "4px",
                                                                }}
                                                                size="small"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onDeleteWord(value.id, value.keyword);
                                                                }}
                                                            >
                                                                <ClearIcon style={{ fontSize: "0.7rem" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        style={{ textDecoration: subSector ? "line-through" : "none" }}
                                    />
                                    <ListItemSecondaryAction
                                        style={{
                                            right: 30,
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        onMouseEnter={() => setHoverState(i)}
                                        onMouseLeave={() => setHoverState(false)}
                                        onClick={(e) => {
                                            if (subSector === false) {
                                                e.stopPropagation();
                                                onSelect(value.keyword);
                                            }
                                        }}
                                        id="addBtnID"
                                    >
                                        {hoverState === i && !selectedWords.includes(value.keyword) ? (
                                            // hovered item is not in query already
                                            <Typography variant="button" id="addBtnID">
                                                voeg toe
                                            </Typography>
                                        ) : selectedWords.includes(value.keyword) ? (
                                            //  item is in query already
                                            <CheckIcon style={{ opacity: "0.7" }} />
                                        ) : // TODO: same check as check icon.
                                        // show searchlabel if word is already used in a searchrule
                                        // (i === 2 && type === "tender") || (i === 3 && type === "tender") ? (
                                        //     <LocalOfferOutlinedIcon style={{ opacity: "0.7" }} />
                                        // ) :
                                        null}
                                        {/*
                                         * option to remove
                                         */}
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            );
                        })}
                    </List>
                )}
            </Paper>
        </div>
    );
};

export default SearchTermList;
