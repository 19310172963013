import { useEffect } from "react";
import ClientEcho from "./Echo";

interface WhisperData {
    [key: string]: any;
}

if (!window.Echo) {
    window.Echo = ClientEcho;
}

//* CHANNEL FOR NOTIFICATIONS
export const useSocket = (channel: string, event: string, callBack: (payload: any) => void) => {
    useEffect(() => {
        window.Echo.private(channel).listen(event, (payload: any) => {
            callBack(payload);
        });

        return () => {
            window.Echo.leaveChannel(channel);
        };
    }, [callBack, channel, event]);
};

//* CONNECTION FOR CHATS
//* INCLUDING WHISPERING
export const useSocketForChat = (
    channel: string,
    event: string,
    callBack: (payload: any, eventName: string) => void,
    whisperEvent: string | null = null
) => {
    useEffect(() => {
        const echoChannel = window.Echo.private(channel);

        // Listen for all events on the channel
        echoChannel.listen("*", (payload: any, eventName: any) => {
            callBack(payload, eventName);
        });

        // Optionally listen for whispers if whisperEvent is provided
        if (whisperEvent) {
            echoChannel.listenForWhisper(whisperEvent, (payload: any) => {
                console.log(`Whisper event ${whisperEvent} received:`, payload);
                callBack(payload, whisperEvent);
            });
        }

        return () => {
            if (whisperEvent) {
                echoChannel.stopListeningForWhisper(whisperEvent);
            }

            window.Echo.leaveChannel(channel);
        };
    }, [callBack, channel, whisperEvent]);

    // Function to send a whisper
    const sendWhisper = (eventName: string, data: WhisperData) => {
        window.Echo.private(channel).whisper(eventName, data);
    };

    return { sendWhisper };
};
