import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import CountDown from "./countdown/CountDown";
import HeroDot1 from "./Hero_GUI/HeroDot1";
import OverlappingDotLeft from "./Hero_GUI/OverlappingDotLeft";
import OverlappingDotRight from "./Hero_GUI/OverlappingDotRight";
import WobbleLine from "./Hero_GUI/WobbleLine";
import WhiteBubbleSection from "./Hero_GUI/WhiteBubbleSection";

interface Props {
    a?: number;
}

const Hero: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const extralarge = useMediaQuery(theme.breakpoints.up("xxl"));
    const tendenzRef = useRef<HTMLElement | null>(null);

    return (
        <Box
            id="tendenz"
            ref={tendenzRef}
            py={4}
            sx={{
                height: noMobile ? "100vh" : `calc(100vh - 44px)`,
                display: "flex",
                flexDirection: "column",
                position: "relative",
                background: `linear-gradient(45deg, #70a4b7, #82b6a0)`,
                overflow: "hidden",
            }}
        >
            <div style={{ flex: "1", position: "relative" }}>
                {/* LOGO GIF */}
                <Box sx={{ width: extralarge ? "50vw" : noMobile ? "60vw" : "90vw", zIndex: 99, position: "relative" }}>
                    <img src="https://storage.googleapis.com/tenderguide-marketing-assets/TendenZ.gif" alt="TendenZ" width={"100%"} />
                </Box>

                {/* COUNTDOWN */}
                <CountDown targetDate={new Date("2024-10-08T11:30:00Z")} />
            </div>

            {/* Dot left */}
            {noMobile && <HeroDot1 />}
            {/* Dot  overlap left*/}
            {noMobile && <OverlappingDotLeft />}
            {/* Dot */}
            {noMobile && <OverlappingDotRight />}
            {/* Wobble line */}
            {noMobile && <WobbleLine />}
            {/* White shape behind logo */}
            <WhiteBubbleSection />
        </Box>
    );
};

export default Hero;
