import React, { useState, useEffect } from "react";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { useLazyQuery } from "@apollo/client";
import TenderSearch from "../../../components/TenderSearchComponent/TenderSearch";

import { MUIDataTableState } from "mui-datatables";
import { SortOrder, TenderSearchInput, TenderFilterTarget, TenderFilterInput, TenderFilterSource } from "../../../__generated__/globalTypes";
import setTitle from "../../../utils/setTitle";

import { useTranslation } from "react-i18next";
import TenderTable from "./TenderTable";
import { ColumnContextTndrInt } from "../../../components/contextProviders/ColumnContextTndrInt";
import { TenderColumns } from "./TenderColumns";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import EventView from "../eventView/EventView";
import { View } from "../../../components/TenderSearchComponent/ViewMenu";
import {
    FilterInterestingMinimumTendersOverview,
    FilterInterestingMinimumTendersOverviewVariables,
    FilterInterestingMinimumTendersOverview_filterTenders_filters,
    FilterInterestingMinimumTendersOverview_filterTenders_results,
} from "../../../__generated__/FilterInterestingMinimumTendersOverview";
import { QUERY_TENDERS_INTERESTING_MINIMUM } from "../../../graphql/queryDefinitions";
import { Box } from "@mui/material";

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.ALL_TENDERS,
    target: TenderFilterTarget.SUMMARY,
    filters,
};
const defaultVariables: FilterInterestingMinimumTendersOverviewVariables = {
    page: 1,
    count: 10,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const InterestingTenderOverview: React.FC = () => {
    /**
     * Current page in table
     * default = 1
     */
    const [page, setPage] = useState(defaultVariables.page);
    /**
     * rows per page
     * default = 10 rows
     */
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    /**
     * Current field to use for sorting table (has to be prop name of tenderObject)
     * default = "updated_at"
     */
    const [orderField, setOrderField] = useState<string>(defaultVariables.orderField);
    /**
     * Current order direction
     * default = descending
     */
    const [orderDir, setOrderDir] = useState<SortOrder>(defaultVariables.order);
    /**
     * Current active filters
     * default = { reviews: [1] } => reviews liked is active
     */
    const [searchInput, setSearchInput] = useState<TenderFilterInput>({ reviews: [1] });
    /**
     * User input in searchbox
     * default = ''
     */
    const [query, setQuery] = useState<string>("");
    /**
     * Current target to search in
     * default = summary
     */
    const [target, setTarget] = useState<TenderFilterTarget>(defaultSearch.target);
    /**
     * state with id's of selected rows
     */
    const [selectedRows, setSelectedRows] = useState([]);
    /**
     * Contains all data for filterbar
     * filterset changes based on other states like: active filters/ query (user input)/ target/ source
     */
    const [filterData, setFilterData] = useState<FilterInterestingMinimumTendersOverview_filterTenders_filters[] | null>([]);

    /**
     * Contains all data to fill datatable
     * dataset changes based on other states like: active filters/ query (user input)/ target/ source
     */
    const [tendersData, setTendersData] = useState<FilterInterestingMinimumTendersOverview_filterTenders_results | null>();
    /**
     * State with active view
     */
    const [view, setView] = useState<View | 0>(0);
    // Check if user changes view
    const [changeView, setChangeView] = useState<boolean>(false);

    const { t } = useTranslation();
    const handleView = (view: number) => [setView(view)];

    // Variables for the GQL query
    const variables: FilterInterestingMinimumTendersOverviewVariables = {
        page: page,
        count: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            source: TenderFilterSource.ALL_TENDERS,
            target: target,
            query: query,
            filters: searchInput,
        },
    };

    /**
     * Check if user changes view to a view that is not preferred.
     * if changeview is true => show infotooltip with helptext
     */
    useEffect(() => {
        if (view !== parseInt(JSON.parse(localStorage.getItem("rtv") || "0"))) {
            setChangeView(true);
        } else setChangeView(false);
    }, [view]);

    /**
     * Initialize state from url parameters
     */
    useEffect(() => {
        // set view for review tab (localstorage)
        if (localStorage.getItem("rtv")) {
            setView(parseInt(JSON.parse(localStorage.getItem("rtv") || "0")));
        }
        // tender interesting rows per page (local storage)
        if (localStorage.getItem("rtr")) {
            setRowsPerPage(parseInt(JSON.parse(localStorage.getItem("rtr") || "10")));
        }
        // active page in table (session storage)
        if (sessionStorage.getItem("rtp")) {
            setPage(JSON.parse(sessionStorage.getItem("rtp") || "1"));
        }
        // active filters (session storage)
        if (sessionStorage.getItem("rtf")) {
            setSearchInput(JSON.parse(sessionStorage.getItem("rtf") || "{}"));
        }
        // user query input (session storage)
        if (sessionStorage.getItem("rtq")) {
            setQuery(JSON.parse(sessionStorage.getItem("rtq") || ""));
        }
    }, []);

    // Define the GQL query
    const [run, { error, loading, data }] = useLazyQuery<FilterInterestingMinimumTendersOverview, FilterInterestingMinimumTendersOverviewVariables>(
        QUERY_TENDERS_INTERESTING_MINIMUM,
        {
            variables: variables,
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        if (data && data.filterTenders) {
            setFilterData(data.filterTenders.filters);
            setTendersData(data.filterTenders.results);
        }
    }, [data]);

    useEffect(() => {
        sessionStorage.setItem("rtq", JSON.stringify(query));

        // if query is empty, or only whitespace -> set target to summary
        // relevant docs and all docs will be disabled as filter
        if (typeof query === "string" && query.trim().length === 0) {
            setTarget(TenderFilterTarget.SUMMARY);
        }
    }, [query]);

    useEffect(() => {
        if (view === 0 || 2) {
            if (data && data.filterTenders !== null && data.filterTenders.results !== null) {
                setFilterData(data.filterTenders.filters);
                setTendersData(data.filterTenders.results);
            }
        }
    }, [data, view]);

    // Set page title
    useEffect(() => {
        if (view === 0 || view === 2) {
            sessionStorage.setItem("rtf", JSON.stringify(searchInput));
            sessionStorage.setItem("rtp", JSON.stringify(page));
            localStorage.setItem("rtr", JSON.stringify(rowsPerPage));
            setTitle(t("Reviews"));
            run({
                variables: {
                    page: page,
                    count: rowsPerPage,
                    orderField: orderField,
                    order: orderDir,
                    search: {
                        source: TenderFilterSource.ALL_TENDERS,
                        target: target,
                        query: query,
                        filters: searchInput,
                    },
                },
            });
        }
    }, [run, orderDir, orderField, page, rowsPerPage, searchInput, query, target, t, view]);

    // handle pagination change: -page change, -rows per page change

    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if ((view === 0 || view === 2) && rows !== rowsPerPage) {
            setPage(1);
        }
    }; // handle table change: -sort column, -sort direction
    const handleTableChange = (_: string, tableState: MUIDataTableState) => {
        if (tableState.sortOrder.direction) {
            setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
        }
        if (tableState.sortOrder.name) {
            setOrderField(tableState.sortOrder.name);
        }
    };

    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cutcr" defaultState={TenderColumns}>
            <ColumnContextTndrInt.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                    <TenderSearch
                        changeView={changeView}
                        columns={TenderColumns}
                        totalTenders={tendersData?.paginatorInfo.total || 0}
                        loading={loading}
                        query={query}
                        target={target}
                        setQuery={setQuery}
                        setTarget={setTarget}
                        resetPage={() => setPage(1)}
                        filterData={filterData}
                        viewButtons={true}
                        views={[View.List, View.Event]}
                        onViewClick={handleView}
                        activeView={view}
                        visible={false}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        order={orderDir}
                        orderField={orderField}
                        startSearch={() => {
                            setPage(1);
                            run({ variables });
                        }}
                        resetFilters={() => {
                            setSearchInput({ reviews: [1] });
                            setPage(1);
                        }}
                    />

                    <div style={{ marginTop: 12 }} />

                    {content}
                </Box>
            </ColumnContextTndrInt.Provider>
        </ColumnProvider>
    );

    if (!tendersData && view === 0 && loading) return renderPage(<FullPageSpinner />);
    if (error || (!tendersData && view === 0))
        return renderPage(
            <TenderTable
                loading={loading}
                error={error}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={handleTableChange}
            />
        );

    const getView = (view: View) => {
        switch (view) {
            case View.List:
                // Show default view
                return (
                    <TenderTable
                        loading={loading}
                        rows={tendersData?.data || []}
                        paginatorInfo={
                            tendersData?.paginatorInfo
                                ? tendersData?.paginatorInfo
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 1,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                        onChangePagination={handlePaginationChange}
                        onTableChange={handleTableChange}
                    />
                );
            case View.Event:
                // Show event view
                return (
                    <EventView
                        onChangePagination={handlePaginationChange}
                        onTableChange={handleTableChange}
                        rows={tendersData?.data || []}
                        loading={loading}
                        error={error}
                        paginatorInfo={
                            tendersData?.paginatorInfo
                                ? tendersData?.paginatorInfo
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 1,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                    />
                );

            default:
                return (
                    <TenderTable
                        loading={loading}
                        rows={tendersData?.data || []}
                        paginatorInfo={
                            tendersData?.paginatorInfo
                                ? tendersData?.paginatorInfo
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 1,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                        onChangePagination={handlePaginationChange}
                        onTableChange={handleTableChange}
                    />
                );
        }
    };
    /**
     * Renderpage with active view
     */

    return renderPage(<React.Fragment>{getView(view)}</React.Fragment>);
};

export default InterestingTenderOverview;
