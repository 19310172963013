import React from "react";

interface Props {
    a?: number;
}

const HeroDot1: React.FC<Props> = (props) => {
    return (
        // {/* Dot */}
        <div
            style={{
                position: "absolute",
                top: "200px",
                left: "-250px",
                height: "400px",
                width: "400px",
            }}
        >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#ffffff14"
                    d="M51,-53.3C62.2,-39.7,64.8,-19.9,64.9,0.1C65.1,20.2,62.8,40.3,51.6,51.2C40.3,62.1,20.2,63.7,-1.4,65C-22.9,66.4,-45.8,67.6,-53.1,56.7C-60.3,45.8,-51.9,22.9,-50.7,1.3C-49.4,-20.3,-55.2,-40.7,-47.9,-54.2C-40.7,-67.7,-20.3,-74.4,-0.2,-74.2C19.9,-74,39.7,-66.8,51,-53.3Z"
                    transform="translate(100 100)"
                />
            </svg>
        </div>
    );
};

export default HeroDot1;
