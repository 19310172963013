import { Clear } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { TendenZ_unregister, TendenZ_unregisterVariables } from "../../__generated__/TendenZ_unregister";
import { gql, useMutation } from "@apollo/client";
import { TENDENZ_REGISTRATIONS } from "./FormSection";
import { TENDENZ_SECTORS } from "./CircleGraph";

interface Props {
    id: number;
}

const UnregisterUser: React.FC<Props> = ({ id }) => {
    const [deleteRegistration, { loading: loadDelete }] = useMutation<TendenZ_unregister, TendenZ_unregisterVariables>(TENDENZ_UNREGISTER);
    // Function to register single user on click
    const deleteSingle = async () => {
        try {
            await deleteRegistration({
                variables: {
                    user_id: id,
                    edition: "1",
                },

                refetchQueries: [{ query: TENDENZ_REGISTRATIONS }, { query: TENDENZ_SECTORS }],
                awaitRefetchQueries: true,
            });
        } catch (e) {
            // nothing to do
        }
    };

    return (
        <Tooltip title="Afmelden" placement="left">
            <IconButton disabled={loadDelete} onClick={() => deleteSingle()} sx={{ zIndex: 1200 }}>
                {loadDelete ? (
                    <CircularProgress style={{ color: "#c0392b", height: "24px", width: "24px" }} />
                ) : (
                    <Clear style={{ color: "#c0392b" }} />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default UnregisterUser;

export const TENDENZ_UNREGISTER = gql`
    mutation TendenZ_unregister($user_id: Int!, $edition: String!) {
        DeleteTendenZRegistration(user_id: $user_id, edition: $edition)
    }
`;
