import React from "react";
import { useTranslation } from "react-i18next";

//accordion imports
import { Accordion as MuiAccordion, AccordionSummary, Typography } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PasswordChange from "../security/PasswordChange";
import { withStyles } from "tss-react/mui";
// import SessionLog from "../security/SessionLog";
// import TwoFactor from "../security/TwoFactor";

interface Props {
    a?: number;
}

//accordion style
const Accordion = withStyles(MuiAccordion, {
    root: {
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

const SecuritySettings: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    // default 'panel1' means password change default opened
    // uncomment state and handler when more accordions are in use
    // const [expanded, setExpanded] = useState<string | false>("panel1");

    // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    return (
        <React.Fragment>
            <Accordion
                variant="outlined"
                expanded={true}
                // expanded={expanded === "panel1"}
                // onChange={handleChange("panel1")}
            >
                {/* HeadingBlock  inside summary */}
                <AccordionSummary expandIcon={null}>
                    <Typography variant="h4">{t("Change_password")}</Typography>
                </AccordionSummary>
                {/* Change password component*/}
                <PasswordChange />
            </Accordion>

            {/* Divider */}

            {/* session log accordion */}
            {/* <Accordion variant="outlined" expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4">Sessies beheren</Typography>
                </AccordionSummary>
                <SessionLog />
            </Accordion> */}

            {/* Divider */}

            {/* two factor authentication accordion */}
            {/* <Accordion variant="outlined" expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4">two factor authenticatie</Typography>
                </AccordionSummary>
                <TwoFactor />
            </Accordion> */}
        </React.Fragment>
    );
};

export default SecuritySettings;
