import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Typography, MenuItem, Select, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useTranslation } from "react-i18next";
import GeneralSelector from "../../../../components/input/GeneralSelector";
import update from "immutability-helper";

import {
    DistributionIntervalInput,
    RemindersPreferenceMomentInput,
    RemindersPreferencePeriodInput,
    TenderModulePreferences,
} from "../../../../__generated__/globalTypes";
import { Disable } from "react-disable";

interface Props {
    settings: TenderModulePreferences;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
    saveSettings: (settings: TenderModulePreferences) => void;
}

const TenderNotifications: React.FC<Props> = ({ settings, saveSettings, setChanged }) => {
    console.log("🚀 ~ settings:", settings);
    const { t } = useTranslation();
    const blue = "#173357";
    const [hover, setHover] = useState<any | false>(false);
    const theme = useTheme();
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <table style={{ padding: "0 16px", borderCollapse: "collapse" }}>
            {/*
             * Header with description and icons
             */}
            <tr>
                <td>
                    <Typography variant="h5" style={{ display: "flex", alignItems: "center" }}>
                        <ThumbUpIcon style={{ color: blue, marginRight: "8px", height: "20px", width: "20px" }} />
                        {t("Notifications")}
                    </Typography>
                    <Typography>{t("NotificationsDescription")}</Typography>
                </td>
                <td style={{ width: 150 }}>
                    <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <NotificationsIcon style={{ marginBottom: "4px", color: "#707070" }} />
                        <Typography sx={{ fontSize: "1rem" }}>{t("Mentions")}</Typography>
                    </div>
                </td>
                <td>
                    <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <MailIcon style={{ marginBottom: "4px", color: "#707070" }} />
                        <Typography align="center" sx={{ width: "75px" }} variant={mobileOnly ? "body1" : "h4"}>
                            {t("E-mails")}
                        </Typography>
                    </div>
                </td>
            </tr>
            <tr style={{ height: 16 }} />

            {/* Map over options */}
            {settings?.notifications?.map((setting, i) => {
                return (
                    <tr
                        key={setting?.event}
                        style={{ backgroundColor: hover === setting?.event ? "#f0f0f0" : undefined }}
                        onMouseEnter={() => {
                            setHover(setting?.event);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                    >
                        <td style={{ width: "30vw", paddingLeft: "16px" }}>
                            <Typography sx={{ fontSize: "1rem" }}>{t(setting?.event as string)}</Typography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <FormControlLabel
                                    sx={{ marginLeft: 0, marginRight: 0 }}
                                    control={
                                        <Checkbox
                                            disabled
                                            checked={setting?.notification}
                                            onChange={(e) => {
                                                saveSettings(
                                                    update(settings, {
                                                        notifications: {
                                                            [i]: {
                                                                notification: { $set: e.target.checked },
                                                            },
                                                        },
                                                    })
                                                );
                                                setChanged(true);
                                            }}
                                            name={setting?.event}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </td>
                        <td>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <FormControlLabel
                                    sx={{ marginLeft: 0, marginRight: 0 }}
                                    control={
                                        <Checkbox
                                            checked={setting?.email}
                                            onChange={(e) => {
                                                saveSettings(
                                                    update(settings, {
                                                        notifications: {
                                                            [i]: {
                                                                email: { $set: e.target.checked },
                                                            },
                                                        },
                                                    })
                                                );
                                                setChanged(true);
                                            }}
                                            name={setting?.event}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </td>
                        <td>
                            {setting?.email === true && (
                                <FormControl variant="standard" margin="dense">
                                    <Select
                                        disableUnderline
                                        labelId="demo-simple-select-outlined-label"
                                        defaultValue={setting?.emailInterval || DistributionIntervalInput.DAILY}
                                    >
                                        <MenuItem
                                            value={"HOURLY"}
                                            onClick={(e) => {
                                                saveSettings(
                                                    update(settings, {
                                                        notifications: {
                                                            [i]: {
                                                                emailInterval: { $set: DistributionIntervalInput.HOURLY },
                                                            },
                                                        },
                                                    })
                                                );
                                                setChanged(true);
                                            }}
                                        >
                                            Direct
                                        </MenuItem>
                                        <MenuItem
                                            value={"DAILY"}
                                            onClick={(e) => {
                                                saveSettings(
                                                    update(settings, {
                                                        notifications: {
                                                            [i]: {
                                                                emailInterval: { $set: DistributionIntervalInput.DAILY },
                                                            },
                                                        },
                                                    })
                                                );
                                                setChanged(true);
                                            }}
                                        >
                                            Dagelijks
                                        </MenuItem>
                                        <MenuItem
                                            value={"WEEKLY"}
                                            onClick={(e) => {
                                                saveSettings(
                                                    update(settings, {
                                                        notifications: {
                                                            [i]: {
                                                                emailInterval: { $set: DistributionIntervalInput.WEEKLY },
                                                            },
                                                        },
                                                    })
                                                );
                                                setChanged(true);
                                            }}
                                        >
                                            Wekelijks
                                        </MenuItem>
                                        <MenuItem
                                            value={"MONTHLY"}
                                            onClick={(e) => {
                                                saveSettings(
                                                    update(settings, {
                                                        notifications: {
                                                            [i]: {
                                                                emailInterval: { $set: DistributionIntervalInput.MONTHLY },
                                                            },
                                                        },
                                                    })
                                                );
                                                setChanged(true);
                                            }}
                                        >
                                            Maandelijks
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </td>
                    </tr>
                );
            })}
            {settings?.reminders
                // Filter out the startdate reminder
                ?.filter((f) => f?.event !== "STARTDATE_CONTRACT")
                .map((reminder, i) => {
                    return (
                        <tr
                            key={reminder?.event}
                            style={{ backgroundColor: hover === reminder?.event ? "#f0f0f0" : undefined }}
                            onMouseEnter={() => {
                                setHover(reminder?.event);
                            }}
                            onMouseLeave={() => {
                                setHover(false);
                            }}
                        >
                            <td style={{ width: "30vw", paddingLeft: "16px" }}>
                                <Disable disabled={false}>
                                    {/* IF event is "DEADLINE" show data */}
                                    {reminder?.event === "DEADLINE" ? (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Typography
                                                sx={{
                                                    fontSize: "1rem",
                                                    color: (theme) => (reminder?.email || reminder?.notification ? "#000000" : `${"#000000"}61`),
                                                    marginRight: "8px",
                                                }}
                                            >
                                                {t("Reminder")}
                                            </Typography>

                                            {/* Week selector */}
                                            <div style={{ marginTop: 2 }}>
                                                <GeneralSelector
                                                    openStep={false}
                                                    type="tender"
                                                    onSelect={(e) => {
                                                        saveSettings(
                                                            update(settings, {
                                                                reminders: {
                                                                    [i]: {
                                                                        interval: { $set: parseInt(e) },
                                                                        period: { $set: "WEEKS" as RemindersPreferencePeriodInput },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                        setChanged(true);
                                                    }}
                                                    values={[
                                                        { value: "1", text: "1 week" },
                                                        { value: "2", text: "2 weken" },
                                                        { value: "3", text: "3 weken" },
                                                    ]}
                                                    selectedValue={`${reminder?.interval?.toString()} ${reminder?.interval === 1 ? "week" : "weken"}`}
                                                />
                                            </div>
                                            {/* before/after selector */}
                                            <div style={{ marginTop: 2 }}>
                                                <GeneralSelector
                                                    openStep={false}
                                                    type="tender"
                                                    values={[
                                                        { value: RemindersPreferenceMomentInput.BEFORE, text: `${t("before")}` },
                                                        { value: RemindersPreferenceMomentInput.AFTER, text: `${t("after")}` },
                                                    ]}
                                                    onSelect={(e) => {
                                                        saveSettings(
                                                            update(settings, {
                                                                reminders: {
                                                                    [i]: {
                                                                        moment: { $set: e as RemindersPreferenceMomentInput },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                        setChanged(true);
                                                    }}
                                                    selectedValue={`${t(reminder?.moment?.toLowerCase() as string)}` || t("before")}
                                                />
                                            </div>
                                            <Typography
                                                sx={{
                                                    fontSize: "1rem",
                                                    color: (theme) => (reminder?.email || reminder?.notification ? "#000000" : `${"#000000"}61`),
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                {t("deadline")}
                                            </Typography>
                                        </div>
                                    ) : reminder?.event === "NEXT_ENDDATE_CONTRACT" ? (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Typography
                                                sx={{
                                                    fontSize: "1rem",
                                                    color: (theme) => (reminder?.email || reminder?.notification ? "#000000" : `${"#000000"}61`),
                                                    marginRight: "10px",
                                                }}
                                            >
                                                {t("Reminder")}
                                            </Typography>
                                            <div style={{ marginTop: 2 }}>
                                                <GeneralSelector
                                                    openStep={false}
                                                    type="tender"
                                                    values={[
                                                        { value: "3", text: "3 maanden" },
                                                        { value: "6", text: "6 maanden" },
                                                        { value: "9", text: "9 maanden" },
                                                        { value: "12", text: "12 maanden" },
                                                    ]}
                                                    onSelect={(e) => {
                                                        saveSettings(
                                                            update(settings, {
                                                                reminders: {
                                                                    [i]: {
                                                                        interval: { $set: parseInt(e) },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                        setChanged(true);
                                                    }}
                                                    selectedValue={`${reminder.interval} maanden`}
                                                />
                                            </div>
                                            <div style={{ marginTop: 2 }}>
                                                <GeneralSelector
                                                    openStep={false}
                                                    type="tender"
                                                    values={[
                                                        { value: RemindersPreferenceMomentInput.BEFORE, text: `${t("before")}` },
                                                        { value: RemindersPreferenceMomentInput.AFTER, text: `${t("after")}` },
                                                    ]}
                                                    onSelect={(e) => {
                                                        saveSettings(
                                                            update(settings, {
                                                                reminders: {
                                                                    [i]: {
                                                                        moment: { $set: e as RemindersPreferenceMomentInput },
                                                                        period: { $set: "MONTHS" as RemindersPreferencePeriodInput },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                        setChanged(true);
                                                    }}
                                                    selectedValue={`${t(reminder?.moment?.toLowerCase() as string)}` || t("before")}
                                                />
                                            </div>
                                            <Typography
                                                sx={{
                                                    fontSize: "1rem",
                                                    color: (theme) => (reminder.email || reminder.notification ? "#000000" : `${"#000000"}61`),
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                {t("NEXT_ENDDATE_CONTRACT")}
                                            </Typography>
                                            {/* TODO: v4.x add multiple contract options */}
                                            {/* <GeneralSelector
                                        type="tender"
                                        values={[
                                            { value: `${t("InitialEnddateContract")}`, text: `${t("InitialEnddateContract")}` },
                                            { value: `${t("EveryOptionEnd")}`, text: `${t("EveryOptionEnd")}` },
                                            { value: `${t("Enddate_inc_options")}`, text: `${t("Enddate_inc_options")}` },
                                            { value: `${t("All_possible_EndDates")}`, text: `${t("All_possible_EndDates")}` },
                                        ]}
                                        onSelect={setOption}
                                        selectedValue={options}
                                    /> */}
                                        </div>
                                    ) : (
                                        <Typography style={{ fontSize: "1rem" }}>{t(reminder?.event as string)}</Typography>
                                    )}
                                </Disable>
                            </td>
                            <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Disable disabled={false}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <FormControlLabel
                                            sx={{ marginLeft: 0, marginRight: 0 }}
                                            control={
                                                <Checkbox
                                                    disabled
                                                    checked={reminder?.notification}
                                                    // TODO:change to reminder -> notification
                                                    onChange={(e) => {
                                                        saveSettings(
                                                            update(settings, {
                                                                reminders: {
                                                                    [i]: {
                                                                        notification: { $set: e.target.checked },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                        setChanged(true);
                                                    }}
                                                    name={reminder?.event}
                                                />
                                            }
                                            label=""
                                        />
                                    </div>
                                </Disable>
                            </td>
                            <td>
                                <Disable disabled={false}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <FormControlLabel
                                            sx={{ marginLeft: 0, marginRight: 0 }}
                                            control={
                                                <Checkbox
                                                    checked={reminder?.email}
                                                    onChange={(e) => {
                                                        saveSettings(
                                                            update(settings, {
                                                                reminders: {
                                                                    [i]: {
                                                                        email: { $set: e.target.checked },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                        setChanged(true);
                                                    }}
                                                    name={reminder?.event}
                                                />
                                            }
                                            label=""
                                        />
                                    </div>
                                </Disable>
                            </td>
                        </tr>
                    );
                })}
        </table>
    );
};

export default TenderNotifications;
