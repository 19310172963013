import React, { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
// import { useSocketForChat } from "../../utils/websocketChannels";
// import TypingIndicator from "./TypingIndicator";
// import { useProfile } from "../contextProviders/ProfileOpenerContext";
// import { TypingIndicator } from "@chatscope/chat-ui-kit-react";

interface Props {
    onDocumentClick(): void;
    onSend(text: string): Promise<void>;
    loading: boolean;
    modelType: string;
    conversationId: string;
}

const SendMessageForm: React.FC<Props> = ({ onDocumentClick, onSend, loading, modelType, conversationId }) => {
    const [textMessage, setTextMessage] = useState("");
    // const [typing, setTyping] = useState<{ typing: boolean; name: string }>({ typing: false, name: "" });
    // const { givenname } = useProfile();
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSend(textMessage);
        setTextMessage("");
    };

    // const handleEvent = (payload: any, eventName: string) => {
    //     if (eventName === "typing") {
    //         setTyping({ typing: true, name: payload.user });
    //         setTimeout(() => setTyping({ typing: false, name: "" }), 3000); // Typing indicator lasts for 3 seconds
    //     }
    // };

    // const { sendWhisper } = useSocketForChat(`conversations.${conversationId}`, "", handleEvent, "typing");
    // const { sendWhisper } = useSocketForChat(`user.notifications.10291`, "", handleEvent, "typing");

    // const handleInputChange = () => {
    //     sendWhisper("typing", { user: givenname, typing: true });
    // };

    return (
        <div
            style={{ backgroundColor: "white", padding: "8px", display: "flex", flexDirection: "row", alignContent: "center", position: "relative" }}
        >
            {/* Typing indicator when user has the conversation opened */}
            {/* {typing.typing && (
                <div style={{ position: "absolute", right: 16, top: -32, display: "flex", alignItems: "center" }}>
                    <Typography sx={{ marginRight: "2px" }}>{typing.name} is aan het typen</Typography>
                    <TypingIndicator />
                </div>
            )} */}
            <form style={{ display: "flex", flexGrow: 1, alignItems: "center" }} onSubmit={handleSubmit}>
                <TextField
                    size={"small"}
                    value={textMessage}
                    onChange={(e) => {
                        setTextMessage(e.target.value);
                        // handleInputChange();
                    }}
                    variant="outlined"
                    sx={{
                        width: "100%",
                        marginRight: "10px",
                        borderColor: "#173357",
                    }}
                    placeholder="Type hier een notitie..."
                    multiline
                    maxRows={10}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    type="submit"
                                    sx={{
                                        color: (theme) =>
                                            modelType.includes("Tender")
                                                ? "#173357"
                                                : modelType.includes("MarketParty")
                                                ? "#E03660"
                                                : modelType.includes("ContractingAuthority")
                                                ? "#F57117"
                                                : modelType.includes("Opportunity")
                                                ? "#225E4D"
                                                : "#707070",
                                    }}
                                    disabled={textMessage === "" || loading}
                                    size="small"
                                >
                                    <SendIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {/* <IconButton className={classes.tenderColor} onClick={onDocumentClick}>
                    <AttachFile fontSize="small" />
                </IconButton> */}
            </form>
        </div>
    );
};

export default SendMessageForm;
