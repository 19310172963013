import React, { useEffect, useState } from "react";
import { Grid, Accordion as MuiAccordion, AccordionSummary, Typography, Tooltip, Theme, useTheme, useMediaQuery } from "@mui/material";

//Components
import BasicSearch from "./BasicSearch";
// import AdvancedSearch from "./AdvancedSearch";
import SectorSearch from "./SectorSearch";
import { useTranslation } from "react-i18next";
import SearchTermList from "../SearchTerm/SearchTermList";
import InformationDialog from "./InformationDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Icons used on this page
import { GetUserEmail_currentUser_employee_organization_subscriptions_sectors } from "../../../__generated__/GetUserEmail";
import { useQuery, useMutation } from "@apollo/client";
import { GetWordArray, GetWordArray_currentUser_keywords } from "../../../__generated__/GetWordArray";
import { addWord, addWordVariables } from "../../../__generated__/addWord";
import { deleteWord, deleteWordVariables } from "../../../__generated__/deleteWord";

import ReactCountryFlag from "react-country-flag";
import { withStyles } from "tss-react/mui";
import { MUTATION_ADD_SEARCHTERM, MUTATION_DELETE_SEARCHTERM } from "../../../graphql/mutationDefinitions";
import { QUERY_ALL_SEARCHTERMS } from "../../../graphql/queryDefCurrentUser";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";

interface Props {
    type: "tender" | "opportunity";
    query: string[];
    setQuery: (query: React.SetStateAction<string[]>) => void;
    sectors?: (GetUserEmail_currentUser_employee_organization_subscriptions_sectors | null)[] | null;
    checkedSub?: number[];
    setCheckedSub?(checkedSub: number[]): void;
}

//accordion style
const Accordion = withStyles(MuiAccordion, (theme: Theme) => ({
    root: {
        width: "40vw",
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        [theme.breakpoints.up("xxl")]: {
            //5k version
            width: "25vw",
        },
    },
    expanded: {
        margin: "0 !important",
        height: "51vh",
    },
}));

const WordModal: React.FC<Props> = ({ query, setQuery, type }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { checkedSub, sector, chosenWords } = useTenderSearchContext(); // Use the context hook to access context value and function

    // selectedWords defaultvalue has to be user searchterms
    const { data: queryWordArray, loading } = useQuery<GetWordArray>(QUERY_ALL_SEARCHTERMS);
    const [selectedWords, setSelectedWords] = useState<GetWordArray_currentUser_keywords[]>([]);

    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        setSelectedWords(queryWordArray?.currentUser.keywords || []);
        // check if checked > 0 -> open accordion
        (checkedSub.length > 0 && chosenWords.length === 0) || (sector.length > 0 && chosenWords.length === 0)
            ? setExpanded("panel1")
            : setExpanded(false);
    }, [checkedSub.length, chosenWords.length, queryWordArray?.currentUser.keywords, sector.length, setSelectedWords]);

    /**
     * chosenWords -> words that are selected by the user
     */
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [toggle, setToggle] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [errors, setErrors] = useState<string>("");
    const [expanded, setExpanded] = useState<string | false>(false);

    const [saveWord] = useMutation<addWord, addWordVariables>(MUTATION_ADD_SEARCHTERM);
    const [deleteWord] = useMutation<deleteWord, deleteWordVariables>(MUTATION_DELETE_SEARCHTERM);

    /**
     * handler to close subsectorpanel
     */
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    /**
     * handler to open information dialog
     */
    // TODO: add information tooltip 4.x
    // const handleClickOpenInformationDialog = () => {
    //     setOpenDialog(true);
    // };

    /**
     * handler to close the information dialog
     */
    const handleCloseInformationDialog = () => {
        setOpenDialog(false);
    };

    /**
     * Add a word to the searchterm selection for this specific search rule.
     * @param s selected word to add to selection for this search rule
     */
    const handleSelect = (s: string) => {
        setExpanded(false);
        if (!query.includes(s)) {
            setQuery((selectedWords) => [...selectedWords, s]);
        }
    };

    /**
     * Delete a word from the chosenwords.
     * Chosenwords is the state with the selection terms for this specific search rule.
     * @param value selected word to delete.
     */
    const handleDelete = (value: string) => {
        setQuery(query.filter((o) => o !== value));
    };

    /**
     * Delete a word from the list
     * @param value selected word-id to delete.
     */
    const handleDeleteWord = async (id: string, word: string) => {
        setSelectedWords(selectedWords.filter((o) => o.keyword !== word));
        try {
            await deleteWord({
                variables: {
                    id: parseInt(id),
                },
            });
        } catch (e) {}
    };

    /**
     * Toggle action to switch between basic and advanced search
     * TODO: alert when user switch back to basic search
     */
    const handleToggle = () => {
        setToggle(!toggle);
    };

    /**
     * Search in list if words are already in list or not
     * Regex to check if word has no special characters.
     * If regex doesnt match, errortext wil appear.
     * @param event userinput from textfield
     */
    const handleSearchChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setErrors("");
        setSearchTerm(value);

        sessionStorage.removeItem("lastSearchTerm");
        sessionStorage.setItem("lastSearchTerm", JSON.stringify(value));

        /**
         * characters that are allowed to use in the TextField
         * 2 spaces, text (+specials) and numbers
         */
        // const reg = new RegExp(/^$|^[a-zA-Z\d]+$/).test(value);
        const reg = new RegExp(/^[A-Za-zÀ-ȕ\d-]+(\s[A-Za-zÀ-ȕ\d-]*){0,2}$/).test(value);
        if (value.length === 0) {
            setErrors("");
        }
        if (value === " ") {
            setErrors("Spatie kan niet als eerste character");
        }
        if (value.split(" ").length > 3) {
            setErrors("Maximaal 3 woorden");
        }
        if (!reg && value.length > 1 && value.split(" ").length <= 3) {
            setErrors("Speciale tekens zijn niet toegestaan");
        }

        /**
         * get text inside div with id 'searchtermToReplace'
         */
        const term = document.getElementById("searchtermToReplace");

        // Get live data from searchtermToReplace when guide is active
        // Workaround to get live data in guide. (state not working when guide is shown)
        if (term) {
            term.innerHTML = value;
        }
    };

    /**
     * When searchterm is false, show all words
     * When searchTerm is true, show words with matching characters.
     * - item and searchTerm values to lowercase to check if the characters are the same. Now it doesnt matter if user or input is capitalchars or not
     */
    const results = !searchTerm ? selectedWords : selectedWords.filter((item) => item.keyword.toLowerCase().includes(searchTerm.toLowerCase()));

    /**
     * FUNCTION TO ADD NEW WORD
     * - check if this word doesnt exist in list
     * - minimum of 3 characters
     * - when added to list, not possible to add again
     * - clear textfield after adding word to list
     */
    const addWordToBucket = async (word: string) => {
        const reg = new RegExp(/^[A-Za-zÀ-ȕ\d-]+(\s[A-Za-zÀ-ȕ\d-]*){0,2}$/).test(word);
        if (reg && searchTerm.length >= 3 && !selectedWords.some((item) => item.keyword.toLocaleLowerCase() === word.toLocaleLowerCase())) {
            // add word to local state
            setSelectedWords([...selectedWords, { id: `${word}`, keyword: word, __typename: "Keyword" }]);
            setSearchTerm("");
            try {
                await saveWord({
                    variables: {
                        keyword: word,
                    },
                    refetchQueries: [
                        QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                        "GetWordArray", // Query name
                    ],
                });
            } catch (e) {}
        }
    };

    /**
     *  Text for dialog that shows up when user clicks on ( i )
     */
    const textHelper =
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic aliquid culpa quis enim, fugit, saepe magni dolor ea nisi velit doloribus consectetur beatae placeat magnam sint, quaerat animi voluptatibus vel?";

    // TODO: remove hide to show systemrules option 4.x
    const hide = false;
    const shouldReturnTrue: boolean = (sector.length > 0 && selectedWords.length === 0) || (checkedSub.length > 0 && selectedWords.length === 0);

    return (
        <>
            {/* Grid with 2 paper items for searchterm selection. */}
            <Grid container style={{ width: "100vw" }}>
                {/*
                 *   Grid item with a list of search terms. In this list its possible to choose terms for search rule.
                 *   user can search in list and add word if needed
                 */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{
                        display: "flex",
                        marginTop: "20px",
                    }}
                    style={{ justifyContent: "flex-end", paddingRight: "20px" }}
                >
                    <div style={{ width: onlyMobile ? "100%" : undefined }}>
                        <SearchTermList
                            type={type}
                            results={results}
                            onChange={handleSearchChange}
                            onAdd={addWordToBucket}
                            onToggle={handleToggle}
                            onSelect={handleSelect}
                            toggle={toggle}
                            errors={errors}
                            searchTerm={searchTerm}
                            subSector={shouldReturnTrue}
                            selected={selectedWords}
                            selectedWords={query}
                            loading={loading}
                            onDeleteWord={handleDeleteWord}
                        />
                    </div>
                </Grid>

                {/* Grid item with 3 different options 
                - sector search
                - basic search (only OR)
                - advanced search
                */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{
                        display: "flex",
                        marginTop: (theme) => (theme.breakpoints.down("sm") ? "20px" : "30px"),
                    }}
                    style={{ flexDirection: "column", justifyContent: "flex-start", paddingLeft: "20px" }}
                >
                    <div style={{ width: onlyMobile ? "100%" : undefined }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <h3>{t("wordModal.chosenterms")}</h3>
                            {/* Infobutton to open a modal with helptext */}
                            {/* <IconButton size="small" style={{ marginLeft: 4 }}>
                                <InfoOutlinedIcon fontSize="small" onClick={handleClickOpenInformationDialog} />
                            </IconButton> */}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", minHeight: "57vh", maxHeight: "57vh" }}>
                        <BasicSearch
                            type={type}
                            words={query}
                            onDelete={handleDelete}
                            onToggle={handleToggle}
                            toggle={toggle}
                            expanded={expanded}
                            checked={shouldReturnTrue}
                        />

                        {type === "tender" && (
                            <Accordion
                                data-tut="reactour__searchWithSubSector"
                                disabled={query.length !== 0}
                                variant="outlined"
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                            >
                                {/*
                                 * HeadingBlock  inside summary
                                 */}
                                <AccordionSummary
                                    sx={{
                                        maxHeight: "50px",
                                        "& .MuiAccordionSummary-root": { minHeight: "48px !important", maxHeight: "48px !important" },
                                        "& .Mui-expanded": { minHeight: "48px !important", maxHeight: "48px !important" },
                                    }}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
                                        {query.length !== 0
                                            ? "Deelsector kan in de resultatenstap als filter worden geactiveerd"
                                            : "Of kies deelsector"}
                                        {/*
                                         *  Show countryflag with textual helper
                                         */}
                                        <Tooltip placement="right" title={"Deelsectoren zijn alleen beschikbaar voor Nederland"}>
                                            <div style={{ display: "inline", height: 26, marginLeft: 4 }}>
                                                <ReactCountryFlag
                                                    style={{
                                                        height: 11,
                                                        marginLeft: 4,
                                                    }}
                                                    countryCode={"NL"}
                                                    svg
                                                />
                                            </div>
                                        </Tooltip>
                                    </Typography>
                                </AccordionSummary>
                                {/* sectorsearch component*/}
                                <SectorSearch />
                            </Accordion>
                        )}
                        {type === "opportunity" && hide && (
                            <Accordion
                                data-tut="reactour__searchWithSubSector"
                                disabled={query.length !== 0}
                                variant="outlined"
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                            >
                                {/*
                                 * HeadingBlock  inside summary
                                 */}
                                <AccordionSummary
                                    sx={{
                                        "& .MuiAccordionSummary-root": { minHeight: "48px !important", maxHeight: "48px !important" },
                                        "& .Mui-expanded": { minHeight: "48px !important", maxHeight: "48px !important" },
                                    }}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
                                        Of kies een suggestie
                                    </Typography>
                                </AccordionSummary>
                                {/* sectorsearch component*/}
                                {/* //TODO: Add checked systemrule query data (setQuery(checked)) */}
                                {/* <SystemRuleSearch words={query} checked={checkedSub} setChecked={setCheckedSub} expanded={expanded} /> */}
                            </Accordion>
                        )}
                    </div>
                </Grid>
            </Grid>

            {/* InformationDialog for user to see information for the search options. */}
            <InformationDialog open={openDialog} onClose={handleCloseInformationDialog} dialogTitle={"Informatievenster"} dialogText={textHelper} />
        </>
    );
};

export default WordModal;
