import React from "react";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { AccessTime, Event, Restaurant } from "@mui/icons-material";
import StaticEventCard from "./program/StaticEventCard";
import PresentationCard from "./program/PresentationCard";

interface Props {
    a?: number;
}

const programData = [
    {
        title: "Inloop",
        time: "13:30 uur",
        icon: <Event />,
        bg: "#82b6a0",
        img: "https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png",
        smaller: true,
        size: 0.5,
    },
    {
        title: "Aanvang",
        time: "14:00 uur",
        icon: <AccessTime />,
        bg: "#82b6a0",
        img: "https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png",
        smaller: true,
        size: 0.5,
    },
    {
        title: "Infense Advocaten",
        subTitle: "Liesbeth Haverkort",
        time: "14:15 uur",
        icon: <Restaurant />,
        bg: "#70a4b7",
        // img: "https://storage.googleapis.com/employee-files-public/2k0Otzu9NTGB4BEx3RjyyNgoFq4teKyoafjvaTfw.jpg",
        img: "https://112.wpcdnnode.com/infense.nl/wp-content/uploads/2019/01/Liesbeth.jpg",
        size: 3,
        function: "Advocaat - partner aanbestedingsrecht en bouwrecht",
        programSneak: "Het vinden van voldoende rechtsbescherming bij aanbestedingen blijft uitdagend. Natuurlijk zijn er mogelijkheden om...",
        text: "Het vinden van voldoende rechtsbescherming bij aanbestedingen blijft uitdagend. Natuurlijk zijn er mogelijkheden om zaken aan de kaak te stellen. Het voelt desondanks niet altijd rechtvaardig of effectief. Timing is belangrijk om effectief te kunnen zijn! Liesbeth doet een kleine greep uit de praktijk. Er zijn ook ontwikkelingen waar te nemen. In Europese rechtspraak is de motiveringsplicht in relatie tot de uitsluitingsgrond uitgebreid. Veranderingen in wetgeving zijn aangekondigd, al vergt dit een oefening in geduld. De internetconsultatie van het wetsvoorstel Rechtsbescherming bij aanbesteden sloot in het najaar van 2023. Sindsdien is het stil gebleven. Vroeg of laat zal het een vervolg gaan krijgen. Liesbeth neemt u mee in de highlights.",
    },
    {
        title: "Rijkswaterstaat",
        subTitle: "Joop Forster",
        time: "15:00 uur",
        icon: <Event />,
        bg: "#70a4b7",
        img: "https://storage.googleapis.com/tenderguide-public/img_joop4.png",
        size: 3,
        function: "Adviseur Duurzaam Inkopen",
        programSneak: "Rijkswaterstaat heeft de ambitie om in 2030 klimaatneutraal en circulair te werken. De Transitiepaden, waarin...",
        text: "Rijkswaterstaat heeft de ambitie om in 2030 klimaatneutraal en circulair te werken. De Transitiepaden, waarin Rijkswaterstaat samenwerkt met andere opdrachtgevers, zijn in gesprek met de markt, maken beleid en formuleren inkoopstrategieën. Het programma Duurzame Infra helpt projectteams om deze ambities in de praktijk te realiseren met behulp van het proces Innoveren, Uniformeren, Produceren. Adviseur Duurzaam Inkopen Joop Förster ontwikkelt inkoopinstrumenten en adviseert GWW-projecten. Hij vertelt hoe Rijkswaterstaat duurzaam inkoopt.",
    },
    {
        title: "Coffee break",
        time: "",
        icon: <Event />,
        bg: "#82b6a0",
        // img: "https://images.pexels.com/photos/312418/pexels-photo-312418.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        img: "https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png",
        smaller: true,
        size: 1,
    },
    {
        title: "Bernays Institute",
        subTitle: "Bart Willems",
        time: "16:00 uur",
        icon: <Event />,
        bg: "#70a4b7",
        img: "https://storage.googleapis.com/employee-files-public/utU2bDwH8MyUkfycsfBRgO32vDRUH1YcxtPG56JB.jpg",
        size: 3,
        function: "Managing Director",
        programSneak: "Laat de subjectiviteit voor jou werken! Interpretatie en vooroordelen spelen nog altijd een grote rol bij...",
        text: "Laat de subjectiviteit voor jou werken! Interpretatie en vooroordelen spelen nog altijd een grote rol bij aanbestedingen. Juist vanuit het onderbewuste maakt een beoordelaar keuzes. Dat is geheel menselijk. Bernays Institute heeft methoden ontwikkelt om het denken, redeneren of het nemen van beslissingen te beïnvloeden. Hiermee tillen wij ‘tenderen’ naar het volgende niveau.",
    },
    {
        title: "Netwerkborrel",
        time: "17:00 uur",
        icon: <Event />,
        bg: "#82b6a0",
        smaller: true,
        // img: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        img: "https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png",
        size: 1,
    },
];

const ProgramSection: React.FC<Props> = (props) => {
    // const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    // const handleExpandClick = (index: number) => {
    //     setExpandedIndex(expandedIndex === index ? null : index);
    // };

    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Box py={4} px={4} sx={{ display: "flex", flexDirection: "column" }}>
            <div id="program" />
            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                Programma
            </Typography>

            {noMobile && <div style={{ height: "100px" }} />}

            <Grid container sx={{ alignItems: "center", flexDirection: noMobile ? "row" : "column", height: "60%" }} spacing={2}>
                {programData.map((item, index) => {
                    // hide Inloop
                    if (item.title === "Inloop" && noMobile) {
                        return null;
                    }
                    // show aanvang & inloop in one section
                    if (item.title === "Aanvang" && noMobile) {
                        return (
                            <Grid item flex={1} key={item.title} sx={{ display: "flex", flexDirection: "column", height: "52vh" }} xs={12} md={1}>
                                <StaticEventCard
                                    eventName={"Inloop"}
                                    eventTime={"13:30 uur"}
                                    image={"https://cdn.pixabay.com/photo/2022/03/31/14/10/aroace-7103105_1280.png"}
                                    reverseImg={true}
                                />
                                <div style={{ height: "16px" }} />
                                <StaticEventCard
                                    eventName={"Aanvang"}
                                    eventTime={"14:00 uur"}
                                    image={"https://cdn.pixabay.com/photo/2022/03/31/14/10/aroace-7103105_1280.png"}
                                    reverseImg={false}
                                />
                            </Grid>
                        );
                    }

                    // Return all other objects
                    return (
                        <Grid item flex={1} key={index} sx={{ display: "flex", height: "60%", width: "100%" }} xs={12} md={item.size}>
                            <PresentationCard
                                eventText={item.text ?? ""}
                                bgColor={item.bg}
                                image={item.img}
                                eventTitle={item.title}
                                eventSubTitle={item.subTitle ?? ""}
                                eventTime={item.time}
                                eventSmallText={item.function ?? ""}
                                smaller={item.smaller ?? false}
                                programTextSneakPreview={item.programSneak ?? ""}
                            />
                        </Grid>
                    );
                })}
            </Grid>

            {noMobile && <div style={{ height: "100px" }} />}
        </Box>
    );
};

export default ProgramSection;
