import React, { lazy, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import classes from "./NavBar.module.scss";
import ProfileBox from "./ProfileBox";
import { ReactComponent as LogoIcon } from "../../../assets/tenderguide-icon.svg";
import { useTheme, Theme, Avatar, useMediaQuery, Collapse, Typography } from "@mui/material";
import UserSupport from "../../chat/UserSupport";
import { useFaq } from "../../contextProviders/FaqOpenerContext";
import { useProfile } from "../../contextProviders/ProfileOpenerContext";
import NotificationsMenu from "../../notifications/NotificationsMenu";
import SupportMenu from "./SupportMenu";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";
import NewUserGuide from "../../guides/newUser/NewUserGuide";
import { useQuery } from "@apollo/client";
import { queryIntroSeen } from "../../../__generated__/queryIntroSeen";
import { QUERY_INTRO_HAS_SEEN } from "../../../graphql/queryDefCurrentUser";
import moment from "moment";
import CollapsableNavBar from "./CollapsableNavBar";

const NewUserLoggedInDialog = lazy(() => import("../../dialogs/NewUserLoggedIn"));
const TendenZ = lazy(() => import("../../dialogs/TendenZTeaser"));

interface Props extends WithTranslation {
    activePage?: string;
}

const StyledAvatar = withStyles(Avatar, (theme: Theme) => ({
    root: {
        width: "30px",
        height: "30px",
        fontSize: "0.9rem",
        backgroundColor: "#454545",
    },
}));

const NavBar: React.FunctionComponent<Props> = ({ t, activePage }) => {
    const theme = useTheme();
    const location = useLocation();
    const { setOpenFaq, setView } = useFaq();
    const { trackEvent } = useMatomo();
    const { openProfile, setOpenProfile, initials, datafetched } = useProfile();
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
    const anchorpoint = useRef<any>(null);
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [userIsNew, setUserIsNew] = useState<boolean>(false);
    // state keeps track if moment of tendenz seen is before hard coded date
    const [showTendenZ, setShowTendenz] = useState<boolean>(false);
    const [tendenzModal, setTendenzmodal] = useState<boolean>(false);

    // const [openChat, setOpenChat] = useState(false);
    const [openSupport, setOpenSupport] = useState<boolean>(false);
    const [newUserLoggedInmodal, setNewUserLoggedInmodal] = useState<boolean>(false);
    const [startNewUserTour, setStartNewUserTour] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);

    const isTendenz = location.pathname === "/tendenz";

    /**
     * Fetch tender.events, deadline_inschrijving
     */
    const { data } = useQuery<queryIntroSeen>(QUERY_INTRO_HAS_SEEN, {
        onCompleted: (data) => {
            if (data && data.currentUser && data.currentUser.hasSeenIntro === null) {
                setUserIsNew(true);
            } else if (!isTendenz && moment(data.currentUser.hasSeenIntro).isBefore(moment("30-07-2024", "DD-MM-YYYY"))) {
                setShowTendenz(true);
            }
        },
    });

    useEffect(() => {
        if (datafetched && data?.currentUser.hasSeenIntro === null && noMobile) {
            setNewUserLoggedInmodal(true);
        }
        if (datafetched && !isTendenz && moment(data?.currentUser.hasSeenIntro).isBefore(moment("30-07-2024", "DD-MM-YYYY")) && noMobile) {
            setTendenzmodal(true);
        }
    }, [data?.currentUser.hasSeenIntro, datafetched, isTendenz, noMobile]);

    // start & end time for online support, at the moment hardcoded with a switch in operator chat
    // const currentTime = moment(); // e.g. 11:00 pm
    // const startTime = moment("09:00:00", "HH:mm:ss");
    // const endTime = moment("16:30:00", "HH:mm:ss");

    // const amIBetween = currentTime.isBetween(startTime, endTime);
    // const supportState = localStorage.getItem("supportState");

    const backToHome = async () => {
        setOpenFaq(false);
        await sleep(100);
        setView("start");
    };

    // track navigation items
    const trackNavigation = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <React.Fragment>
            {/* Navigation icons to switch between modules */}

            <div
                className={checked ? classes.NavBarHover : classes.NavBar}
                style={{ backgroundColor: theme.palette.primary.main, justifyContent: "space-between" }}
            >
                {/* Top part of the NavBar */}
                <div style={{ width: "100%", overflow: "hidden" }} onMouseOver={() => setChecked(true)} onMouseLeave={() => setChecked(false)}>
                    {/* TenderGuide logo */}
                    <div className={classes.iconContainer}>
                        <Link
                            to="/"
                            style={{ height: 30, width: 30, overflow: "hidden" }}
                            onClick={() => trackNavigation({ category: "Dashboard", action: "Go-to-dashboard" })}
                        >
                            <LogoIcon title="TenderGuide" className={classes.tenderguideIconSvg} />
                        </Link>
                    </div>
                    <Collapse
                        collapsedSize={48}
                        in={checked}
                        timeout={500}
                        orientation="horizontal"
                        sx={{
                            ".MuiCollapse-wrapperInner": {
                                width: "100%",
                            },
                            overflow: "hidden",
                        }}
                    >
                        <div data-tut="reactour__navbarmodules">
                            <CollapsableNavBar activePage={activePage} checked={checked} />
                        </div>
                    </Collapse>
                </div>

                {/* Bottom part of the NavBar */}
                {noMobile && (
                    <div className={classes.columnCentered} style={{ paddingBottom: 10 }} data-tut="reactour__navbarpersonal">
                        {/*
                         * Notification pop up component
                         */}
                        <NotificationsMenu checked={checked} />
                        {/* <div style={{ height: "6px" }} /> */}

                        {/*
                         * Menu with help options
                         * - Interactive guide (TODO)
                         * - Knowledgebase
                         * - Send support message
                         * - Screen share
                         */}
                        <SupportMenu openSupport={openSupport} setOpenSupport={setOpenSupport} checked={checked} />

                        {/* TourMenu */}

                        {/* user support pop up component */}
                        <UserSupport
                            anchorEl={anchorpoint.current}
                            handleClose={() => {
                                backToHome();
                            }}
                            // TODO change input state to GQL or something to activate online support
                            supportState={false}
                        />
                        {/* Profile button */}
                        {/* <div style={{ height: "6px" }} /> */}
                        <div
                            style={{ display: "flex" }}
                            onClick={() => {
                                setOpenProfile(true);
                                trackNavigation({ category: "Personal", action: "Open-profile-modal" });
                            }}
                        >
                            <IconButton data-tut="reactour__navbarpersonalpages" ref={anchorpoint} sx={{ height: "48px", width: "48px" }}>
                                <StyledAvatar>{initials}</StyledAvatar>
                            </IconButton>
                            {/* Navbar hover state */}
                            <div style={{ width: "100%", display: "flex", alignItems: "center", marginLeft: "8px", overflow: "hidden" }}>
                                <Collapse timeout={500} mountOnEnter unmountOnExit in={checked} orientation="horizontal">
                                    <Typography variant="h6" fontWeight={500} color={"#fff"} sx={{ "&:hover": { fontWeight: 600 } }}>
                                        Profiel
                                    </Typography>
                                </Collapse>
                            </div>
                        </div>
                        <ProfileBox anchorEl={anchorpoint.current} opened={openProfile} handleClose={() => setOpenProfile(false)} />
                    </div>
                )}
            </div>

            {/*
             * Welcome modal with button to start a first guide.
             * First guide will show a user what he can expect from each module in app.
             */}

            {showTendenZ && sessionStorage.getItem("hideTendenZModal") === null && (
                <TendenZ handleClose={() => setTendenzmodal(false)} open={tendenzModal} />
            )}

            {userIsNew && sessionStorage.getItem("hideNewUserModal") === null && (
                <NewUserLoggedInDialog
                    open={newUserLoggedInmodal}
                    userName={data?.currentUser.employee.givenname ?? ""}
                    handleClose={(openTendenZ: boolean) => {
                        if (openTendenZ) {
                            setShowTendenz(true);
                            setTendenzmodal(true);
                        }
                        setNewUserLoggedInmodal(false);
                    }}
                    setStartNewUserTour={setStartNewUserTour}
                />
            )}
            {datafetched && startNewUserTour && (
                <NewUserGuide
                    setTendenz={() => {
                        setShowTendenz(true);
                        setTendenzmodal(true);
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default withTranslation("translation")(NavBar);
