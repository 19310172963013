import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import "./Login.scss";
import setTitle from "../utils/setTitle";
import { useTranslation } from "react-i18next";
import LoginHeader from "../components/login/LoginHeader";
import LanguageSelector from "../components/login/LanguageSelector";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Login as LoginType, LoginVariables } from "../__generated__/Login";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GetCurrentUser } from "../__generated__/GetCurrentUser";
import { updateProfileSettings, updateProfileSettingsVariables } from "../__generated__/updateProfileSettings";
import { UPDATEPERSONALSETTINGS } from "../graphql/mutationDefinitions";
import { QUERY_CURRENT_USER_SETTINGS } from "../graphql/queryDefCurrentUser";

interface Props {
    a?: number;
}

type LocationState = {
    email: string;
    from: {
        pathname: string;
        search: string;
        hash: string;
    };
};

const Login: React.FC<Props> = (props) => {
    const location = useLocation();
    const { from } = (location.state as LocationState) || { from: { pathname: "/" } };
    const [email, setEmail] = useState<string>(
        // process.env.REACT_APP_PREFILLED_USERNAME ||
        ""
    );
    const [password, setPassword] = useState<string>(
        // process.env.REACT_APP_PREFILLED_PASSWORD ||
        ""
    );
    const [error, setError] = useState<string | undefined>(undefined);
    const [login, { loading }] = useMutation<LoginType, LoginVariables>(LOGIN);
    const [getCurrentUser] = useLazyQuery<GetCurrentUser>(GET_CURRENT_USER);

    const navigate = useNavigate();
    const { t } = useTranslation();

    // Set title of the page
    setTitle("Login");

    // If requested, extract the redirect url to redirect the user after login
    const redirectObject = (location.state as LocationState) && from;
    const redirectUrl = redirectObject ? `${redirectObject.pathname}${redirectObject.search}${redirectObject.hash}` : undefined;
    const [save] = useMutation<updateProfileSettings, updateProfileSettingsVariables>(UPDATEPERSONALSETTINGS);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (loading) return;
        if (!email || !password) {
            setError(t("auth.error-login-no-input"));
            return;
        }

        try {
            const authResponse = await login({ variables: { email, password } });

            if (!authResponse.data?.login.token) {
                throw new Error("No token was provided");
            }

            localStorage.setItem("access_token", authResponse.data?.login.token);

            const currentUserData = await getCurrentUser();
            localStorage.setItem("user_id", currentUserData.data?.currentUser.id || "undefined");

            localStorage.setItem("i18nextLng", "nl");
            // Check if the user has a language set, if not, set it to Dutch (nl)
            if (currentUserData.data?.currentUser.language !== "nl") {
                save({
                    variables: {
                        language: "nl",
                        mobile: currentUserData.data?.currentUser.employee.cellphone ? currentUserData.data?.currentUser.employee.cellphone : "",
                        phone: currentUserData.data?.currentUser.employee.phonenumber ? currentUserData.data?.currentUser.employee.phonenumber : "",
                        function: currentUserData.data?.currentUser.employee.function ? currentUserData.data?.currentUser.employee.function : "",
                    },
                    refetchQueries: [
                        QUERY_CURRENT_USER_SETTINGS, // DocumentNode object parsed with gql
                        "GetCurrentUserSettings", // Query name
                    ],
                });
                navigate(redirectUrl || "/dashboard");
            } else {
                navigate(redirectUrl || "/dashboard");
            }
        } catch (e) {
            console.error("Error signing in", e);
            setError(t("auth.error-login-check-credentials"));
            localStorage.removeItem("acces_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("initials");
        }
    };

    return (
        <div className="Login auth-form-wrapper">
            <div className="auth-form-container">
                <LoginHeader />
                <form onSubmit={onSubmit}>
                    <div style={{ marginBottom: 30 }}>
                        <TextField
                            type="email"
                            placeholder={t("auth.email")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            disabled={loading}
                            autoComplete="username"
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <TextField
                            type="password"
                            placeholder={t("auth.password")}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            variant="outlined"
                            disabled={loading}
                            autoComplete="current-password"
                        />
                        <div className="input-help">
                            <Link to={{ pathname: "/forgot-password" }} className="forgot-password">
                                {t("auth.forgot-password.title")}
                            </Link>
                        </div>
                    </div>

                    {/*
                     * If anything went wrong during the login procedure, error will be displayed here
                     */}
                    <div style={{ visibility: error ? "visible" : "hidden" }} className="auth-form-error">
                        {error || "ERROR_PLACEHOLDER"}
                    </div>

                    {/*
                     * bottom div of login form
                     * with link to request a demo and a logn button to submit login
                     */}
                    <div className="action-bar">
                        <div />
                        {/* <Link to="/request-demo" className="text-button">
                            {t("auth.no-account-yet")}
                        </Link> */}
                        <Button variant="contained" color="primary" type="submit" size="large" disabled={loading}>
                            {loading ? t("auth.loading") : t("auth.login-button-label")}
                        </Button>
                    </div>
                </form>
                <LanguageSelector />
            </div>
        </div>
    );
};

export default Login;

export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            token
        }
    }
`;

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            language
            employee {
                id
                function
                cellphone
                phonenumber
            }
        }
    }
`;
