import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { useQuery } from "@apollo/client";

import { TENDER_DEADLINE_REMINDER_NOTIFICATION } from "../../graphql/queryDefNotifications";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import TooltipOnNewLines from "../TooltipOnNewLines";
import moment from "moment";
import { GetTenderDeadlineNotificationData, GetTenderDeadlineNotificationDataVariables } from "../../__generated__/GetTenderDeadlineNotificationData";
import { getRelativeTimeInDays } from "../../utils/relativeTimeInDays";
import { useTranslation } from "react-i18next";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: string): void;
    keyIndex: number;
}

/**
 * Display a single notification row for the notification Dialog
 */
const TenderDeadlineReminderNotification: React.FC<Props> = ({ notification, onClick, keyIndex }) => {
    const { t } = useTranslation();
    // notification.data[0] -> tender id
    const { loading, data: tender } = useQuery<GetTenderDeadlineNotificationData, GetTenderDeadlineNotificationDataVariables>(
        TENDER_DEADLINE_REMINDER_NOTIFICATION,
        {
            skip: notification === null || notification.data === null,
            variables: {
                tender_id: notification !== null && notification.data !== null ? (notification.data[0] as string) : "",
            },
        }
    );
    const blue = "#173357";

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem
                key={keyIndex}
                onClick={() => onClick(tender?.tender?.id as string)}
                style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined }}
            >
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    return (
        <MenuItem
            key={keyIndex}
            onClick={() => onClick(tender?.tender?.id as string)}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: blue }}>
                    <DescriptionIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={`Herinnering deadline: ${tender?.tender?.namegiventocontract}` || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    // Show colored label and name of searchrule when available
                    <Typography
                        style={{
                            fontSize: 12,
                            // When the nofitication is unread, the date-part of the notification is bold and accent colored

                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {moment(tender?.tender?.deadline_inschrijving).format("LLL")},
                        {getRelativeTimeInDays(tender?.tender?.deadline_inschrijving, t).show && (
                            <> ({getRelativeTimeInDays(tender?.tender?.deadline_inschrijving, t).date})</>
                        )}
                    </Typography>
                }
            />
        </MenuItem>
    );
};

export default TenderDeadlineReminderNotification;
