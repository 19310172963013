import React, { useState } from "react";
import SearchUsers from "./SearchUsers";
import { Chip, Typography } from "@mui/material";
import { RemoveCircleOutline, Share, TaskAlt } from "@mui/icons-material";
import { UserInTeam } from "../UserAndTeams/UsersAndTeams";

interface Props {
    users: UserInTeam[];
    checked: number[];
    setChecked: React.Dispatch<React.SetStateAction<number[]>>;
    onToggle: (e: any, value: number) => void;
    type: "tender" | "opportunity";
    idsToDelete: number[];
}

const ShareSearchlineWithUser: React.FC<Props> = ({ users, checked, setChecked, onToggle, type, idsToDelete }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenMenu(false);
    };

    return (
        <React.Fragment>
            <SearchUsers
                anchorEl={anchorEl}
                handleCloseSub={handleCloseSub}
                shareOpened={openMenu}
                users={users}
                checked={checked}
                setChecked={setChecked}
            />
            <Typography
                sx={{
                    fontWeight: 500,
                    display: "inline-flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "4px 6px",
                    fontSize: "1rem",
                    marginLeft: "-4px",
                    borderRadius: "4px",
                    "&:hover": {
                        backgroundColor: "#ececec",
                    },
                }}
                onClick={handleClickSub}
                variant="h5"
            >
                <Share fontSize="small" style={{ marginRight: "4px", color: type === "tender" ? "#465c84" : "#508c79" }} /> Deel zoekregel
            </Typography>
            <Typography sx={{ margin: "4px 0px 12px 0px" }}>
                Deel de resultaten van jouw zoekregel met je collega&apos;s. Alleen jij kunt wijzingen doorvoeren voor deze zoekregel.
            </Typography>
            <div style={{ height: "25px" }}>
                {checked.map((id) => {
                    const user = users.find((user) => parseInt(user.id) === id) as UserInTeam;
                    return (
                        <Chip
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: "8px", borderRadius: "4px" }}
                            key={user.id}
                            onClick={(e) => onToggle(e, id)}
                            icon={<TaskAlt style={{ color: type === "tender" ? "#465c84" : "#508c79" }} />}
                            label={user.employee.name}
                        />
                    );
                })}
            </div>

            {/* Div contains users that have to be deleted from sharing */}
            {idsToDelete.length > 0 && <Typography sx={{ margin: "8px 0px 12px 0px" }}>Delen stoppen met:</Typography>}
            <div style={{ height: "25px" }}>
                {idsToDelete.map((id) => {
                    const user = users.find((user) => parseInt(user.id) === id) as UserInTeam;
                    return (
                        <Chip
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: "8px", borderRadius: "4px" }}
                            key={user.id}
                            onClick={(e) => onToggle(e, id)}
                            icon={<RemoveCircleOutline style={{ color: "#c0392b" }} />}
                            label={user.employee.name}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default ShareSearchlineWithUser;
