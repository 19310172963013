import React, { useState } from "react";
import truncate from "truncate";
import { Disable } from "react-disable";
import { useTranslation } from "react-i18next";
// import moment from "moment";
// Material UI icons
import { MailOutlined, Language } from "@mui/icons-material";

// Material UI components
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// import MenuItem from "@mui/material/MenuItem";
import { useMutation, useQuery } from "@apollo/client";
import { updateProfileSettings, updateProfileSettingsVariables } from "../../../__generated__/updateProfileSettings";
import { toast } from "react-toastify";
import { GetCurrentUserSettings, GetCurrentUserSettings_currentUser } from "../../../__generated__/GetCurrentUserSettings";
import MobileInput from "./personalInputFields/MobileInput";
import PhoneInput from "./personalInputFields/PhoneInput";
import FunctionInput from "./personalInputFields/FunctionInput";
import { withStyles } from "tss-react/mui";
import { QUERY_CURRENT_USER_SETTINGS } from "../../../graphql/queryDefCurrentUser";
import { UPDATEPERSONALSETTINGS } from "../../../graphql/mutationDefinitions";
// import { Select, FormControl } from "@mui/material";

/**
 * textfield in left widgetbox
 */
export const TextFieldProfile = withStyles((props: TextFieldProps) => <TextField {...props} variant={"outlined"} fullWidth size={"small"} />, {
    root: {
        marginBottom: "12px",
        display: "block",
    },
});

export const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));

// const languages = [
//     { id: "1", label: "Nederlands", code: "nl", language: "nl" },
//     { id: "2", label: "English", code: "en", language: "en-US" },
//     { id: "3", label: "Deutsch", code: "de", language: "de" },
//     { id: "4", label: "Français", code: "fr", language: "fr" },
//     { id: "5", label: "Español", code: "es", language: "es" },
// ];

const PersonalDetails: React.FC = () => {
    const {
        t,
        // i18n
    } = useTranslation();
    const [personalinfo, setPersonalInfo] = useState<GetCurrentUserSettings_currentUser>();
    // TODO 4.x -> add option to choose image as picture
    // const [openUpload, setOpenUpload] = useState(false);
    const [
        imageSource,
        // setImageSource
    ] = useState("");
    const [
        lang,
        // setLang
    ] = useState(localStorage.getItem("i18nextLng") || "nl");

    /**
     * Get current user settings
     */
    const { error: errorQuery, loading: loadQuery } = useQuery<GetCurrentUserSettings>(QUERY_CURRENT_USER_SETTINGS, {
        onCompleted: (data) => {
            if (data && data.currentUser) {
                setPersonalInfo(data.currentUser);
            }
        },
    });

    // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setLang(event.target.value as string);
    //     onSaveBtnClick();
    // };

    /**
     * loading query data
     */
    if (loadQuery) {
    }

    /**
     * error on query
     */
    if (errorQuery) {
    }

    /**
     * mutation to set/update personal settings
     * phone, mobile, function language
     */
    const [save] = useMutation<updateProfileSettings, updateProfileSettingsVariables>(UPDATEPERSONALSETTINGS);

    const onSaveBtnClick = async () => {
        try {
            await save({
                variables: {
                    mobile: personalinfo?.employee.cellphone ? personalinfo?.employee.cellphone : "",
                    phone: personalinfo?.employee.phonenumber ? personalinfo?.employee.phonenumber : "",
                    function: personalinfo?.employee.function ? personalinfo?.employee.function : "",
                    language: lang,
                },
                refetchQueries: [
                    QUERY_CURRENT_USER_SETTINGS, // DocumentNode object parsed with gql
                    "GetCurrentUserSettings", // Query name
                ],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    const name = personalinfo?.employee.givenname ? personalinfo?.employee.givenname.charAt(0) : "";
    const famname = personalinfo?.employee.familyname ? personalinfo?.employee.familyname.charAt(0) : "";
    const initials = name + famname;

    return (
        <Disable disabled={loadQuery}>
            <Paper
                square
                sx={(theme) => ({
                    padding: "20px",
                    position: "relative",
                    [theme.breakpoints.down("sm")]: {
                        marginBottom: "20px",
                        padding: "16px",
                        position: "relative",
                    },
                })}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        width: "100%",
                        height: "70px",
                        backgroundColor: "#707070",
                    }}
                />

                {/* Colored bar with option to choose an image as pf */}
                <Box mb={3} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Avatar
                        sx={{ width: "100px", height: "100px", fontSize: "2.5rem", border: "2px solid white" }}
                        alt={t("Profile picture")}
                        src={imageSource || undefined}
                    >
                        {!imageSource && `${initials}`}
                        {/* <Box sx={{        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100%",
        width: "100%",
        opacity: 0,
        transition: ".5s ease",
        backgroundColor: "#173357",
        "&:hover": {
            opacity: 0.8,
        }}}>
                            <div style={{ display: "flex", height: "100%", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip title={t("personal.choose-photo") as string} placement="top" arrow>
                                    <IconButton size="small" style={{ color: "#fff" }} onClick={() => setOpenUpload(true)}>
                                        <PhotoCamera />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("personal.delete-photo") as string} placement="top" arrow>
                                    <IconButton size="small" style={{ color: "#fff" }} onClick={() => setImageSource("")}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Box> */}
                    </Avatar>

                    {/* Name of person */}
                    <Typography style={{ marginTop: 12 }} variant="h2">
                        {personalinfo?.employee.givenname} {personalinfo?.employee.middlename} {personalinfo?.employee.familyname}
                    </Typography>

                    {/* <ProfileImageDialog
                        open={openUpload}
                        onClose={() => setOpenUpload(false)}
                        cropOptions={{
                            callback: (original, resultbase64, circle) => {
                                setImageSource(resultbase64);
                            },
                        }}
                    /> */}
                </Box>
                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: 16 }}>
                    {/* Grid with all fields */}
                    <div>
                        {/* Email */}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                            <MailOutlined sx={{ marginRight: "12px", color: "#707070" }} />
                            <Box
                                sx={{
                                    padding: "10.5px 14px 10.5px 14px",
                                    height: "37.63px",
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "250px",
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                <Tooltip title="email niet aanpasbaar" placement="right" arrow>
                                    <Typography>{truncate(personalinfo?.email !== undefined ? personalinfo.email : "", 40)}</Typography>
                                </Tooltip>
                            </Box>
                        </div>

                        {/* Function Work */}
                        <FunctionInput onSave={onSaveBtnClick} personalinfo={personalinfo} setPersonalInfo={setPersonalInfo} />
                        {/* Phone number */}
                        <PhoneInput onSave={onSaveBtnClick} personalinfo={personalinfo} setPersonalInfo={setPersonalInfo} />
                        {/* Cellphone */}
                        <MobileInput onSave={onSaveBtnClick} personalinfo={personalinfo} setPersonalInfo={setPersonalInfo} />

                        {/* Change language */}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", marginTop: 6 }}>
                            <Language sx={{ marginRight: "12px", color: "#707070" }} />

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", marginLeft: 16 }}>
                                {/* <FormControl style={{ marginLeft: 16 }}>
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            : null,
                                        }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={lang}
                                        onChange={handleChange}
                                        disableUnderline

                                    >
                                        {languages.map((m) => {
                                            return (
                                                <MenuItem
                                                    key={m.id}
                                                    value={m.code}
                                                    onClick={() => {
                                                        i18n.changeLanguage(m.code);
                                                        moment.locale(m.language);
                                                    }}
                                                >
                                                    {m.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl> */}
                                {t("language.nl")}
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </Disable>
    );
};

export default PersonalDetails;
