import React, { useState } from "react";
import LoginHeader from "../components/login/LoginHeader";
import { TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "./Login.scss";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import queryString from "query-string";
import { saveNewSetPasswordForNewUser, saveNewSetPasswordForNewUserVariables } from "../__generated__/saveNewSetPasswordForNewUser";
import { verifyEmail, verifyEmailVariables } from "../__generated__/verifyEmail";
import { GetCurrentUser } from "../__generated__/GetCurrentUser";
import { GET_CURRENT_USER, LOGIN } from "./Login";
import { Login as LoginType, LoginVariables } from "../__generated__/Login";
import { updateProfileSettings, updateProfileSettingsVariables } from "../__generated__/updateProfileSettings";
import { UPDATEPERSONALSETTINGS } from "../graphql/mutationDefinitions";
import { QUERY_CURRENT_USER_SETTINGS } from "../graphql/queryDefCurrentUser";

interface Props {
    a?: number;
}

// const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

const ResetPassword: React.FC<Props> = (props) => {
    const query = queryString.parse(window.location.search);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [secondPassword, setSecondPassword] = useState<string>("");
    const [success, setSuccess] = useState<string | null | undefined>("");
    const [initialRun, setInitialrun] = useState(true);

    const resetToken = query.resetToken;
    const expires = query.expires;
    const id = query.id;
    const hash = query.hash;
    const signature = query.signature;

    const [focused, setFocused] = useState(false);
    const [pwFocus, setPwFocus] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    /**
     * mutation to request a new password
     */
    const [passwordReset] = useMutation<saveNewSetPasswordForNewUser, saveNewSetPasswordForNewUserVariables>(SAVE_PASSWORD_NEW_USER);
    const [verifyMail] = useMutation<verifyEmail, verifyEmailVariables>(ACTIVE_NEW_USER);
    const [login] = useMutation<LoginType, LoginVariables>(LOGIN);
    const [getCurrentUser] = useLazyQuery<GetCurrentUser>(GET_CURRENT_USER);
    const [save] = useMutation<updateProfileSettings, updateProfileSettingsVariables>(UPDATEPERSONALSETTINGS);

    /**
     * Login user with new params
     */
    const loginUser = async (e: string, p: string) => {
        try {
            const authResponse = await login({ variables: { email: e, password: p } });

            if (!authResponse.data?.login.token) {
                throw new Error("No token was provided");
            }

            localStorage.setItem("access_token", authResponse.data?.login.token);

            const currentUserData = await getCurrentUser();
            localStorage.setItem("user_id", currentUserData.data?.currentUser.id || "undefined");

            localStorage.setItem("i18nextLng", "nl");
            // Check if the user has a language set, if not, set it to Dutch (nl)
            if (currentUserData.data?.currentUser.language !== "nl") {
                save({
                    variables: {
                        language: "nl",
                        mobile: currentUserData.data?.currentUser.employee.cellphone ? currentUserData.data?.currentUser.employee.cellphone : "",
                        phone: currentUserData.data?.currentUser.employee.phonenumber ? currentUserData.data?.currentUser.employee.phonenumber : "",
                        function: currentUserData.data?.currentUser.employee.function ? currentUserData.data?.currentUser.employee.function : "",
                    },
                    refetchQueries: [
                        QUERY_CURRENT_USER_SETTINGS, // DocumentNode object parsed with gql
                        "GetCurrentUserSettings", // Query name
                    ],
                });
                navigate("/dashboard");
            } else {
                navigate("/login");
            }
        } catch (e) {
            console.error("Error signing in", e);
            setError(t("auth.error-login-check-credentials"));
            localStorage.removeItem("acces_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("initials");
        }
    };

    /**
     * @param e on click on save button
     * @returns sets password to inputdata
     * on succes => login user with new password and email
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (loading) return;
        if (!email || !password || !secondPassword) {
            setError(t("auth.error-login-no-input"));
            return;
        }

        try {
            setLoading(true);
            passwordReset({
                variables: {
                    input: {
                        email: email,
                        password: password,
                        password_confirmation: secondPassword,
                        token: resetToken as string,
                    },
                },
                onCompleted: () => {
                    setSuccess("done");

                    /**
                     * Login user
                     */
                    loginUser(email, password);
                    setSuccess("");
                },
            });
        } catch (e) {
            console.error("Error signing in", e);
            setError(t("auth.error-login-check-credentials"));
        }
    };

    /**
     * Verify account based on params in url.
     * all params have to be correct to verify user
     */
    const verifyAccount = async () => {
        try {
            verifyMail({
                variables: {
                    verify_input: {
                        expires: parseInt(expires as string),
                        hash: hash as string,
                        id: id as string,
                        signature: signature as string,
                    },
                },
            });
            setSuccess("success");
            setInitialrun(false);
        } catch {
            console.error("oepsie");
        }
    };

    /**
     * @param email input of user
     * @returns returns boolean based on userinput
     */
    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    /**
     * Check if one of the query values is not a string.
     * Navigate to login
     */
    if (
        typeof expires !== "string" ||
        typeof hash !== "string" ||
        typeof id !== "string" ||
        typeof signature !== "string" ||
        typeof resetToken !== "string"
    ) {
        navigate("/login");
    }

    if (success === "" && initialRun === true) {
        verifyAccount();
    }

    return (
        <div className="Login auth-form-wrapper">
            <div className="auth-form-container">
                <LoginHeader />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "0px", marginTop: "-40px" }}>
                    <Typography variant="h2">Welkom</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px", marginTop: "8px" }}>
                    <Typography variant="body1">Maak hieronder je wachtwoord aan</Typography>
                </div>
                {success === "success" ? (
                    <form onSubmit={onSubmit}>
                        <div style={{ marginBottom: 20 }}>
                            <TextField
                                onFocus={onFocus}
                                onBlur={onBlur}
                                type="email"
                                placeholder={t("auth.email")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoFocus
                                fullWidth
                                variant="outlined"
                                disabled={loading}
                                autoComplete="username"
                                helperText={!isValidEmail(email) && !focused ? "Vul een geldig e-mailadres in" : ""}
                            />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <TextField
                                onFocus={() => setPwFocus(true)}
                                onBlur={() => setPwFocus(false)}
                                helperText={
                                    password.length > 0 && password.length < 6 && pwFocus === false
                                        ? "Wachtwoord moet minimaal 6 tekens bevatten"
                                        : ""
                                }
                                error={password.length > 0 && password.length < 6 && pwFocus === false}
                                disabled={isValidEmail(email) ? false : true || loading}
                                style={{ marginBottom: 20 }}
                                type="password"
                                placeholder={t("auth.password")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                variant="outlined"
                                autoComplete="current-password"
                            />

                            <TextField
                                type="password"
                                error={secondPassword.length >= 6 && password !== secondPassword}
                                helperText={secondPassword.length >= 6 && password !== secondPassword ? t("auth.error-passwords-not-equal") : ""}
                                disabled={password.length < 6 || !isValidEmail(email) || loading}
                                placeholder={t("auth.password-repeat")}
                                value={secondPassword}
                                onChange={(e) => setSecondPassword(e.target.value)}
                                fullWidth
                                variant="outlined"
                                autoComplete="current-password"
                            />
                        </div>

                        {/*
                         * If anything went wrong during the login procedure, error will be displayed here
                         */}
                        <div
                            style={{
                                visibility: error ? "visible" : "hidden",
                                color: "#c0392b",
                                marginBottom: "0px",
                                fontSize: "16px",
                                fontWeight: 500,
                            }}
                            className="auth-form-error"
                        >
                            {error || "ERROR_PLACEHOLDER"}
                        </div>

                        {/*
                         * bottom div of login form
                         * with link to request a demo and a logn button to submit login
                         */}
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                disabled={
                                    loading || !isValidEmail(email) || password.length < 6 || secondPassword.length < 6 || password !== secondPassword
                                }
                            >
                                {loading ? t("auth.loading") : t("auth.save")}
                            </Button>
                        </div>
                    </form>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;

//? set chosen password for verified user
export const SAVE_PASSWORD_NEW_USER = gql`
    mutation saveNewSetPasswordForNewUser($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            message
            status
        }
    }
`;

//? verify user
export const ACTIVE_NEW_USER = gql`
    mutation verifyEmail($verify_input: VerifyEmailInput!) {
        verifyEmail(input: $verify_input) {
            status
        }
    }
`;
