import React, { useState, useEffect } from "react";
import { Button, Drawer, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFaq } from "../contextProviders/FaqOpenerContext";
import { useQuery, useMutation } from "@apollo/client";
import { GetSideBarArticlesOfModule } from "../../__generated__/GetSideBarArticlesOfModule";
import { SetKnowledgeBaseRead, SetKnowledgeBaseReadVariables } from "../../__generated__/SetKnowledgeBaseRead";
import { useLocation } from "react-router-dom";
import SideBarContent from "./SideBarContent";
import { useTranslation } from "react-i18next";
import { QUERY_SIDEBAR_ARTICLES_OF_CURRENT_MODULE } from "../../graphql/queryDefFaq";
import { MARK_ART_AS_READ } from "../../graphql/mutationDefinitions";
interface Props {
    title: string;
}

const ModuleInformation: React.FC<Props> = ({ title, children }) => {
    const { setOpenFaq } = useFaq();
    const [openDrawer, setOpenDrawer] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();
    const link = location.pathname.split("/");
    const url = link[1].replace(/[^a-zA-Z0-9 ]/g, "");
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { data } = useQuery<GetSideBarArticlesOfModule>(QUERY_SIDEBAR_ARTICLES_OF_CURRENT_MODULE, { variables: { module: url } });
    const [activeId, setActiveId] = useState<number>(0);

    const [markRead, { error: errorMutation }] = useMutation<SetKnowledgeBaseRead, SetKnowledgeBaseReadVariables>(MARK_ART_AS_READ);

    /**
     * Function to mark article as read
     * function fired when user clicks "niet tonen"
     * In future add button to specific articles(in SideBarContent component)
     */
    const mark_as_read = async () => {
        if (errorMutation || activeId === undefined) {
        }
        try {
            await markRead({
                variables: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    art_id: activeId!,
                },
                refetchQueries: [QUERY_SIDEBAR_ARTICLES_OF_CURRENT_MODULE, "GetSideBarArticlesOfModule"],
            });
        } catch (e) {}
    };

    const checkStorage = sessionStorage.getItem(`hideSideDrawer${title.trim()}`)
        ? JSON.parse(sessionStorage.getItem(`hideSideDrawer${title.trim()}`) || "false")
        : false;

    useEffect(() => {
        // check if user hides drawer in current session
        if (data !== undefined && data.getSidebarArticle.length > 0 && !checkStorage) {
            return setOpenDrawer(true);
        }
        return setOpenDrawer(false);
    }, [data, checkStorage]);

    const closeDrawer = () => {
        setOpenDrawer(false);
    };

    const openFaq = () => {
        closeDrawer();
        setOpenFaq(true);
    };

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    width: onlyMobile ? "80vw" : "33vw",
                },
            }}
            anchor="right"
            open={openDrawer}
        >
            <div style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                {/* title of drawer */}
                <Typography
                    variant="h1"
                    sx={{
                        color: theme.palette.primary.main,
                        margin: onlyMobile ? "16px" : "48px",
                        marginTop: onlyMobile ? "16px" : "96px",
                    }}
                >
                    {title}
                </Typography>
                <div style={{ overflow: "auto" }}>
                    {data !== undefined && <SideBarContent content={data.getSidebarArticle} setActiveId={setActiveId} mark_as_read={mark_as_read} />}
                    {/* Specific rendered data for each module if its available */}
                    {children}
                </div>
                {/* Bottom div with buttons to read more info/close drawer */}
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Link underline="none" component="button" onClick={openFaq}>
                        {t("Read_more_about")} {title.toLowerCase()}
                    </Link>
                    <div style={{ display: "flex", justifyContent: "center", margin: onlyMobile ? "16px" : "24px" }}>
                        <Button
                            onClick={() => {
                                closeDrawer();
                                sessionStorage.setItem(`hideSideDrawer${title.trim()}`, JSON.stringify("true"));
                            }}
                            sx={{ color: "#000000" }}
                            variant="text"
                            style={{ margin: 8 }}
                        >
                            {t("Show_later")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: "8px", color: "#ffffff" }}
                            onClick={() => {
                                closeDrawer();
                                mark_as_read();
                            }}
                        >
                            {t("Dont_show_again")}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default ModuleInformation;
