import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { ApolloProvider } from "@apollo/client";

import client from "../graphql/gqlClient";
import moment from "moment";

import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/nl";

import "react-chat-elements/dist/main.css";
import { MatomoProvider, useMatomo } from "@datapunt/matomo-tracker-react";
import createMatomoInstance from "../utils/createMatomoInstance";

//material ui
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { materialTheme } from "../assets/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// Routes
import NotFound404 from "../routes/NotFound404";
import Login from "../routes/Login";
import ForgotPassword from "../routes/ForgotPassword";
import DevInfo from "../routes/DevInfo";
import ResetPassword from "../routes/ResetPassword";

// Auth routes
import Profile from "../routes/Profile";
import Dashboard from "../routes/Dashboard";
import TenderModule from "../routes/tenders/TenderModule";
import OrganizationsModule from "../routes/organizations/OrganizationsModule";
import ContractingAuthorityModule from "../routes/contractingAuthoritiesModule/ContractingAuthorityModule";
import OpportunityModule from "../routes/opportunities/OpportunityModule";
import Calendar from "../routes/calendar/Calendar";
import ProtectedRoute from "./ProtectedRoute";
import RequestDemoContainer from "../routes/supportOrDemoRequest/RequestDemoContainer";
import { OppCustomOptionsContext } from "./contextProviders/OppCustomOptionProvider";
import { TenderCustomOptionsContext } from "./contextProviders/TenderCustomOptionProvider";
import { FaqContext } from "./contextProviders/FaqOpenerContext";
// import secureLocalStorage from "react-secure-storage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import ActivateUser from "../routes/ActivateUser";
import Projects from "../routes/projects/Projects";
import { RowsProvider } from "../routes/Dashboard/editabledashboard/RowsContext";
import TendenzContainer from "../routes/tendenz/TendenzContainer";
import UserIdContext from "./contextProviders/UserIdContext";
import { MyProvider } from "./layout/ScrollableContentContextProvider";
// import TaskModule from "../routes/tasks/TaskModule";
// import PollContainer from "../routes/tendenz/poll/PollContainer";

// Get chosen language and use as time format
const language =
    localStorage.getItem("i18nextLng") === "en-US"
        ? "en"
        : localStorage.getItem("i18nextLng") === "nl-NL"
        ? "nl"
        : localStorage.getItem("i18nextLng");
// Check if language is set and use it as time format
// if language = null -> nl is fallback
moment.locale(language !== null ? language : "nl");

// Create a matomo instance
const matomoInstance = createMatomoInstance();

// Initialize react-modal
Modal.setAppElement("#root");

// Set new thresholds for relative time
moment.relativeTimeThreshold("m", 59); // show hour after 59 minutes
moment.relativeTimeThreshold("h", 23); // show day after 23 hours
moment.relativeTimeThreshold("d", 90); // show months when its 61 days ago
moment.relativeTimeThreshold("M", 60); // show year after 60 months

const App = () => {
    const { enableLinkTracking } = useMatomo();
    const [customOptionsWorklist, setCustomOptionsWorklist] = useState(
        sessionStorage.getItem("cow") ? JSON.parse(sessionStorage.getItem("cow") || "[]") : []
    );
    const [customOptionsReviews, setCustomOptionsReviews] = useState(
        sessionStorage.getItem("cor") ? JSON.parse(sessionStorage.getItem("cor") || "[]") : []
    );
    const [customOptionsAll, setCustomOptionsAll] = useState(sessionStorage.getItem("coa") ? JSON.parse(sessionStorage.getItem("coa") || "[]") : []);

    const [customOppOptionsWorklist, setCustomOppOptionsWorklist] = useState(
        sessionStorage.getItem("coow") ? JSON.parse(sessionStorage.getItem("coow") || "[]") : []
    );
    const [customOppOptionsReviews, setCustomOppOptionsReviews] = useState(
        sessionStorage.getItem("coor") ? JSON.parse(sessionStorage.getItem("coor") || "[]") : []
    );
    const [customOppOptionsAll, setCustomOppOptionsAll] = useState(
        sessionStorage.getItem("cooa") ? JSON.parse(sessionStorage.getItem("cooa") || "[]") : []
    );
    const [openFaq, setOpenFaq] = useState(false);
    const [openChat, setOpenChat] = useState(false);
    const [view, setView] = useState("start");
    const [xAxis, setX] = useState(0);
    const [yAxis, setY] = useState(0);

    enableLinkTracking();
    useEffect(() => {
        window.dispatchEvent(new CustomEvent("resize"));
    }, []);

    // const handleHardReload = async (url: string) => {
    //     await fetch(url, {
    //         headers: {
    //             Pragma: "no-cache",
    //             Expires: "-1",
    //             "Cache-Control": "no-cache",
    //         },
    //     });
    //     window.location.href = url;
    //     // This is to ensure reload with url's having '#'
    //     window.location.reload();
    // };

    useEffect(() => {
        const version = localStorage.getItem("version");
        // if (window.location.pathname !== "/login") {  .
        if (version !== "4.2.1.3") {
            // check if localstorage key "version" exist
            // if not, create it and set it to 4.2.0.0 ..
            localStorage.setItem("version", "4.2.1.3");

            /**
             * Reset secure columns
             */
            // secureLocalStorage.removeItem("cumsc"); //mp columns starred
            // secureLocalStorage.removeItem("cumc"); //mp columns all
            // secureLocalStorage.removeItem("cuadsc"); //ca columns starred
            // secureLocalStorage.removeItem("cuadc"); //ca columns all
            // secureLocalStorage.removeItem("cuocw"); //opp columns worklist
            // secureLocalStorage.removeItem("cuocr"); //ca columns reviewed
            // secureLocalStorage.removeItem("cuoc"); //ca columns all

            // toast.info(
            //     <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            //         <Typography style={{ marginRight: 16 }}>Er is een update!</Typography>
            //         <Button onClick={() => handleHardReload(window.location.href)} size="small" variant="text">
            //             Ververs
            //         </Button>
            //     </div>,
            //     {
            //         position: "bottom-right",
            //         autoClose: false,
            //         hideProgressBar: true,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "light",
            //     }
            // );
        }
        // }
    }, []);

    return (
        <Suspense fallback="">
            <StyledEngineProvider>
                {/* Matomo provider, to allow for advance tracking */}
                <MatomoProvider value={matomoInstance}>
                    {/* Apollo GraphQL client, cache control and local state */}
                    <ApolloProvider client={client}>
                        {/* Material Design theme */}
                        <ThemeProvider theme={materialTheme}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                {/* Imperative API for material design snackbar notifications */}
                                <UserIdContext>
                                    <TenderCustomOptionsContext.Provider
                                        value={{
                                            setCustomOptionsWorklist,
                                            setCustomOptionsReviews,
                                            setCustomOptionsAll,
                                            customOptionsWorklist,
                                            customOptionsAll,
                                            customOptionsReviews,
                                        }}
                                    >
                                        <OppCustomOptionsContext.Provider
                                            value={{
                                                setCustomOppOptionsWorklist,
                                                setCustomOppOptionsReviews,
                                                setCustomOppOptionsAll,
                                                customOppOptionsWorklist,
                                                customOppOptionsAll,
                                                customOppOptionsReviews,
                                            }}
                                        >
                                            <DndProvider backend={HTML5Backend}>
                                                <FaqContext.Provider
                                                    value={{ openFaq, setOpenFaq, openChat, setOpenChat, view, setView, xAxis, setX, yAxis, setY }}
                                                >
                                                    <MyProvider>
                                                        <Router>
                                                            <Routes>
                                                                {/* Authenticated routes */}
                                                                <Route
                                                                    path="/"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <Navigate replace to="/dashboard" />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/dashboard"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <RowsProvider>
                                                                                <Dashboard />
                                                                            </RowsProvider>
                                                                        </ProtectedRoute>
                                                                    }
                                                                />
                                                                {/*** Protected project route /projects module:Projects ***/}
                                                                <Route
                                                                    path="/projects/*"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <Projects />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/tenders/*"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <TenderModule />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/organizations/*"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <OrganizationsModule />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/contracting-authorities/*"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <ContractingAuthorityModule />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/profile/*"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <Profile />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/opportunities/*"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <OpportunityModule />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/calendar/*"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <Calendar />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/request-support"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <RequestDemoContainer />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/tendenz"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <TendenzContainer />
                                                                        </ProtectedRoute>
                                                                    }
                                                                />
                                                                {/* 
                                                        <Route
                                                            path="/poll/*"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <PollContainer />
                                                                </ProtectedRoute>
                                                            }
                                                        /> */}

                                                                {/* <Route
                                                            path="/asdasdwre13//zzz???"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <TaskModule />
                                                                </ProtectedRoute>
                                                            }
                                                        /> */}

                                                                {/* Unauthenticated routes */}
                                                                <Route path="/login" element={<Login />} />
                                                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                                                <Route path="/reset-password" element={<ResetPassword />} />
                                                                <Route path="/activation" element={<ActivateUser />} />
                                                                <Route path="/devinfo" element={<DevInfo />} />

                                                                {/* 404 Page */}
                                                                <Route path="*" element={<NotFound404 url={"/"} />} />
                                                            </Routes>
                                                        </Router>
                                                    </MyProvider>
                                                </FaqContext.Provider>
                                            </DndProvider>
                                        </OppCustomOptionsContext.Provider>
                                    </TenderCustomOptionsContext.Provider>
                                </UserIdContext>
                            </LocalizationProvider>
                            <ToastContainer position="bottom-right" />
                        </ThemeProvider>
                    </ApolloProvider>
                </MatomoProvider>
            </StyledEngineProvider>
        </Suspense>
    );
};

export default App;
