import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { GetUserSearchRules_currentUser_opportunitySearches } from "../../../../__generated__/GetUserSearchRules";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";

interface Props {
    searchline: GetUserSearchRules_currentUser_opportunitySearches;
}

const OppSearchLineSummarySentence: React.FC<Props> = ({ searchline }) => {
    // const filterValues = JSON.parse(searchline.filters);
    const filterValues = searchline.filters_with_labels;

    const { t } = useTranslation();
    return (
        <React.Fragment>
            {/* If searchline contains filter country/countries => show typography component with data */}
            <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }}>
                Ik zoek kansen
            </Typography>

            {/* If searchline contains query => show typography component with query data */}
            {searchline.query && (
                <>
                    met{" "}
                    <Tooltip
                        enterNextDelay={100}
                        placement="top"
                        PopperProps={{
                            style: { marginBottom: "-10px" },
                        }}
                        title={searchline.query.split(" OR ").map((i) => {
                            if (i.includes("~" || "*")) return <div key={i}>{i}</div>;
                            return <div key={i}>{i.replace(/["']/g, "")}</div>;
                        })}
                    >
                        <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px", cursor: "pointer" }}>
                            <strong> zoekwoorden </strong>
                        </Typography>
                    </Tooltip>
                </>
            )}

            {filterValues !== undefined && filterValues !== null && filterValues.filter((f: any) => f?.key !== "country").length > 0 && (
                <>
                    <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }}>
                        {searchline.query !== null && searchline.query.length > 0 ? "en de" : "met de"}{" "}
                        {filterValues?.length > 1 ? "filters" : "filter"}
                        {filterValues
                            .filter((fltr: any) => fltr?.key !== "country")
                            .map((f: any, i: number) => {
                                // remove country from list of filters to const to use as params for " en " and ", "
                                const filterWithoutCountry = filterValues.filter((fltr: any) => fltr?.key !== "country");
                                return (
                                    <>
                                        <Tooltip
                                            key={i}
                                            enterNextDelay={100}
                                            placement="top"
                                            PopperProps={{
                                                style: { marginBottom: -10 },
                                            }}
                                            title={
                                                <>
                                                    {f?.labels?.map((l: string) => {
                                                        return <div key={l}>{l}</div>;
                                                    })}
                                                </>
                                            }
                                        >
                                            <Typography style={{ display: "inline-flex", cursor: "pointer" }}>
                                                <strong style={{ marginLeft: 4, marginRight: filterWithoutCountry?.length - 2 === i ? 4 : 0 }}>
                                                    {t(`filter.${f?.key}`).toLowerCase()}
                                                </strong>
                                            </Typography>
                                        </Tooltip>
                                        {filterWithoutCountry.length - 2 === i ? "en" : i !== filterWithoutCountry.length - 1 ? "," : ""}
                                    </>
                                );
                            })}
                    </Typography>
                </>
            )}
        </React.Fragment>
    );
};

export default OppSearchLineSummarySentence;
