import React, { useEffect, useMemo, useState } from "react";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import { useLocation } from "react-router-dom";
import { GetUserSearchRules } from "../../__generated__/GetUserSearchRules";
import { useLazyQuery, useQuery } from "@apollo/client";
import { IntRange } from "./IntRangeFilterItem";
import { QUERY_CA_FILTER_DATA, QUERY_OPP_YEARS_OF_USER } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetUserSavedOppYears } from "../../__generated__/GetUserSavedOppYears";
import ValueFilter from "../TopFilterBar/ValueFilter";
import PeriodFilter from "../TopFilterBar/PeriodFilter";
import FilterDropdown from "../TopFilterBar/FilterDropdown";
import FilterDropdownStringVersion from "../TopFilterBar/FilterDropdownStringVersion";
import {
    GetContractingAuthorityFilterData,
    GetContractingAuthorityFilterDataVariables,
    GetContractingAuthorityFilterData_filterContractingAuthorities_results,
} from "../../__generated__/GetContractingAuthorityFilterData";
import { ContractingAuthoritiesFilterSource, SortOrder } from "../../__generated__/globalTypes";
import FilterDropdownCA from "../TopFilterBar/FilterDropdownCA";
import { useCustomOptions } from "../contextProviders/TenderCustomOptionProvider";
import { useCustomOppOptions } from "../contextProviders/OppCustomOptionProvider";
import { customOptionsRegions } from "../../routes/profile/Dialog/opportunityWizard/ResultModalOpp";
import { QUERY_SEARCHRULES } from "../../graphql/queryDefCurrentUser";

export type DateRange = { from: Moment | null; to: Moment | null };
interface Props {
    lastUpdate?: DateRange;
    setLastUpdate?(lastUpdate: DateRange): void;
    deadline?: DateRange;
    setDeadline?(deadline: DateRange): void;
    firstPublication?: DateRange;
    setFirstPublication?(firstPublication: DateRange): void;
    endDate?: DateRange;
    setEndDate?(endDate: DateRange): void;
    endDateInc?: DateRange;
    setEndDateInc?(endDateInc: DateRange): void;
    awardDate?: DateRange;
    setAwardDate?(awardDate: DateRange): void;
    startDateContract?: DateRange;
    setStartDateContract?(startDateContract: DateRange): void;
    nextEndDateContract?: DateRange;
    setNextEndDateContract?(nextEndDateContract: DateRange): void;
    updatedAt?: DateRange;
    setUpdatedAt?(updatedAt: DateRange): void;
    ratedDate?: DateRange;
    setRatedDate?(ratedDate: DateRange): void;
    filterData: any[];
    resetPage: () => void;
    onChange(searchInput: any): void;
    startSearch(): void;
    searchInput: any;
    setOpen?: (open: boolean) => void;
    // publication date opportunity
    publishedOppDate?: DateRange;
    setPublishedOppDate?(ratedDate: DateRange): void;
    // personal opportunity year
    personalYear?: DateRange;
    setPersonalYear?(personalYear: DateRange): void;
    // document year_from filter opportunity
    yearFrom?: DateRange;
    setYearFrom?(yearFrom: DateRange): void;
    // last reviewdate opportunity
    documentLastReviewed?: DateRange;
    setDocumentLastReviewed?(documentLastReviewed: DateRange): void;
    IntRangeState?: IntRange;
    setIntRangeState?(IntRangeState: IntRange): void;
    setCaSearchQuery?(caSearch: string): void;
    caSearchQuery?: string;
    resetCustomFilter?: boolean;
    setResetCustomFilter?(bool: boolean): void;
    pageview: "tenders" | "opportunities" | "tenderWizard" | "opportunityWizard" | "organizations";
}

export const arrayOfItems = [
    { id: -1, label: "Niet beoordeeld", amountOfResults: "0" },
    { id: 0, label: "Niet interessant", amountOfResults: "0" },
    { id: 1, label: "Interessant", amountOfResults: "0" },
    { id: 2, label: "Gearchiveerd", amountOfResults: "0" },
];

const TenderFilterBar: React.FC<Props> = ({
    pageview,
    filterData,
    resetPage,
    onChange,
    searchInput,
    startSearch,
    deadline,
    setDeadline,
    firstPublication,
    setFirstPublication,
    endDate,
    setEndDate,
    endDateInc,
    setEndDateInc,
    awardDate,
    setAwardDate,
    startDateContract,
    setStartDateContract,
    nextEndDateContract,
    setNextEndDateContract,
    updatedAt,
    setUpdatedAt,
    ratedDate,
    setRatedDate,
    setOpen,
    publishedOppDate,
    setPublishedOppDate,
    personalYear,
    setPersonalYear,
    yearFrom,
    setYearFrom,
    documentLastReviewed,
    setDocumentLastReviewed,
    IntRangeState,
    setIntRangeState,
    setCaSearchQuery,
    caSearchQuery,
    resetCustomFilter,
    setResetCustomFilter,
    lastUpdate,
    setLastUpdate,
}) => {
    const { t } = useTranslation();

    const filterCopy = [...filterData];

    const { pathname } = useLocation();

    // Fetch searchrules (tenders & opportunities)
    const { data, loading: loadSearches } = useQuery<GetUserSearchRules>(QUERY_SEARCHRULES);
    // Fetch searchrules (tenders & opportunities)

    const [caFilterSet, setCaFilterSet] = useState<GetContractingAuthorityFilterData_filterContractingAuthorities_results | null>();
    const filterSet = useMemo(() => {
        const checkingPath =
            pageview === "opportunities"
                ? { opportunityFileCount: 0 }
                : pageview === "opportunityWizard"
                ? { opportunityFileCount: 0 }
                : { tenderCount: 0 };
        return checkingPath;
    }, [pageview]);

    //custom options for ca filter
    const { setCustomOptionsWorklist, setCustomOptionsReviews, setCustomOptionsAll, customOptionsWorklist, customOptionsAll, customOptionsReviews } =
        useCustomOptions();

    const {
        setCustomOppOptionsWorklist,
        setCustomOppOptionsReviews,
        setCustomOppOptionsAll,
        customOppOptionsWorklist,
        customOppOptionsAll,
        customOppOptionsReviews,
    } = useCustomOppOptions();

    /**
     * reset handler to remove session storage key with data
     */
    const handleResetCustomFilter = () => {
        if (pathname === "/tenders/worklist") {
            setCustomOptionsWorklist([]);
            sessionStorage.removeItem("cow");
        }
        if (pathname === "/tenders/reviews") {
            setCustomOptionsReviews([]);
            sessionStorage.removeItem("cor");
        }
        if (pathname === "/tenders") {
            setCustomOptionsAll([]);
            sessionStorage.removeItem("coa");
        }
        if (pathname === "/opportunities/worklist") {
            setCustomOppOptionsWorklist([]);
            sessionStorage.removeItem("coow");
        }
        if (pathname === "/opportunities/reviews") {
            setCustomOppOptionsReviews([]);
            sessionStorage.removeItem("coor");
        }
        if (pathname === "/opportunities") {
            setCustomOppOptionsAll([]);
            sessionStorage.removeItem("cooa");
        }
    };

    // Variables for the GQL query
    const variables: GetContractingAuthorityFilterDataVariables = {
        page: 1,
        count: 1000,
        orderField: "last_update_date_timestamp",
        order: SortOrder.DESC,
        search: {
            source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
            query: "",
            filters: filterSet,
        },
    };

    const [searchCA, { loading: loadCaFilterset }] = useLazyQuery<GetContractingAuthorityFilterData, GetContractingAuthorityFilterDataVariables>(
        QUERY_CA_FILTER_DATA,
        {
            variables: variables,
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterContractingAuthorities) {
                    setCaFilterSet(data.filterContractingAuthorities.results);
                }
            },
        }
    );

    // Fetch a list of all years current user has used in his created opportunities
    const { data: yearCopy, loading: loadingYears } = useQuery<GetUserSavedOppYears>(QUERY_OPP_YEARS_OF_USER);
    const oppYears =
        yearCopy !== undefined && yearCopy.currentUser.savedOpportunityYears !== null ? [...yearCopy.currentUser.savedOpportunityYears] : [];

    // Filters to hide in GUI for all modules/tabs
    const remove_filter_overall = filterCopy
        ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
        // Remove scopeMin filter
        .filter((filter: any) => filter.title !== "scopeMin")
        // Remove scopeMax filter
        .filter((filter: any) => filter.title !== "scopeMax")
        // Remove tenderers filter (in all tenders page)
        .filter((filter: any) => filter.title !== "contractors")
        // Remove source filter
        .filter((filter: any) => filter.title !== "platform")
        // Remove agenda
        .filter((filter: any) => filter.title !== "agenda")
        // Remove type_procedure
        .filter((filter: any) => filter.title !== "type_procedure")
        .filter((filter: any) => filter.title !== "last_update_type");

    const array_to_show =
        pathname === "/tenders"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "searches")
                  .filter((filter: any) => filter.title !== "startdate_contract")
                  .filter((filter: any) => filter.title !== "next_enddate_contract")
                  .filter((filter: any) => filter.title !== "enddate_contract_quarters")
                  .filter((filter: any) => filter.title !== "rated_date")
                  .filter((filter: any) => filter.title !== "reviews")
                  .filter((filter: any) => filter.title !== "enddate_contract_incl_options")
                  .filter((filter: any) => filter.title !== "enddate_contract")
            : pathname === "/tenders/worklist"
            ? remove_filter_overall.filter((filter: any) => filter.title !== "rated_date").filter((filter: any) => filter.title !== "reviews")
            : pathname === "/opportunities"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "searches")
                  .filter((filter: any) => filter.title !== "contracting_authority_state")
                  .filter((filter: any) => filter.title !== "document_unreviewed_page_count")
                  .filter((filter: any) => filter.title !== "personal_value")
                  .filter((filter: any) => filter.title !== "personal_year")
                  .filter((filter: any) => filter.title !== "document_last_reviewed")
            : pathname === "/opportunities/reviews"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "contracting_authority_state")
                  .filter((filter: any) => filter.title !== "document_unreviewed_page_count")
            : pathname === "/opportunities/worklist"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "contracting_authority_state")
                  .filter((filter: any) => filter.title !== "document_unreviewed_page_count")
                  .filter((filter: any) => filter.title !== "personal_value")
                  .filter((filter: any) => filter.title !== "personal_year")
            : remove_filter_overall;

    useEffect(() => {
        if (caSearchQuery === undefined || (caSearchQuery !== undefined && caSearchQuery.length === 0)) {
            setCaFilterSet(undefined);
        }
        if (caSearchQuery !== undefined && caSearchQuery.length > 2) {
            searchCA({
                variables: {
                    page: 1,
                    count: 1000,
                    orderField: "last_update_date_timestamp",
                    order: SortOrder.DESC,
                    search: {
                        source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
                        query: `"${caSearchQuery}"`,
                        filters: filterSet,
                    },
                },
            });
        }
    }, [searchCA, caSearchQuery, filterSet]);

    /**
     * Check if sessionstorage is {}
     * If its empty set to interessant
     */
    useEffect(() => {
        if (sessionStorage.getItem("rtf") === `{}`) {
            sessionStorage.setItem("rtf", JSON.stringify({ reviews: [1] }));
            onChange(update(searchInput, { reviews: { $set: [1] } }));
        }
    });

    return (
        <>
            {/*
             * *******
             * TOPFILTERS
             * Dynamic filters based on active filters and results
             * See "filterdrawer.tsx" to create dynamic filters
             * ******
             * */}
            {array_to_show
                ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
                .filter((filter) => filter.topFilter === true)
                .map((filter) => {
                    // change test to filter.title
                    const test = filter.title;
                    const filterItems = searchInput?.[test as keyof any] as any[];
                    /**
                     * Opportunity => contracting authority filter
                     */
                    if (test === "contracting_authorities") {
                        /**
                         * Search in ca query instead of filter data. (filterdata shows first 100 only)
                         */
                        const optionsToShow =
                            caSearchQuery !== undefined &&
                            caSearchQuery.length > 0 &&
                            caFilterSet?.paginatorInfo.total !== undefined &&
                            caFilterSet?.paginatorInfo.total > 0
                                ? caFilterSet?.data.map(
                                      (ca) =>
                                          ({
                                              key: ca.name,
                                              label: ca.name,
                                              id: parseInt(ca.id),
                                          } || [])
                                  )
                                : filter.values
                                      .filter((f: any) => f.amountOfResults > 0)
                                      .sort(function (a: any, b: any) {
                                          return parseInt(b.key) - parseInt(a.key);
                                      })
                                      .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                          key: fltr.label,
                                          label: `${fltr.label} (${fltr.amountOfResults})`,
                                          id: parseInt(fltr.identifier),
                                      }));

                        const currentPageOptions =
                            pathname === "/opportunities"
                                ? customOppOptionsAll
                                : pathname === "/opportunities/worklist"
                                ? customOppOptionsWorklist
                                : customOppOptionsReviews;

                        const currentPageOptionsSetter =
                            pathname === "/opportunities"
                                ? setCustomOppOptionsAll
                                : pathname === "/opportunities/worklist"
                                ? setCustomOppOptionsWorklist
                                : setCustomOppOptionsReviews;
                        return (
                            <FilterDropdownCA
                                helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                                key={filter.title}
                                disabled={false}
                                allowSearch={false}
                                allowGqlSearch={true}
                                setGqlSearch={setCaSearchQuery}
                                gqlSearch={caSearchQuery}
                                gqlLoading={loadCaFilterset}
                                label={`${t(`filter.${filter.title}`)}`}
                                /**
                                 * Custom setter to make it possible to search in query and in filterdata to select ca's
                                 */
                                setCustomChosenOptions={currentPageOptionsSetter}
                                customChosenOptions={currentPageOptions}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                        handleResetCustomFilter();
                                        // setCustomOptions([]);
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={optionsToShow}
                                width={430}
                            />
                        );
                    }

                    /**
                     * Opportunity personal opportunity value
                     */
                    if (test === "personal_value" && loadingYears === false && IntRangeState !== undefined && setIntRangeState !== undefined) {
                        return (
                            <ValueFilter
                                key="personal_value"
                                active={filter.active}
                                label="personal_value"
                                IntRangeState={IntRangeState}
                                setIntRange={setIntRangeState}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    /**
                     * Filter for last reviewed document
                     * DateRange selector
                     */
                    if (test === "document_last_reviewed" && documentLastReviewed && setDocumentLastReviewed)
                        return (
                            <PeriodFilter
                                key="document_last_reviewed"
                                active={filter.active}
                                label="document_last_reviewed"
                                dateRangeState={documentLastReviewed}
                                setDateRange={setDocumentLastReviewed}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    /**
                     * Filter for personal opportunity year selector
                     * Array of years selection
                     */
                    if (test === "personal_year" && pathname === "/opportunities/reviews") {
                        return (
                            <FilterDropdown
                                key={filter.title}
                                disabled={false}
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                //* map all items (0 value included)
                                // options={filter.values.map((cas) => ({
                                //     key: cas.label,
                                //     label: `${cas.label} (${cas.amountOfResults})`,
                                // }))}
                                //* map all items (without 0 value)
                                options={oppYears
                                    // new to old
                                    .map(
                                        (year) =>
                                            ({
                                                key: `${year}`,
                                                label: `${year}`,
                                                id: year as number,
                                            } || [])
                                    )}
                                width={200}
                            />
                        );
                    }

                    if (test === "regions") {
                        return (
                            <FilterDropdownStringVersion
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={filter.values
                                    .filter((f: any) => f.amountOfResults > 0)
                                    .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                        key: fltr.label,
                                        label: `${fltr.label} (${fltr.amountOfResults})`,
                                        id: fltr.identifier,
                                    }))}
                                width={275}
                            />
                        );
                    }

                    if (
                        test === "searches" &&
                        loadSearches === false &&
                        (pathname === "/tenders/reviews" || pathname === "/tenders/worklist") &&
                        data?.currentUser.searches !== undefined
                    ) {
                        return (
                            <FilterDropdown
                                helpertext={loadSearches ? "Zoekregels ophalen..." : ""}
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    if (target.length === 0 && test === "reviews") {
                                        onChange(update(searchInput, { [test]: { $set: [1] } }));
                                    }

                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={data?.currentUser.searches.map(
                                    (search) =>
                                        ({
                                            key: search.color,
                                            label: search.name,
                                            id: parseInt(search.id),
                                        } || [])
                                )}
                                width={275}
                            />
                        );
                    }

                    /**
                     * Opportunityfilter search
                     */
                    if (
                        test === "searches" &&
                        loadSearches === false &&
                        (pathname === "/opportunities/reviews" || pathname === "/opportunities/worklist") &&
                        data?.currentUser.opportunitySearches !== undefined &&
                        data?.currentUser.opportunitySearches !== null
                    ) {
                        return (
                            <FilterDropdown
                                key={filter.title}
                                disabled={false}
                                helpertext={loadSearches ? "Zoekregels ophalen..." : ""}
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    if (target.length === 0 && test === "reviews") {
                                        onChange(update(searchInput, { [test]: { $set: [1] } }));
                                    }

                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={data?.currentUser.opportunitySearches.map(
                                    (search) =>
                                        ({
                                            key: "#225e4d",
                                            label: search.name,
                                            id: parseInt(search.id),
                                        } || [])
                                )}
                                width={275}
                            />
                        );
                    }

                    return (
                        <FilterDropdown
                            key={filter.title}
                            disabled={
                                false
                                // filter.values.length === 0
                            }
                            allowSearch
                            label={`${t(`filter.${filter.title}`)}`}
                            values={filterItems || []}
                            onChange={(target) => {
                                // If target has over 0 items. add filter to searchInput
                                if (target.length > 0) {
                                    onChange(update(searchInput, { [test]: { $set: target } }));
                                }
                                // If target.length = 0 -> remove empty filter from searchInput
                                if (target.length === 0) {
                                    onChange(update(searchInput, { $unset: [test as keyof any] }));
                                }
                                if (target.length === 0 && test === "reviews") {
                                    onChange(update(searchInput, { [test]: { $set: [1] } }));
                                }

                                // Execute startsearch each time
                                // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                startSearch();
                            }}
                            options={
                                filter.title === "reviews"
                                    ? arrayOfItems
                                          .sort((a, b) => [1, 2, 0, -1].indexOf(a.id) - [1, 2, 0, -1].indexOf(b.id))
                                          .map((fltr: { label: string; amountOfResults: string; id: number }) => ({
                                              key: fltr.id.toString(),
                                              label: fltr.label,
                                              id: fltr.id,
                                          }))
                                    : filter.values
                                          .filter((f: any) => f.amountOfResults > 0)
                                          .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                              key: fltr.label,
                                              label: `${fltr.label} (${fltr.amountOfResults})`,
                                              id: parseInt(fltr.identifier),
                                          }))
                            }
                            width={filter.title === "subSectors" ? 420 : filter.title === "sectors" ? 340 : 275}
                        />
                    );
                })}
            {/*
             ********
             * ACTIVE FILTERS
             * If user activates a filter thats not a topfilter -> show it here
             ********
             */}
            {array_to_show
                ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
                .filter((filter) => filter.topFilter === false)
                .filter((filter) => filter.active === true)
                .map((filter, i) => {
                    // change test to filter.title
                    const test = filter.title;
                    const filterItems = searchInput?.[test as keyof any] as any[];
                    /**
                     * Opportunity => contracting authority region filter
                     * (ca of document)
                     */
                    if (test === "contracting_authority_region") {
                        return (
                            <FilterDropdownStringVersion
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.contracting_authority_region`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { contracting_authority_region: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: ["contracting_authority_region" as keyof any] }));
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={customOptionsRegions}
                                width={275}
                            />
                        );
                    }

                    /**
                     * Tender => contracting authority filter
                     */
                    if (test === "contractingAuthorities") {
                        /**
                         * Search in ca query instead of filter data. (filterdata shows first 100 only)
                         */
                        const optionsToShow =
                            caSearchQuery !== undefined &&
                            caSearchQuery.length > 0 &&
                            caFilterSet?.paginatorInfo.total !== undefined &&
                            caFilterSet?.paginatorInfo.total > 0
                                ? caFilterSet?.data.map(
                                      (ca) =>
                                          ({
                                              key: ca.name,
                                              label: ca.name,
                                              id: parseInt(ca.id),
                                          } || [])
                                  )
                                : filter.values
                                      .filter((f: any) => f.amountOfResults > 0)
                                      .sort(function (a: any, b: any) {
                                          return parseInt(b.key) - parseInt(a.key);
                                      })
                                      .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                          key: fltr.label,
                                          label: `${fltr.label} (${fltr.amountOfResults})`,
                                          id: parseInt(fltr.identifier),
                                      }));

                        const currentPageOptions =
                            pathname === "/tenders"
                                ? customOptionsAll
                                : pathname === "/tenders/worklist"
                                ? customOptionsWorklist
                                : customOptionsReviews;

                        const currentPageOptionsSetter =
                            pathname === "/tenders"
                                ? setCustomOptionsAll
                                : pathname === "/tenders/worklist"
                                ? setCustomOptionsWorklist
                                : setCustomOptionsReviews;

                        return (
                            <FilterDropdownCA
                                helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                                key={filter.title}
                                disabled={false}
                                allowSearch={false}
                                allowGqlSearch={true}
                                setGqlSearch={setCaSearchQuery}
                                gqlSearch={caSearchQuery}
                                gqlLoading={loadCaFilterset}
                                label={`${t(`filter.${filter.title}`)}`}
                                /**
                                 * Custom setter to make it possible to search in query and in filterdata to select ca's
                                 */
                                setCustomChosenOptions={currentPageOptionsSetter}
                                customChosenOptions={currentPageOptions}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                        handleResetCustomFilter();
                                        // setCustomOptions([]);
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={optionsToShow}
                                width={430}
                            />
                        );
                    }

                    if (test === "enddate_contract_quarters") {
                        return (
                            <FilterDropdownStringVersion
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={filter.values
                                    .filter((f: any) => f.amountOfResults > 0)
                                    .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                        key: fltr.label,
                                        label: `${fltr.label}`,
                                        id: fltr.identifier,
                                    }))}
                                width={275}
                            />
                        );
                    }
                    if (test === "cpvs") {
                        return (
                            <FilterDropdownStringVersion
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={filter.values
                                    .filter((f: any) => f.amountOfResults > 0)
                                    .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                        key: fltr.label,
                                        label: `${fltr.label} (${fltr.amountOfResults})`,
                                        id: fltr.identifier,
                                    }))}
                                width={275}
                            />
                        );
                    }

                    if (test === "deadline_inschrijving" && deadline !== undefined && setDeadline !== undefined) {
                        return (
                            <PeriodFilter
                                key="deadline_inschrijving"
                                active={filter.active}
                                label="deadline_inschrijving"
                                dateRangeState={deadline}
                                setDateRange={setDeadline}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "first_publication" && firstPublication !== undefined && setFirstPublication !== undefined) {
                        return (
                            <PeriodFilter
                                key="first_publication"
                                active={filter.active}
                                label="first_publication"
                                dateRangeState={firstPublication}
                                setDateRange={setFirstPublication}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "enddate_contract" && endDate !== undefined && setEndDate !== undefined) {
                        return (
                            <PeriodFilter
                                key="enddate_contract"
                                active={filter.active}
                                label="enddate_contract"
                                dateRangeState={endDate}
                                setDateRange={setEndDate}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "enddate_contract_incl_options" && endDateInc !== undefined && setEndDateInc !== undefined) {
                        return (
                            <PeriodFilter
                                key="enddate_contract_incl_options"
                                active={filter.active}
                                label="enddate_contract_incl_options"
                                dateRangeState={endDateInc}
                                setDateRange={setEndDateInc}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "award_date" && awardDate !== undefined && setAwardDate !== undefined) {
                        return (
                            <PeriodFilter
                                key="award_date"
                                active={filter.active}
                                label="award_date"
                                dateRangeState={awardDate}
                                setDateRange={setAwardDate}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "startdate_contract" && startDateContract !== undefined && setStartDateContract !== undefined) {
                        return (
                            <PeriodFilter
                                key="startdate_contract"
                                active={filter.active}
                                label="startdate_contract"
                                dateRangeState={startDateContract}
                                setDateRange={setStartDateContract}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "next_enddate_contract" && nextEndDateContract !== undefined && setNextEndDateContract !== undefined) {
                        return (
                            <PeriodFilter
                                key="next_enddate_contract"
                                active={filter.active}
                                label="next_enddate_contract"
                                dateRangeState={nextEndDateContract}
                                setDateRange={setNextEndDateContract}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "updated_at" && updatedAt !== undefined && setUpdatedAt !== undefined) {
                        return (
                            <PeriodFilter
                                key="updated_at"
                                active={filter.active}
                                label="updated_at"
                                dateRangeState={updatedAt}
                                setDateRange={setUpdatedAt}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "rated_date" && ratedDate !== undefined && setRatedDate !== undefined) {
                        return (
                            <PeriodFilter
                                key="rated_date"
                                active={filter.active}
                                label="rated_date"
                                dateRangeState={ratedDate}
                                setDateRange={setRatedDate}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    if (test === "published" && publishedOppDate !== undefined && setPublishedOppDate !== undefined) {
                        return (
                            <PeriodFilter
                                key="published"
                                active={filter.active}
                                label="published"
                                dateRangeState={publishedOppDate}
                                setDateRange={setPublishedOppDate}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }
                    // Opportunity published date
                    if (test === "publication_date" && publishedOppDate !== undefined && setPublishedOppDate !== undefined) {
                        return (
                            <PeriodFilter
                                key="publication_date"
                                active={filter.active}
                                label="publication_date"
                                dateRangeState={publishedOppDate}
                                setDateRange={setPublishedOppDate}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }

                    // Opportunity document last review date
                    if (test === "document_last_reviewed" && documentLastReviewed !== undefined && setDocumentLastReviewed !== undefined) {
                        return (
                            <PeriodFilter
                                key="document_last_reviewed"
                                active={filter.active}
                                label="document_last_reviewed"
                                dateRangeState={documentLastReviewed}
                                setDateRange={setDocumentLastReviewed}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }

                    return (
                        <FilterDropdown
                            key={i}
                            disabled={filter.title !== "searches" && filter.title !== "reviews" && filter.values.length === 0}
                            allowSearch
                            label={`${t(`filter.${filter.title}`)}`}
                            values={filterItems || []}
                            onChange={(target) => {
                                // If target has over 0 items. add filter to searchInput
                                if (target.length > 0) {
                                    onChange(update(searchInput, { [test]: { $set: target } }));
                                }
                                // If target.length = 0 -> remove empty filter from searchInput
                                if (target.length === 0) {
                                    onChange(update(searchInput, { $unset: [test as keyof any] }));
                                }
                                // Execute startsearch each time
                                // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                startSearch();
                            }}
                            //* map all items (0 value included)
                            // options={filter.values.map((cas) => ({
                            //     key: cas.label,
                            //     label: `${cas.label} (${cas.amountOfResults})`,
                            // }))}
                            //* map all items (without 0 value)
                            options={filter.values
                                .filter((f: any) => f.amountOfResults > 0)
                                .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                    key: fltr.label,
                                    label: `${fltr.label} (${fltr.amountOfResults})`,
                                    id: parseInt(fltr.identifier),
                                }))}
                            width={275}
                        />
                    );
                })}

            {lastUpdate !== undefined && lastUpdate.from !== null && lastUpdate.to !== null && setLastUpdate !== undefined && (
                <PeriodFilter
                    key="lastUpdate"
                    active={true}
                    label="lastUpdate"
                    dateRangeState={lastUpdate}
                    setDateRange={setLastUpdate}
                    onChange={onChange}
                    searchInput={searchInput}
                />
            )}
        </>
    );
};

export default TenderFilterBar;

export const sortingArray = [
    "searches",
    "reviews",
    "country",
    "sectors",
    "subSectors",
    "status",
    "regions",
    "contractingAuthorityTypes",
    "contractingAuthorities",
    "city",
    "type",
    "cpvs",
    "deadline_inschrijving",
    "first_publication",
    "updated_at",
    "award_date",
    "startdate_contract",
    "next_enddate_contract",
    "enddate_contract",
    "enddate_contract_incl_options",
    "rated_date",
    "enddate_contract_quarters",
    "agenda",
    "contractors",
    "last_update_type",
    "platform",
    "scopeMax",
    "scopeMin",
    "type_procedure",
    "city",
    "contracting_authorities",
    "contracting_authority_type",
    "contracting_authority_city",
    "contracting_authority_state",
    "document_last_reviewed",
    "publication_date",
    "file_type",
    "document_unreviewed_page_count",
    "year_from",
    "personal_opportunity_count",
    "personal_value",
    "personal_year",
    "result_count",
    "text",
];

export const statusArray = ["0", "1", "2", "4", "8", "16", "48", "32", "64"];
