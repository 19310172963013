import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GetSideBarArticlesOfModule_getSidebarArticle } from "../../__generated__/GetSideBarArticlesOfModule";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
interface Props {
    content: (GetSideBarArticlesOfModule_getSidebarArticle | null)[];
    setActiveId: Dispatch<SetStateAction<number>>;
    mark_as_read: () => void;
}

const SiderBarContent: React.FC<Props> = ({ content, setActiveId, mark_as_read }) => {
    const theme = useTheme();
    const [data] = useState<(GetSideBarArticlesOfModule_getSidebarArticle | null)[]>(content);
    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage] = useState(1);
    const nPages = Math.ceil(data.length / rowsPerPage);
    const [currentRecords, setCurrentRecords] = useState<(GetSideBarArticlesOfModule_getSidebarArticle | null)[]>([]);
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    /**
     * Next page
     */
    const nextPage = () => {
        if (activePage !== nPages) {
            setActivePage(activePage + 1);
            mark_as_read();
        }
    };
    /**
     * Prev page
     */
    const prevPage = () => {
        if (activePage !== 1) setActivePage(activePage - 1);
    };

    /**
     * Last item
     */
    const indexOfLastRecord = activePage * rowsPerPage;
    /**
     * First item
     */
    const indexOfFirstRecord = indexOfLastRecord - rowsPerPage;

    /**
     * Slice data to show one item per page
     */
    useEffect(() => {
        setCurrentRecords(data.slice(indexOfFirstRecord, indexOfLastRecord));
        if (currentRecords !== null && currentRecords[0] !== null && currentRecords[0] !== undefined) setActiveId(parseInt(currentRecords[0].id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div
                style={{
                    fontSize: onlyMobile ? "0.875rem" : "1.2rem",
                    margin: onlyMobile ? "16px" : "48px",
                    marginTop: "0px",
                }}
            >
                {currentRecords.map((i) => {
                    return (
                        <div key={i?.id}>
                            {/*
                             * Content of article
                             */}
                            {i !== null && i?.content !== undefined && (
                                <Typography
                                    style={{ display: "inline", whiteSpace: "pre-wrap", fontSize: "1.2rem" }}
                                    variant={"body1"}
                                    dangerouslySetInnerHTML={{
                                        __html: i.content,
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
            {/*
             * Pagination to show each article one by one
             */}
            {currentRecords.length > 1 && (
                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                    <IconButton onClick={prevPage} size="small">
                        <NavigateBeforeIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                    <div style={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>{activePage}</div>
                    <IconButton onClick={nextPage} size="small">
                        <NavigateNextIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </div>
            )}
        </React.Fragment>
    );
};

export default SiderBarContent;
